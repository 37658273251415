import React from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  useDeleteMealMutation,
  useGetMealsQuery,
  useUpdateRubricStatusMutation,
  useValidateMealMutation,
} from "./rubricsApiSlice";

const RUBRIC_STATUS = {
  ACTIVATED: "activated",
  DEACTIVATED: "deactivated",
};

function UpdateRubricModal({ rubric }) {
  const [updateRubricStatus] = useUpdateRubricStatusMutation();

  async function displayConfirmation(status) {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Veuillez confirmer ${
        status === RUBRIC_STATUS.ACTIVATED
          ? "l'activation"
          : status === RUBRIC_STATUS.DEACTIVATED
          ? "la désactivation"
          : ""
      }.`,
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isConfirmed = swal.isConfirmed;

    if (isConfirmed) {
      updateRubricStatus({ rubricId: rubric.id, status })
        .unwrap()
        .then(() => {
          Swal.fire({
            title: "Succees",
            text: `Opération effectuée avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((response) => {
          Swal.fire({
            title: "Erreur",
            text: `Erreur lors du changement de status`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    }
  }
  return (
    <div>
      {rubric.status === "deactivated" && (
        <button
          className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
          type="button"
          title="Activer"
          onClick={() => displayConfirmation("activated")}
        >
          <i className="bi bi-check-lg fs-3"></i>
        </button>
      )}

      {rubric.status === "activated" && (
        <button
          className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
          type="button"
          title="Désactiver"
          onClick={() => displayConfirmation("deactivated")}
        >
          <i className="bi bi-x-lg fs-3"></i>
        </button>
      )}
    </div>
  );
}

export default UpdateRubricModal;
