import { useState } from "react";
import { useUpdateTagMutation } from "./tagsApiSlice";
import Swal from "sweetalert2";
import Input from "../../components/formik/Input";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";

function UpdateTagModal({ tag }) {
  const [name, setName] = useState(tag.name);
  const [description, setDescription] = useState(tag.description);

  const [updateTag] = useUpdateTagMutation();

  const handleSubmit = (data) => {
    updateTag(data)
      .unwrap()
      .then((response) => {
        if (response?.error?.originalStatus === 500) {
          Swal.fire({
            title: "Erreur",
            text: `Erreur du serveur lors de la mise à jour du tag`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Succees",
            text: `Tag mis à jour avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
      .catch((error) => {
        let message = "<ul>";
        if (error.data.message) {
          message += error.data.message;
        }

        if (error.data.errors) {
          for (const [key, value] of Object.entries(error.data.errors)) {
            message += `<li>${key} : ${value.join(" ")}</li>`;
          }
        }

        message += "</ul>";

        Swal.fire({
          title: "Erreur",
          html: message,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 15000,
          timerProgressBar: true,
        });
      });

    setName("");
    setDescription("");
  };

  return (
    <div>
      <button
        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#updateTagModal${tag.id}`}
      >
        <i className="bi bi-pencil"></i>
      </button>

      {/* <!-- Update Plan Modal --> */}
      <div
        class="modal fade"
        id={`updateTagModal${tag.id}`}
        tabindex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div class="modal-content">
            {/* <!-- Header --> */}
            <div class="modal-header">
              <h4 class="modal-title" id="accountUpdatePlanModalLabel">
                Mise à jour du tag
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <Formik
              enableReinitialize
              initialValues={{ name: tag.name, description: tag.description }}
              validationSchema={Yup.object({
                name: Yup.string().required("Obligatoire"),
                description: Yup.string().required("Obligatoire"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit({
                  ...tag,
                  name: values.name,
                  description: values.description,
                  _method: "put",
                });
                setSubmitting(false);
              }}
            >
              <Form className="modal-body">
                <div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-end mt-3">
                        <Input
                          label="Nom"
                          name="name"
                          type="text"
                          placeholder="Nom"
                          className="form-control"
                        />
                      </div>

                      <div className="col-md-6 d-flex align-items-end mt-3">
                        <Input
                          label="Description"
                          name="description"
                          type="text"
                          placeholder="Description"
                          className="form-control"
                        />
                      </div>

                      <div className="mt-5 d-flex bd-highlight justify-content-start">
                        <div className="mx-2">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Enregistrer
                          </button>
                        </div>

                        <div className="mx-2">
                          <button
                            type="button"
                            className="btn btn-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Annuler
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateTagModal;
