import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/Shared/Title";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";
import { stringAvatar } from "../../components/Shared/Utils";
import { nanoid } from "@reduxjs/toolkit";
import { useGetDeliveryCompanyQuery } from "./deliveryCompanyApiSlice";

function ReadDeliveryCompany() {
  const { deliveryCompanyId } = useParams();
  const [deliveryCompany, setDeliveryCompany] = useState({
    name: "Compagnie de livraison",
  });
  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetDeliveryCompanyQuery({ deliveryCompanyId });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setDeliveryCompany(response.deliveryCompany);
    }
  }, [isLoading, isSuccess]);

  return (
    <>
      <Title title={deliveryCompany.name} backPath="/delivery-company"></Title>

      <div className="row">
        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      {/* 508 */}
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Commandes</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      {/* 55 */}
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Commandes en attente</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      {/* 200 */}
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Livraisons</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      {/* 451 */}
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Promotions</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}
      </div>

      <div className="content container-fluid">
        <div className="row">
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Nom</h6>
                <h3 className="card-title">{deliveryCompany.name}</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Status</h6>
                <h3 className="card-title">{deliveryCompany.status}</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Adresse</h6>
                <h3 className="card-title">{deliveryCompany.address} </h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Nombre de livreurs</h6>
                <h3 className="card-title">
                  {deliveryCompany.nbr_of_delivery_men}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">
                  Administrateur Restaurant
                </h6>
                <Avatar
                  alt=" "
                  src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.png"}
                />
                <h3 className="card-title">
                  {deliveryCompany.admin?.identity.fullName}
                </h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Slogan</h6>
                <h3 className="card-title">{deliveryCompany.slogan} </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReadDeliveryCompany;
