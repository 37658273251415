import React, { useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import { Link } from "react-router-dom";
import { useGetAllCustomersQuery } from "./customerApiSlice";
import CreateCustomerPromotion from "./CreateCustomerPromotion";

function CustomerList({ setCustomers }) {
  const [baseUrl, setBaseUrl] = useState("clients");
  const [url, setUrl] = useState(baseUrl);
  const [canSelect, setCanSelect] = useState(false);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllCustomersQuery({ url });

  const columns = [
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
    {
      name: "Numero",
      selector: "telephone",
    },
    {
      name: "Inscrit le",
      selector: "created_at",
      type: "date",
    },
  ];

  let data = [];
  let meta = {};

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((staff) => ({
      id: staff.id,
      name: staff.identity.fullName,
      telephone: staff.identity.telephone,
      created_at: staff.created_at,
      identity: staff.identity,
    }));
    // setCustomers(data);

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (customer) => {
    return (
      <div className="fw-bold d-flex">
        <Link to={`/customer/${customer.id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
            type="button"
          >
            <i className="bi bi-eye fs-3"></i>
          </button>
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid d-flex justify-content-end bd-highlight">
        <div className="m-4">
          <button
            className="btn btn-lg btn-ghost-secondary border"
            type="button"
            onClick={() => setCanSelect(!canSelect)}
          >
            Sélectionner
          </button>
        </div>

        <CreateCustomerPromotion customers={data} setCanSelect={setCanSelect} />
      </div>
      <div className="container-fluid">
        <DataTable
          columns={columns}
          data={data}
          actions={actions}
          baseUrl={baseUrl}
          setUrl={setUrl}
          meta={meta}
          canSelect={canSelect}
        />
      </div>
    </>
  );
}

export default CustomerList;
