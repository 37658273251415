import { useSelector } from "react-redux";
import { apiSlice } from "../../app/api/apiSlice";
import { selectUrl } from "./crudSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

const crudApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Crud"],
  endpoints: (builder) => ({
    readAll: builder.query({
      query: () => "staff",
      providesTags: ["Crud"],
    }),
    addRestaurantCategory: builder.mutation({
      query: (restaurantCategory) => ({
        url: "restaurant-categories",
        method: "POST",
        body: dataForm(restaurantCategory),
      }),
      invalidatesTags: ["Crud"],
    }),
    updateRestaurantCategory: builder.mutation({
      query: (restaurantCategory) => ({
        url: `restaurant-categories/${restaurantCategory.id}`,
        method: "PUT",
        body: dataForm(restaurantCategory),
      }),
      invalidatesTags: ["Crud"],
    }),
    deleteRestaurantCategory: builder.mutation({
      query: (restaurantCategoryId) => ({
        url: `restaurant-categories/${restaurantCategoryId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Crud"],
    }),
  }),
});

export const {
  useReadAllQuery,
  useAddRestaurantCategoryMutation,
  useUpdateRestaurantCategoryMutation,
  useDeleteRestaurantCategoryMutation,
} = crudApiSlice;
