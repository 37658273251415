import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

const deliveryFeeApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["DeliveryFee", "SingleDeliveryFee"],
  endpoints: (builder) => ({
    getDeliveryFee: builder.query({
      query: ({ url }) => url,
      providesTags: ["DeliveryFee"],
    }),
    getSingleDeliveryFee: builder.query({
      query: ({ deliveryFeeId }) => {
        return `config-deliveries/${deliveryFeeId}`;
      },
      providesTags: ["SingleDeliveryFee"],
    }),
    getCreateDeliveryFeeData: builder.query({
      query: () => "config-deliveries/create",
    }),
    addDeliveryFee: builder.mutation({
      query: (deliveryFee) => ({
        url: "config-deliveries",
        method: "POST",
        body: { ...deliveryFee },
      }),
      invalidatesTags: ["DeliveryFee"],
    }),
    updateDeliveryFee: builder.mutation({
      query: (deliveryFee) => ({
        url: `config-deliveries/${deliveryFee.id}`,
        method: "POST",
        body: deliveryFee,
      }),
      invalidatesTags: ["DeliveryFee", "SingleDeliveryFee"],
    }),
    deleteDeliveryFee: builder.mutation({
      query: (deliveryFeeId) => ({
        url: `config-deliveries/${deliveryFeeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DeliveryFee"],
    }),
  }),
});

export const {
  useGetDeliveryFeeQuery,
  useGetSingleDeliveryFeeQuery,
  useGetCreateDeliveryFeeDataQuery,
  useAddDeliveryFeeMutation,
  useUpdateDeliveryFeeMutation,
  useDeleteDeliveryFeeMutation,
} = deliveryFeeApiSlice;
