import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

const adminOrdersApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["AdminOrders", "AdminOrder"],
  endpoints: (builder) => ({
    getAdminOrders: builder.query({
      query: ({ url }) => url,
      providesTags: ["AdminOrders"],
    }),
    getOrder: builder.query({
      query: ({ orderId }) => {
        return `orders/${orderId}`;
      },
      providesTags: ["AdminOrder"],
    }),
    getCreateRestaurantOrderData: builder.query({
      query: () => "restaurants/orders/create",
    }),
    addRestaurantOrder: builder.mutation({
      query: (restaurant) => ({
        url: "restaurants/orders",
        method: "POST",
        body: restaurant,
      }),
      invalidatesTags: ["AdminOrders"],
    }),
    updateRestaurantOrder: builder.mutation({
      query: (restaurant) => ({
        url: `restaurants/orders/${restaurant.id}`,
        method: "PUT",
        body: restaurant,
      }),
      invalidatesTags: ["AdminOrders", "AdminOrder"],
    }),
    deleteRestaurantOrder: builder.mutation({
      query: (restaurantId) => ({
        url: `restaurants/orders/${restaurantId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AdminOrders"],
    }),
  }),
});

export const {
  useGetAdminOrdersQuery,
  useGetOrderQuery,
  useGetCreateRestaurantOrderDataQuery,
  useAddRestaurantOrderMutation,
  useUpdateRestaurantOrderMutation,
  useDeleteRestaurantOrderMutation,
} = adminOrdersApiSlice;
