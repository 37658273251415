import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/Shared/Title";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";
import { stringAvatar } from "../../components/Shared/Utils";
import {
  useGetCreateRestaurantDataQuery,
  useGetRestaurantQuery,
} from "./restaurantsApiSlice";
import { nanoid } from "@reduxjs/toolkit";
import STATUS from "../../components/Shared/Status";
import EditableAvailabilityTable from "./EditableAvailabilityTable";
import { useDispatch } from "react-redux";
import { setCreateRestaurantData } from "./restaurantSlice";

function ReadRestaurant() {
  const { restaurantId } = useParams();
  const [restaurant, setRestaurant] = useState({ name: "Restaurant" });
  const [days, setDays] = useState([]);

  const dispatch = useDispatch();

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetRestaurantQuery({ restaurantId });

  const createRequest = useGetCreateRestaurantDataQuery();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setRestaurant(response);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (createRequest.isSuccess) {
      // dispatch set create restaurant data
      const { days, categories } = createRequest.data;
      dispatch(setCreateRestaurantData({ days, categories }));
      setDays(days);
      // setCategories(categories);

      let formatedDays = days.map((element) => ({
        id: element.id,
        name: element.name,
        number: element.number,
        open_hour: "00",
        open_min: "00",
        close_hour: "23",
        close_min: "59",
        is_available: true,
      }));
      formatedDays.sort((a, b) => (a.number > b.number ? 1 : -1));
      setDays(formatedDays);
    }
  }, [createRequest.isLoading, createRequest.isSuccess]);

  return (
    <>
      <div className="profile-cover">
        <div className="profile-cover-img-wrapper">
          {restaurant.banner && (
            <img
              className="profile-cover-img"
              src={restaurant.banner_url}
              alt="Bannière"
            />
          )}
        </div>
      </div>

      <div className="text-center mb-5">
        <div className="avatar avatar-xxl avatar-circle profile-cover-avatar">
          {restaurant.logo ? (
            <img
              className="avatar-img"
              src={restaurant.logo_url}
              alt="Restaurant Logo"
            />
          ) : (
            <span className="avatar-initials">
              {restaurant.name.charAt(0)} {restaurant.name.charAt(1)}
            </span>
          )}
          <span className="avatar-status avatar-status-success"></span>
        </div>

        <h1 className="page-header-title">{restaurant.name}</h1>
        <div className="text-center">
          <Rating
            name="half-rating-read"
            defaultValue={2.5}
            precision={0.5}
            readOnly
            size="large"
          />
        </div>
      </div>

      <div className="row">
        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      5
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Ventes</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>

        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      2
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Commandes en attente</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      0
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Promotions</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      7
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Repas approuvés</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}
      </div>

      <div className="content container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="table-responsive datatable-custom">
              <table
                id="datatable"
                className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th>Élément</th>
                    <th>Information</th>
                  </tr>
                </thead>

                <tbody>
                  <tr key={nanoid()}>
                    <td>Nom</td>
                    <td>{restaurant.name}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Status</td>
                    <td>{STATUS[restaurant.status]}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Adresse</td>
                    <td>{restaurant.address}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Longitude</td>
                    <td>{restaurant.longitude}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Latitude</td>
                    <td>{restaurant.latitude}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>En promotion</td>
                    <td>{restaurant.promocodes?.length > 0 ? "Oui" : "Non"}</td>
                  </tr>
                </tbody>
              </table>
              <div className="card-footer"></div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card">
              {/* <!-- Header --> */}
              <div class="card-header">
                <h4 class="card-header-title">Restaurant</h4>
              </div>
              {/* <!-- End Header --> */}

              {/* <!-- Body --> */}
              <div class="card-body">
                {/* <!-- List Group --> */}
                <ul class="list-group list-group-flush list-group-no-gutters">
                  <li class="list-group-item">
                    <div class="d-flex align-items-center justify-content-around">
                      <p>Logo</p>
                      <Avatar
                        alt=" "
                        src={restaurant.logo_url}
                        sx={{ width: 56, height: 56 }}
                      />
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="d-flex align-items-center justify-content-around">
                      <p>Bannière</p>
                      <Avatar
                        alt=" "
                        src={restaurant.banner_url}
                        sx={{ width: 56, height: 56 }}
                      />
                    </div>
                  </li>

                  {restaurant.telephones?.map((item, index) => (
                    <li key={nanoid()} class="list-group-item">
                      <div class="d-flex align-items-center justify-content-around">
                        <p>{`Téléphone ${index + 1}`}</p>
                        <p>{item}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                {/* <!-- End List Group --> */}
              </div>
              {/* <!-- End Body --> */}
            </div>
            {/* <!-- End Card --> */}
          </div>
        </div>
        <h3 className="text-center">Horaire</h3>
        <div className="row">
          <div className="col-md-12 mb-5">
            <EditableAvailabilityTable
              initialDays={days}
              initialAvailability={restaurant.availabilities}
              setAvailability={() => console.log("set availability")}
              canEdit={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ReadRestaurant;
