import React, { useState } from "react";
import Swal from "sweetalert2";
import {
  useGetDrinkCategoriesQuery,
  useUpdateDrinkCategoryMutation,
} from "./drinkCategoriesApiSlice";

function UpdateDrinkCategoryModal({ drinkCategory }) {
  const [name, setName] = useState(drinkCategory.name);
  const [description, setDescription] = useState(drinkCategory.description);
  const [image, setImage] = useState({ name: drinkCategory.image });

  const [updateDrinkCategory] = useUpdateDrinkCategoryMutation();
  // const { refetch } = useGetDrinkCategoriesQuery();

  const handleSubmit = (e) => {
    e.preventDefault();

    updateDrinkCategory({
      ...drinkCategory,
      name,
      description,
      image,
      _method: "put",
    })
      .unwrap()
      .then((response) => {
        if (response?.error?.originalStatus === 500) {
          Swal.fire({
            title: "Erreur",
            text: `Erreur du serveur lors de la mise à jour de la catégorie de boisson`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Succees",
            text: `Catégorie de boisson mise à jour avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
      .catch((response) => {
        console.log("ERROR UPDATE DRINK CATEGORY", response);
        let message = "<ul>";
        for (const [key, value] of Object.entries(response.data.errors)) {
          message += `<li>${key} : ${value.join(" ")}</li>`;
        }
        message += "</ul>";

        Swal.fire({
          title: "Erreur",
          html: message,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 15000,
          timerProgressBar: true,
        });
      });

    setName("");
    setDescription("");
    setImage({ name: null });
  };

  const handleUpload = (file) => {
    setImage(file);
  };

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#updateDrinkCategoryModal${drinkCategory.id}`}
      >
        <i className="bi bi-pencil fs-3"></i>
      </button>

      {/* <!-- Update Plan Modal --> */}
      <div
        class="modal fade"
        id={`updateDrinkCategoryModal${drinkCategory.id}`}
        tabindex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div class="modal-content">
            {/* <!-- Header --> */}
            <div class="modal-header">
              <h4 class="modal-title" id="accountUpdatePlanModalLabel">
                Mise à jour de la catégorie de boisson
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <form
              onSubmit={handleSubmit}
              class="modal-body"
              encType="multipart/form-data"
            >
              <div>
                {/* <!-- Body --> */}
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 d-flex align-items-end mt-3">
                      <input
                        type="text"
                        id="updateTagName"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        placeholder="Nom de la catégorie de boisson"
                        aria-label="Nom de la catégorie de boisson"
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <input
                        type="text"
                        id={`udateTagDescription${drinkCategory.id}`}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-control"
                        placeholder="Description de la catégorie de boisson"
                        aria-label="Description de la catégorie de boisson"
                      />
                    </div>

                    <div className="col-md-12 mt-3 d-flex  align-items-end">
                      <div className="">
                        <label
                          htmlFor="mealCategoryImage"
                          className="form-label"
                        >
                          {image?.value?.name ?? "Image"}
                        </label>

                        <input
                          type="file"
                          id="image"
                          value=""
                          onChange={(e) => {
                            handleUpload(e.target.files[0]);
                          }}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="mt-5 d-flex bd-highlight justify-content-start">
                      <div className="mx-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Modifier
                        </button>
                      </div>

                      <div className="mx-2">
                        <button
                          type="button"
                          className="btn btn-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Update Plan Modal --> */}
    </div>
  );
}

export default UpdateDrinkCategoryModal;
