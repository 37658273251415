import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/Shared/Title";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";
import { stringAvatar } from "../../components/Shared/Utils";
import { nanoid } from "@reduxjs/toolkit";
import { useGetSingleDeliveryFeeQuery } from "./deliveryFeeApiSlice";

function ReadDeliveryFee() {
  const { deliveryFeeId } = useParams();
  const [deliveryFee, setDeliveryFee] = useState({});
  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetSingleDeliveryFeeQuery({ deliveryFeeId });

  useEffect(() => {
    if (isSuccess) {
      const { configDelivery } = response;
      setDeliveryFee(configDelivery);
    }
  }, [isLoading, isSuccess]);

  return (
    <>
      <Title title={deliveryFee.name} backPath="/delivery-fee" />

      <div className="content container-fluid">
        <div className="row">
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Nom</h6>
                <h3 className="card-title">{deliveryFee.name}</h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Status</h6>
                <h3 className="card-title">{deliveryFee.status}</h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Type</h6>
                <h3 className="card-title">{deliveryFee.type}</h3>
              </div>
            </div>
          </div>

          {deliveryFee.description && (
            <div className="col-lg-3 mb-5">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Description</h6>
                  <h3 className="card-title">{deliveryFee.description} </h3>
                </div>
              </div>
            </div>
          )}

          {deliveryFee.amount_by_km && (
            <div className="col-lg-3 mb-5">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Montant par Km</h6>
                  <h3 className="card-title">{deliveryFee.amount_by_km} F</h3>
                </div>
              </div>
            </div>
          )}

          {/* fixed_amount */}
          {deliveryFee.fixed_amount && (
            <div className="col-lg-3 mb-5">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Montant Fixe</h6>
                  <h3 className="card-title">{deliveryFee.fixed_amount} </h3>
                </div>
              </div>
            </div>
          )}

          {deliveryFee.ranges?.length > 0 && (
            <div className="col-lg-3 mb-5">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Plages</h6>
                  <ul>
                    {deliveryFee.ranges?.map((range) => (
                      <li key={nanoid()}>
                        <h5 className="card-title">
                          {`( ${range.km_min} - ${range.km_max} ) Km :  ${range.amount} F`}
                        </h5>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ReadDeliveryFee;
