import { apiSlice } from "../../app/api/apiSlice";

const dataForm = (data) => {
  const formdata = new FormData();
  const entriesAsArray = ["images", "options", "supplements", "tags"];

  for (const [key, value] of Object.entries(data)) {
    if (!entriesAsArray.includes(key)) {
      formdata.append(key, value);
    }
  }

  entriesAsArray
    .filter((x) => x !== "supplements")
    .forEach((entry) => {
      // check if entry exists in data
      if (entry in data) {
        data[entry].forEach((element, index) => {
          formdata.append(`${entry}[${index}]`, element);
        });
      }
    });

  data["supplements"].forEach((element, index) => {
    formdata.append(
      `supplements[${index}][supplement_id]`,
      element.supplement_id
    );
    formdata.append(`supplements[${index}][price]`, element.price);
  });

  return formdata;
};

const rubricsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Rubrics"],
  endpoints: (builder) => ({
    getMealsStats: builder.query({
      query: () => "stats/meals",
      providesTags: ["Rubrics"],
    }),
    getRubrics: builder.query({
      query: () => "rubrics",
      providesTags: ["Rubrics"],
    }),
    updateRubricStatus: builder.mutation({
      query: ({ rubricId, status }) => ({
        url: `rubrics/${rubricId}`,
        method: "POST",
        body: { status, _method: "put" },
      }),
      invalidatesTags: ["Rubrics"],
    }),
  }),
});

export const { useGetRubricsQuery, useUpdateRubricStatusMutation } =
  rubricsApiSlice;
