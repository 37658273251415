import Title from "../components/Shared/Title";
import React from "react";

function Notification() {
  return (
    <>
      <Title title="Notifications" />
      <div className="container-fluid d-flex flex-row-reverse bd-highlight">
      </div>
        <h3 className="">Aucune notification pour le moment.</h3>
    </>
  );
}

export default Notification;
