import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    verifyUser: builder.query({
      query: ({ token }) => `users/verify/${token}`,
    }),
    getUserInfo: builder.query({
      query: () => `login`,
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "reset-password",
        method: "POST",
        body: { ...data },
      }),
    }),
    sendResetPasswordMail: builder.mutation({
      query: (data) => ({
        url: "forgot-password",
        method: "POST",
        body: { ...data },
      }),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: {
          password: credentials.password,
          username: credentials.user,
          grant_type: "password",
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
        },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "logout",
        method: "GET",
      }),
    }),
    registerDeliveryCompany: builder.mutation({
      query: (data) => ({
        url: "delivery-companies",
        method: "POST",
        body: {
          ...data,
        },
      }),
    }),
    getAdminStats: builder.query({
      query: () => ({
        url: "",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useVerifyUserQuery,
  useGetUserInfoQuery,
  useResetPasswordMutation,
  useSendResetPasswordMailMutation,
  useLoginMutation,
  useLogoutMutation,
  useRegisterDeliveryCompanyMutation,
  useGetAdminStatsQuery,
} = authApiSlice;
