import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectRoles } from "../../features/auth/authSlice";
import { selectSelectedRestaurant } from "../../features/restaurants/restaurantSlice";
import { useNavigate } from "react-router-dom";

const useRedirectUserIfNoRestaurantSelected = () => {
  const currentRoles = useSelector(selectRoles);
  const currentRolesNames = currentRoles?.map((role) => role.name);
  const selectedRestaurant = useSelector(selectSelectedRestaurant);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      ["restaurant-admin"].some((role) => currentRolesNames?.includes(role)) &&
      !selectedRestaurant
    ) {
      navigate("/dashboard");
    }
  }, []);
  return;
};

export default useRedirectUserIfNoRestaurantSelected;
