import React from "react";
import { useParams } from "react-router-dom";
import Title from "../../Shared/Title";
import Avatar from "@mui/material/Avatar";
import { stringAvatar } from "../../Shared/Utils";

function ReadMealCategoryModal({ mealCategory }) {
  console.log("mealCategory", mealCategory);
  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#mealCategoryReadModal${mealCategory.id}`}
      >
        <i className="bi bi-eye fs-3"></i>
      </button>

      {/* <!-- Update Plan Modal --> */}
      <div
        className="modal fade"
        id={`mealCategoryReadModal${mealCategory.id}`}
        tabIndex="-1"
        aria-labelledby={`mealCategoryReadModalLabel${mealCategory.id}`}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4
                className="modal-title"
                id={`mealCategoryReadModalLabel${mealCategory.id}`}
              >
                Détails de la catégorie de repas
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <div className="modal-body">
              <div className="content container-fluid">
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h6 className="card-subtitle mb-3"></h6>
                        <div className="d-flex justify-content-center">
                          <Avatar
                            src={mealCategory.image}
                            sx={{ width: 200, height: 200 }}
                          />
                        </div>
                        <h3 className="card-title d-flex justify-content-center">
                          {mealCategory.name}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-5">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h6 className="card-subtitle mb-3">Description</h6>
                        {mealCategory.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center justify-content-sm-end gap-3">
                <button
                  type="button"
                  className="btn btn-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Fermer
                </button>
                <button type="submit" className="d-none btn btn-primary">
                  Upgrade plan
                </button>
              </div>
            </div>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Update Plan Modal --> */}
    </div>
  );
}

export default ReadMealCategoryModal;
