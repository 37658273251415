import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectIdentity, selectRoles, selectStaff } from "../auth/authSlice";
import {
  selectSelectedRestaurant,
  setSelectedRestaurant,
} from "./restaurantSlice";
import Title from "../../components/Shared/Title";

function RequireRestaurantSelection() {
  const currentRoles = useSelector(selectRoles);
  const currentRolesNames = currentRoles?.map((role) => role.name);
  const currentIdentity = useSelector(selectIdentity);
  const currentStaff = useSelector(selectStaff);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentStaff?.identity?.staff?.restaurants?.length === 1) {
      const element = currentStaff?.identity?.staff?.restaurants[0];
      dispatch(setSelectedRestaurant({ restaurant: element }));
    }
  }, []);

  const handleRestaurantSelection = (restaurant) => {
    dispatch(setSelectedRestaurant({ restaurant }));
  };
  return (
    <>
      <Title title="Veuillez selectionner le restaurant a administrer" />
      <div className="container">
        <ul>
          {currentStaff?.identity?.staff?.restaurants?.map((restaurant) => (
            <li className="list-unstyled ">
              <button
                onClick={() => handleRestaurantSelection(restaurant)}
                className="btn btn-white"
                type="button"
              >
                {restaurant.name}
              </button>
            </li>
          ))}
        </ul>
        {currentStaff?.identity?.staff?.restaurants?.length <= 0 && (
          <p>
            Aucun restaurant ne vous a été attribué pour le moment. Veuillez
            contacter l'administrateur pour continuer.
          </p>
        )}
      </div>
    </>
  );
}

export default RequireRestaurantSelection;
