import React from "react";
import Swal from "sweetalert2";
import {
  useDeleteRestaurantStaffMutation,
  useGetRestaurantStaffQuery,
} from "./restaurantStaffApiSlice";

function DeleteRestaurantStaffModal({ restaurantStaff }) {
  const [deleteRestaurantStaff] = useDeleteRestaurantStaffMutation();
  const { refetch } = useGetRestaurantStaffQuery();

  async function displayConfirmation() {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Voulez-vous vraiment retier  ${restaurantStaff.name} de la supervision de ses restaurants affiliés ?`,
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isDeleteConfirmed = swal.isConfirmed;

    if (isDeleteConfirmed) {
      deleteRestaurantStaff(restaurantStaff.id)
        .unwrap()
        .then(() => {
          Swal.fire({
            title: "Succees",
            text: `${restaurantStaff.name} retiré(e)`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Erreur",
            text: `Erreur lors du retrait de  ${restaurantStaff.name} de la supervision de ses restaurants affiliés`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    }
  }

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        onClick={displayConfirmation}
      >
        <i className="bi bi-trash fs-3"></i>
      </button>
    </div>
  );
}

export default DeleteRestaurantStaffModal;
