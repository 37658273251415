export const VALUE_TYPE = {
  amount: "Montant",
  percentage: "Pourcentage",
};

export const CALCULATION_BASIS = {
  amount: "Montant",
};

export const APPLICATE = {
  all: "Tous",
};

export const PENDING = "pending";

export const ACCEPTED = "accepted";

export const PROCESSING = "processing";
