import React, { useState } from "react";
import Title from "../../Shared/Title";
import { v4 as uuidv4 } from "uuid";
import Geolocation from "../../Shared/Geolocation";

function UpdateCustomer() {
  const [canUseCurrentLocation, setCanUseCurrentLocation] = useState(true);

  const [availability, setAvailability] = useState([
    {
      day: "Lundi",
      start: "",
      end: "",
      checked: true,
    },
    {
      day: "Mardi",
      start: "",
      end: "",
      checked: true,
    },
    {
      day: "Mercredi",
      start: "",
      end: "",
      checked: true,
    },
    {
      day: "Jeudi",
      start: "",
      end: "",
      checked: true,
    },
    {
      day: "Vendredi",
      start: "",
      end: "",
      checked: true,
    },
    {
      day: "Samedi",
      start: "",
      end: "",
      checked: true,
    },
    {
      day: "Dimanche",
      start: "",
      end: "",
      checked: true,
    },
  ]);

  const switchToggleButton = (slot) => {
    const slot_index = availability.findIndex(
      (element) => element.day === slot.day
    );
    const nextAvailability = [...availability];
    nextAvailability[slot_index].checked = !slot.checked;
    setAvailability(nextAvailability);
  };

  return (
    <div>
      <Title title="Modifier les informations du client" />
      {/* <!-- Body --> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Nom du client"
              aria-label="Nom du client"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Prénom(s) du client"
              aria-label="Prénom(s) du client"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Numero de telephone"
              aria-label="Numero de telephone"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Email"
              aria-label="Email"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Date de naissance"
              aria-label="Date de naissance"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mt-3">
            <label for="formFile" class="form-label">
              Photo
            </label>
            <input class="form-control" type="file" id="formFile" />
          </div>

          <div className="col-md-6 mt-3 d-flex align-items-end">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Adresse du client"
              aria-label="Adresse du client"
            />
          </div>

          <div className="mt-5 d-flex bd-highlight justify-content-start">
            <div className="mx-2">
              <button className="btn btn-primary">Modifier</button>
            </div>

            <div className="mx-2">
              <button className="btn btn-white">Annuler</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateCustomer;
