import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

const notifTemplateApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["NotifTemplate", "SingleNotifTemplate"],
  endpoints: (builder) => ({
    getNotifTemplate: builder.query({
      query: ({ url }) => url,
      providesTags: ["NotifTemplate"],
    }),
    getSingleNotifTemplate: builder.query({
      query: ({ notifTemplateId }) => {
        return `notif-templates/${notifTemplateId}`;
      },
      providesTags: ["SingleNotifTemplate"],
    }),
    getCreateNotifTemplateData: builder.query({
      query: () => "config-deliveries/create",
    }),
    addNotifTemplate: builder.mutation({
      query: (notifTemplate) => ({
        url: "notif-templates",
        method: "POST",
        body: { ...notifTemplate },
      }),
      invalidatesTags: ["NotifTemplate"],
    }),
    updateNotifTemplate: builder.mutation({
      query: (notifTemplate) => ({
        url: `notif-templates/${notifTemplate.id}`,
        method: "PUT",
        body: notifTemplate,
      }),
      invalidatesTags: ["NotifTemplate", "SingleNotifTemplate"],
    }),
    deleteNotifTemplate: builder.mutation({
      query: (notifTemplateId) => ({
        url: `notif-templates/${notifTemplateId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["NotifTemplate"],
    }),
  }),
});

export const {
  useGetNotifTemplateQuery,
  useGetSingleNotifTemplateQuery,
  useGetCreateNotifTemplateDataQuery,
  useAddNotifTemplateMutation,
  useUpdateNotifTemplateMutation,
  useDeleteNotifTemplateMutation,
} = notifTemplateApiSlice;
