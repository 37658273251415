import { apiSlice } from "../../app/api/apiSlice";

const dataForm = (data) => {
  const formdata = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (key !== "images") {
      formdata.append(key, value);
    }
  }
  return formdata;
};

export const mealCategoriesApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["MealCategories"],
  endpoints: (builder) => ({
    getMealCategories: builder.query({
      query: ({ url }) => url,
      providesTags: ["MealCategories"],
      //   keepUnusedDataFor: 5,
    }),
    addMealCategory: builder.mutation({
      query: (mealCategory) => ({
        url: "meal-categories",
        method: "POST",
        body: dataForm(mealCategory),
      }),
      invalidatesTags: ["MealCategories"],
    }),
    updateMealCategory: builder.mutation({
      query: (mealCategory) => ({
        url: `meal-categories/${mealCategory.id}`,
        method: "POST",
        body: dataForm(mealCategory),
      }),
      invalidatesTags: ["MealCategories"],
    }),
    deleteMealCategory: builder.mutation({
      query: (mealCategoryId) => ({
        url: `meal-categories/${mealCategoryId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MealCategories"],
    }),
  }),
});

export const {
  useGetMealCategoriesQuery,
  useAddMealCategoryMutation,
  useUpdateMealCategoryMutation,
  useDeleteMealCategoryMutation,
} = mealCategoriesApiSlice;
