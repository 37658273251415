import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

export const optionsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Options"],
  endpoints: (builder) => ({
    getOptions: builder.query({
      query: ({url}) => url,
      providesTags: ["Options"],
    }),
    getCreateOptionData: builder.query({
      query: () => "options/create",
    }),
    addOption: builder.mutation({
      query: (option) => ({
        url: "options",
        method: "POST",
        body: { ...option },
      }),
      invalidatesTags: ["Options"],
    }),
    updateOption: builder.mutation({
      query: (option) => ({
        url: `options/${option.id}`,
        method: "PUT",
        body: dataForm(option),
      }),
      invalidatesTags: ["Options"],
    }),
    deleteOption: builder.mutation({
      query: (optionId) => ({
        url: `options/${optionId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Options"],
    }),
  }),
});

export const {
  useGetOptionsQuery,
  useGetCreateOptionDataQuery,
  useAddOptionMutation,
  useUpdateOptionMutation,
  useDeleteOptionMutation,
} = optionsApiSlice;
