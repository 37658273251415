import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentToken, selectRoles, setCredentials } from "../authSlice";
import {
  useLoginMutation,
  useLoginQuery,
  useSendResetPasswordMailMutation,
} from "../authApiSlice";
import Swal from "sweetalert2";

function ForgottenPassword() {
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const token = useSelector(selectCurrentToken);

  const [sendResetPasswordMail, { isLoading }] =
    useSendResetPasswordMailMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    sendResetPasswordMail({ username: user })
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Veuillez consulter votre boîte mail`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        navigate(`/otp/password-reset/${user}`);
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Impossible d'envoyer le mail`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        errRef.current.focus();
      });
  };

  const handleUserInput = (e) => setUser(e.target.value);

  return (
    <main id="content" role="main" className="">
      {/* <!-- ========== MAIN CONTENT ========== --> */}
      <div
        className="position-fixed top-0 end-0 start-0 bg-img-start"
        style={{
          height: "32rem",
          backgroundImage: 'url("./assets/svg/components/card-6.svg")',
        }}
      >
        {/* <!-- Shape --> */}
        <div className="shape shape-bottom zi-1">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </div>
        {/* <!-- End Shape --> */}
      </div>
      {/* <!-- Content --> */}
      <div className="container py-5 py-sm-7">
        <a className="d-flex justify-content-center mb-5" href="./index.html">
          <img
            className="zi-2"
            src="./assets/svg/logos/logo.svg"
            alt="Image Description"
            style={{ width: "8rem" }}
          />
        </a>

        <div className="mx-auto" style={{ maxWidth: "30rem" }}>
          {/* <!-- Card --> */}
          <div className="card card-lg mb-5">
            <div className="card-body">
              {/* <!-- Form --> */}
              {isLoading ? (
                <h1>Loading ...</h1>
              ) : (
                <div className="">
                  <p ref={errRef}>{errorMessage}</p>
                  <form
                    onSubmit={handleSubmit}
                    className="js-validate needs-validation"
                    noValidate
                  >
                    <div className="text-center">
                      <div className="mb-5">
                        <h1 className="display-5">Mot de passe oublié</h1>
                      </div>
                    </div>

                    {/* <!-- Form --> */}
                    <div className="mb-4">
                      <label className="form-label" htmlFor="username">
                        Votre email
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        name="email"
                        id="username"
                        ref={userRef}
                        value={user}
                        onChange={handleUserInput}
                        tabIndex="1"
                        placeholder="email@address.com"
                        aria-label="email@address.com"
                        required
                      />
                      <span className="invalid-feedback">
                        Please enter a valid email address.
                      </span>
                    </div>
                    {/* <!-- End Form --> */}

                    <p>Envoyer un mail pour changer mon mot de passe</p>

                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary btn-lg">
                        Envoyer
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {/* <!-- End Form --> */}
            </div>
          </div>
          {/* <!-- End Card --> */}
        </div>
      </div>
      {/* <!-- End Content --> */}
      {/* <!-- ========== END MAIN CONTENT ========== --> */}
    </main>
  );
}

export default ForgottenPassword;
