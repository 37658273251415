import React, { useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import { Link } from "react-router-dom";
import { useGetAdminOrdersQuery } from "./adminOrdersApiSlice";
import STATUS from "../../components/Shared/Status";

function AdminOrderList() {
  const [baseUrl, setBaseUrl] = useState("orders");
  const [url, setUrl] = useState(baseUrl);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAdminOrdersQuery({ url });

  const columns = [
    {
      name: "Référence",
      selector: "reference",
      sortable: true,
    },
    {
      name: "Client",
      selector: "client",
      sortable: true,
    },
    {
      name: "Montant total",
      selector: "total_amount",
      type: "price",
    },
    {
      name: "Statut",
      selector: "status",
      type: "status",
    },
  ];

  let data = [];
  let meta = {};

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((order) => ({
      id: order.id,
      reference: order.reference,
      client: order.client.identity.fullName,
      status: order.status,
      accepted_at: order.treatment?.accepted_at,
      set_as_ready_at: order.treatment?.set_as_ready_at,
      set_as_processing_at: order.treatment?.set_as_processing_at,
      set_as_ready_by_id: order.treatment?.set_as_ready_by_id,
      date: order.created_at,
      total_amount: order.transaction.total_amount,
    }));
    data.sort(function (a, b) {
      var keyA = new Date(a.date),
        keyB = new Date(b.date);
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (order) => {
    return (
      <div className="fw-bold d-flex">
        <Link to={`/order/${order.id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
            type="button"
          >
            <i className="bi bi-eye fs-3"></i>
          </button>
        </Link>
      </div>
    );
  };

  return (
    <div className="container">
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        baseUrl={baseUrl}
        setUrl={setUrl}
        meta={meta}
      />
    </div>
  );
}

export default AdminOrderList;
