import React from "react";
import Swal from "sweetalert2";
import {
  useDeleteRestaurantMutation,
  useGetRestaurantsQuery,
} from "./restaurantsApiSlice";
import { useNavigate } from "react-router-dom";

function DeleteRestaurantModal({ restaurant }) {
  const [deleteRestaurant] = useDeleteRestaurantMutation();
  const { refetch } = useGetRestaurantsQuery();

  const navigate = useNavigate();

  async function displayConfirmation() {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Voulez-vous vraiment supprimer ${restaurant.name} ?`,
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isDeleteConfirmed = swal.isConfirmed;

    if (isDeleteConfirmed) {
      deleteRestaurant(restaurant.id)
        .unwrap()
        .then(() => {
          Swal.fire({
            title: "Succees",
            text: `Restaurant supprimé avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((response) => {
          Swal.fire({
            title: "Erreur",
            text: `Erreur lors de la suppression du restaurant`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    }
  }
  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        onClick={displayConfirmation}
        title="Supprimer"
      >
        <i className="bi bi-trash fs-3"></i>
      </button>
    </div>
  );
}

export default DeleteRestaurantModal;
