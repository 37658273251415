import React, { useEffect } from "react";
import { useGeolocated } from "react-geolocated";

const Geolocation = ({ handleCoords }) => {
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  useEffect(() => {
    if (isGeolocationEnabled) {
      handleCoords(coords);
    }
  }, [coords, handleCoords, isGeolocationEnabled]);

  return !isGeolocationAvailable ? (
    <div className="text-danger fw-bold">
      Votre navigateur ne supporte pas la géolocalisation
    </div>
  ) : !isGeolocationEnabled ? (
    <div className="text-danger fw-bold">
      Veuillez autoriser la géolocalisation
    </div>
  ) : coords ? (
    <>
      <div className="row">
        <div className="col-md-6 mt-3">
          <p>Latitude: {coords.latitude}</p>
        </div>

        <div className="col-md-6 mt-3">
          <p>Longitude: {coords.longitude}</p>
        </div>

        <div className="col-md-3 mt-3 d-none">
          <p>Altitude: {coords.altitude}</p>
        </div>

        <div className="col-md-3 mt-3 d-none">
          <p>En tete: {coords.heading}</p>
        </div>

        <div className="col-md-3 mt-3 d-none">
          <p>Vitesse: {coords.speed}</p>
        </div>
      </div>
    </>
  ) : (
    <div>Détection de la localisation&hellip; </div>
  );
};

export default Geolocation;
