import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

const metaDataApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["MetaData", "SingleMetaData"],
  endpoints: (builder) => ({
    getMetaData: builder.query({
      query: ({ url }) => url,
      providesTags: ["MetaData"],
    }),
    getSingleMetaData: builder.query({
      query: ({ metaDataId }) => {
        return `meta-data/${metaDataId}`;
      },
      providesTags: ["SingleMetaData"],
    }),
    getCreateMetaData: builder.query({
      query: () => "meta-data/create",
    }),
    addMetaData: builder.mutation({
      query: (metaData) => ({
        url: "meta-data",
        method: "POST",
        body: { ...metaData },
      }),
      invalidatesTags: ["MetaData"],
    }),
    updateMetaData: builder.mutation({
      query: (metaData) => ({
        url: `meta-data/${metaData.id}`,
        method: "POST",
        body: { ...metaData, _method: "put" },
      }),
      invalidatesTags: ["MetaData", "SingleMetaData"],
    }),
    deleteMetaData: builder.mutation({
      query: (metaDataId) => ({
        url: `meta-data/${metaDataId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MetaData"],
    }),
  }),
});

export const {
  useGetMetaDataQuery,
  useGetSingleMetaDataQuery,
  useGetCreateMetaDataDataQuery,
  useAddMetaDataMutation,
  useUpdateMetaDataMutation,
  useDeleteMetaDataMutation,
} = metaDataApiSlice;
