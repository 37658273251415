import { createSlice } from "@reduxjs/toolkit";

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState: { selectedRestaurant: null, days: null, categories: null },
  reducers: {
    setSelectedRestaurant: (state, action) => {
      const { restaurant } = action.payload;
      state.selectedRestaurant = restaurant;
    },
    setCreateRestaurantData: (state, action) => {
      const { days, categories } = action.payload;
      state.days = days;
      state.categories = categories;
    },
    resetRestaurant: (state, action) => {
      state.selectedRestaurant = null;
      state.days = null;
      state.categories = null;
    },
  },
});

export const {
  setSelectedRestaurant,
  setCreateRestaurantData,
  resetRestaurant,
} = restaurantSlice.actions;

export default restaurantSlice.reducer;

export const selectSelectedRestaurant = (state) =>
  state.restaurant.selectedRestaurant;

export const selectDays = (state) => state.restaurant.days;

export const selectCategories = (state) => state.restaurant.categories;
