import { apiSlice } from "../../app/api/apiSlice";

const dataForm = (data) => {
  const formdata = new FormData();
  const entriesAsArray = ["images", "supplements", "options"];

  for (const [key, value] of Object.entries(data)) {
    if (!entriesAsArray.includes(key)) {
      formdata.append(key, value);
    }
  }

  entriesAsArray.forEach((entry) => {
    data[entry].forEach((element, index) => {
      formdata.append(`${entry}[${index}]`, element);
    });
  });

  return formdata;
};

export const supplementsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Supplements"],
  endpoints: (builder) => ({
    getSupplements: builder.query({
      query: () => "supplements",
      providesTags: ["Supplements"],
    }),
    getCreateSupplementData: builder.query({
      query: () => "supplements/create",
    }),
    addSupplement: builder.mutation({
      query: (supplement) => ({
        url: "supplements",
        method: "POST",
        body: { ...supplement },
      }),
      invalidatesTags: ["Supplements"],
    }),
    updateSupplement: builder.mutation({
      query: (supplement) => ({
        url: `supplements/${supplement.id}`,
        method: "POST",
        body: { ...supplement },
      }),
      invalidatesTags: ["Supplements"],
    }),
    deleteSupplement: builder.mutation({
      query: (supplementId) => ({
        url: `supplements/${supplementId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Supplements"],
    }),
  }),
});

export const {
  useGetSupplementsQuery,
  useGetCreateSupplementDataQuery,
  useAddSupplementMutation,
  useUpdateSupplementMutation,
  useDeleteSupplementMutation,
} = supplementsApiSlice;
