import React, { useEffect, useState } from "react";
import Title from "../../components/Shared/Title";
import { v4 as uuidv4 } from "uuid";
import Geolocation from "../../components/Shared/Geolocation";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  useAddPromotionMutation,
  useGetCreatePromotionDataQuery,
  useGetPromotionsQuery,
} from "./promotionsApiSlice";
import { selectRoles } from "../auth/authSlice";
import { selectSelectedRestaurant } from "../restaurants/restaurantSlice";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Input from "../../components/formik/Input";
import SearchableSelect from "./SearchableSelect";

function CreatePromotion() {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState(null);
  const [typeOptions, setTypeOptions] = useState(null);
  const [value, setValue] = useState(null);
  const [maxBeneficiaries, setMaxBeneficiaries] = useState(null);
  const [valueType, setValueType] = useState(null);
  const [valueTypeOptions, setValueTypeOptions] = useState(null);
  const [calculationBasis, setCalculationBasis] = useState(null);
  const [calculationBasisOptions, setCalculationBasisOptions] = useState(null);
  const [cible, setCible] = useState(null);
  const [cibleOptions, setCibleOptions] = useState(null);
  const [validity, setValidity] = useState(null);
  const [validityOptions, setValidityOptions] = useState(null);
  const [mealOptions, setMealOptions] = useState(null);
  const [meals, setMeals] = useState([]);
  const [maxUsage, setMaxUsage] = useState(null);
  const [cummulative, setCummulative] = useState(null);
  const [applicate, setApplicate] = useState(null);
  // const [currentUserRestaurant, setCurrentUser]

  const [addPromotion] = useAddPromotionMutation();
  const navigate = useNavigate();

  const createRequest = useGetCreatePromotionDataQuery();

  const selectedRestaurant = useSelector(selectSelectedRestaurant);
  const currentRoles = useSelector(selectRoles);
  const currentRolesNames = currentRoles?.map((role) => role.name);

  useEffect(() => {
    if (createRequest.isSuccess) {
      const { cible, type, value_type, validity, meals } = createRequest.data;
      setCibleOptions(cible);
      setCalculationBasisOptions(cible);
      setTypeOptions(type);
      setValueTypeOptions(value_type);
      setValidityOptions(validity);
      const formattedMealOptions = meals.map((meal) => ({
        label: meal.name,
        value: meal.id,
      }));
      setMealOptions(formattedMealOptions);
    }
  }, [createRequest.isLoading, createRequest.isSuccess]);

  const handleSubmit = ({
    name,
    code,
    value,
    maxBeneficiaries,
    maxUsage,
    calculationBasis,
    cummulative,
  }) => {
    const data = {
      name,
      code,
      value_type: valueType.value,
      value,
      maximum_beneficiaries: maxBeneficiaries,
      calculation_basis: calculationBasis,
      max_usage: maxUsage,
      applicate: applicate.value,
      restaurant_id: selectedRestaurant?.id,
      cummulative,
      status: "valid",
    };

    console.log("PROMO DATA ", data);

    addPromotion(data)
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Code promo créé avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        navigate("/promotion");
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la création du code promo`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  return (
    <div>
      <Title backPath="/promotion" title="Nouveau code promo" />
      {/* <!-- Body --> */}
      <div className="container-fluid">
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
            code: "",
            value: 0,
            maxBeneficiaries: 0,
            maxUsage: 0,
            cummulative: 0,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Obligatoire"),
            code: Yup.string().required("Obligatoire"),
            value: Yup.number()
              .required("Obligatoire")
              .positive("doit strictement être positif")
              .integer("entier"),
            maxBeneficiaries: Yup.number()
              .required("Obligatoire")
              .positive("doit strictement être positif")
              .integer("entier"),
            maxUsage: Yup.number()
              .required("Obligatoire")
              .positive("doit être strictement positif")
              .integer("entier"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit({ ...values });
            setSubmitting(false);
            resetForm();
          }}
        >
          <Form className="">
            <div className="row">
              <div className="col-md-3 mt-3">
                <Input
                  label="Nom"
                  name="name"
                  type="text"
                  placeholder="Nom"
                  className="form-control"
                />
              </div>

              <div className="col-md-3 mt-3">
                <Input
                  label="Code"
                  name="code"
                  type="text"
                  placeholder="Code"
                  className="form-control"
                />
              </div>

              <div className="col-md-3 mt-3">
                <Input
                  label="Bénéficiaires"
                  name="maxBeneficiaries"
                  type="number"
                  min="0"
                  placeholder="Nbr. max bénéficiaires"
                  className="form-control"
                />
              </div>

              <div className="col-md-3 mt-3">
                <Input
                  label="Nbr. max usage"
                  name="maxUsage"
                  type="number"
                  min="0"
                  placeholder="Nbr. max usage"
                  className="form-control"
                />
              </div>

              <div className="col-md-3 mt-3">
                <Input
                  label="Valeur"
                  name="value"
                  type="number"
                  placeholder="Valeur"
                  className="form-control"
                />
              </div>

              <div className="col-md-3 mt-3">
                <Input
                  label="Cummulable (n) fois"
                  name="cummulative"
                  type="number"
                  placeholder="Cummulable (n) fois"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mt-3">
                <p>Type de valeur</p>
                <Select
                  name="valueType"
                  options={valueTypeOptions}
                  isClearable
                  isSearchable
                  onChange={setValueType}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>

              <div className="col-md-3 mt-3">
                <p>Applicable à</p>
                <Select
                  name="applicate"
                  options={[
                    { label: "Tout le monde", value: "all" },
                    { label: "Client(s)", value: "clients" },
                    { label: "Restaurant(s)", value: "restaurants" },
                    { label: "Repas", value: "meals" },
                  ]}
                  isClearable
                  isSearchable
                  onChange={setApplicate}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>

              <SearchableSelect />

              <div className="col-md-3 mt-3">
                <p>Repas</p>
                <AsyncSelect
                  isMulti
                  name="meals"
                  options={mealOptions}
                  defaultOptions
                  isClearable
                  isSearchable
                  onChange={setMeals}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>

              <div className="col-md-3 mt-3">
                <p>Restaurants</p>
                <Select
                  isMulti
                  name="meals"
                  options={mealOptions}
                  isClearable
                  isSearchable
                  onChange={setMeals}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>

              <div className="col-md-3 mt-3">
                <p>Base de calcule</p>
                <Select
                  name="calculusBasis"
                  options={calculationBasisOptions}
                  isClearable
                  isSearchable
                  onChange={setCalculationBasis}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>
            <div className="mt-5 d-flex bd-highlight justify-content-start">
              <div className="mx-2">
                <button className="btn btn-primary" type="submit">
                  Créer
                </button>
              </div>

              <div className="mx-2">
                <Link to="/promotion">
                  <button className="btn btn-white" type="button">
                    Annuler
                  </button>
                </Link>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default CreatePromotion;
