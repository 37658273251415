import React from "react";
import Swal from "sweetalert2";
import {
  useDeleteRestaurantCategoryMutation,
  useGetRestaurantCategoriesQuery,
} from "./restaurantCategoriesApiSlice";

function DeleteRestaurantCategoryModal({ restaurantCategory, refetch }) {
  const [deleteRestaurantCategory] = useDeleteRestaurantCategoryMutation();
  // const { refetch } = useGetRestaurantCategoriesQuery();

  async function displayConfirmation() {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Voulez-vous vraiment supprimer  ${restaurantCategory.name} ?`,
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isDeleteConfirmed = swal.isConfirmed;
    if (isDeleteConfirmed) {
      // triger restaurant category deletion
      deleteRestaurantCategory(restaurantCategory.id)
        .unwrap()
        .then(() => {
          refetch();

          Swal.fire({
            title: "Succees",
            text: `Catégorie de restaurant supprimée avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((response) => {
          console.log("CREATE RESTAURANT CATEGORY MODAL ERROR", response);
        });
    }
  }

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        onClick={displayConfirmation}
      >
        <i className="bi bi-trash fs-3"></i>
      </button>
    </div>
  );
}

export default DeleteRestaurantCategoryModal;
