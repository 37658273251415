import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken, selectRoles } from "./authSlice";

import React from "react";
import { selectSelectedRestaurant } from "../restaurants/restaurantSlice";

const RequireAuth = ({ on = "page", roles, any = true }) => {
  const token = useSelector(selectCurrentToken);
  const currentRoles = useSelector(selectRoles);
  const currentRolesNames = currentRoles?.map((role) => role.name);
  const selectedRestaurant = useSelector(selectSelectedRestaurant);
  const location = useLocation();

  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
