import React, { useEffect, useState } from "react";
import Title from "../../components/Shared/Title";
import { Link, useNavigate } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import {
  useAddMealMutation,
  useGetCreateMealDataQuery,
  useGetMealsQuery,
} from "./mealsApiSlice";
import Select from "react-select";
import { useSelector } from "react-redux";
import { selectRoles } from "../auth/authSlice";
import { selectSelectedRestaurant } from "../restaurants/restaurantSlice";

function CreateMeal() {
  const [name, setName] = useState("");
  const [images, setImages] = useState([{ key: nanoid(), value: null }]);
  const [supplements, setSupplements] = useState([
    { key: nanoid(), supplement_id: null, price: 0 },
  ]);
  const [description, setDescription] = useState(null);
  const [price, setPrice] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [restaurantOptions, setRestaurantOptions] = useState(null);
  const [mealCategory, setMealCategory] = useState(null);
  const [mealCategoryOptions, setMealCategoryOptions] = useState(null);
  const [mealSupplements, setMealSupplements] = useState(null);
  const [mealSupplementOptions, setMealSupplementOptions] = useState(null);
  const [mealSupplementObjects, setMealSupplementObjects] = useState(null);
  const [mealOptions, setMealOptions] = useState(null);
  const [mealOptionOptions, setMealOptionOptions] = useState(null);
  const [tagOptions, setTagOptions] = useState(null);
  const [tags, setTags] = useState(null);
  const [mealOptionObjects, setMealOptionObjects] = useState(null);

  const currentRoles = useSelector(selectRoles);
  const currentRolesNames = currentRoles?.map((role) => role.name);
  const selectedRestaurant = useSelector(selectSelectedRestaurant);

  const [addMeal] = useAddMealMutation();
  const navigate = useNavigate();

  const createRequest = useGetCreateMealDataQuery();

  useEffect(() => {
    if (createRequest.isSuccess) {
      const { restaurants, categories, supplements, options, tags } =
        createRequest.data;
      const formattedRestaurants = restaurants.map((restaurant) => ({
        label: restaurant.name,
        value: restaurant.id,
      }));
      setRestaurantOptions(formattedRestaurants);
      const formattedCategories = categories.map((category) => ({
        label: category.name,
        value: category.id,
      }));
      setMealCategoryOptions(formattedCategories);
      setMealSupplementObjects(supplements);
      const formattedSupplements = supplements.map((supplement) => ({
        label: supplement.name,
        value: supplement.id,
      }));
      setMealSupplementOptions(formattedSupplements);

      setMealOptionObjects(options);
      const formattedOptions = options.map((option) => ({
        label: option.name,
        value: option.id,
      }));
      setMealOptionOptions(formattedOptions);

      const formattedTagsOptions = tags.map((tag) => ({
        label: tag.name,
        value: tag.id,
      }));
      setTagOptions(formattedTagsOptions);
    }
  }, [createRequest.isLoading, createRequest.isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let files = images.map((image) => image.value);

    const options = mealOptions?.map((option) => option.value);
    const tagsSelected = tags?.map((tag) => tag.value);

    let data = {
      name,
      description,
      price,
      meal_category_id: mealCategory.value,
      images: files,
      supplements,
      options,
      tags: tagsSelected,
    };
    data.is_suggested = 0;

    if (
      ["restaurant-admin"].some((role) => currentRolesNames?.includes(role))
    ) {
      data.restaurant_id = selectedRestaurant.id;
    } else {
      data.restaurant_id = restaurant?.value;
    }

    addMeal(data)
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Repas créé avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        navigate("/meal");
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la création du repas`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  const handleUpload = (file, key) => {
    let imagesCopy = [...images];
    const index = imagesCopy.findIndex((image) => image.key === key);
    imagesCopy[index].value = file;
    setImages(imagesCopy);
  };

  const addImage = () => {
    const imagesCopy = [...images];
    imagesCopy.push({ key: nanoid(), value: "" });
    setImages(imagesCopy);
  };

  const addSupplement = () => {
    const supplementsCopy = [...supplements];
    supplementsCopy.push({ key: nanoid(), supplement_id: null, price: 0 });
    setSupplements(supplementsCopy);
  };

  const onImageDelete = ({ key }) => {
    if (images.length <= 1) {
      setImages([{ key: nanoid(), value: "" }]);
      return;
    }
    let imagesCopy = [...images];
    const index = imagesCopy.findIndex((image) => image.key === key);
    imagesCopy.splice(index, 1);
    setImages(imagesCopy);
  };

  const onSupplementDelete = ({ key }) => {
    if (supplements.length <= 1) {
      setSupplements([{ key: nanoid(), supplement_id: null, price: 0 }]);
      return;
    }
    let supplementsCopy = [...supplements];
    const index = supplementsCopy.findIndex(
      (supplement) => supplement.key === key
    );
    supplementsCopy.splice(index, 1);
    setSupplements(supplementsCopy);
  };

  const handleSupplementChange = (element, key) => {
    let supplementsCopy = [...supplements];
    const index = supplementsCopy.findIndex(
      (supplement) => supplement.key === key
    );
    supplementsCopy[index].supplement_id = element?.value;
    setSupplements(supplementsCopy);
  };

  const handleSupplementPriceChange = (price, key) => {
    let supplementsCopy = [...supplements];
    const index = supplementsCopy.findIndex(
      (supplement) => supplement.key === key
    );
    console.log("SUPPLEMENT", supplementsCopy[index]);
    supplementsCopy[index].price = price;
    setSupplements(supplementsCopy);
  };

  return (
    <div>
      <Title backPath="/meal" title="Nouveau repas" />
      {/* <!-- Body --> */}
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="row">
          <div className="col-md-3 mt-3 d-flex align-items-start flex-column">
            <p className="fw-bold">Nom</p>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              placeholder="Nom"
              aria-label="Nom"
            />
          </div>

          <div className="col-md-6 mt-3 d-flex align-items-start flex-column">
            <p className="fw-bold">Description</p>
            <textarea
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
              placeholder="Description"
              aria-label="Description"
            />
          </div>

          <div className="col-md-3 mt-3 d-flex align-items-start flex-column">
            <p className="fw-bold">Prix</p>
            <input
              type="number"
              className="form-control"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Prix"
              aria-label="Prix"
            />
          </div>

          <div
            className={`col-md-4 mt-3 ${
              ["restaurant-admin"].some((role) =>
                currentRolesNames?.includes(role)
              )
                ? "d-none"
                : ""
            }`}
          >
            <p className="fw-bold">Restaurant</p>
            <Select
              name="restaurants"
              options={restaurantOptions}
              isClearable
              isSearchable
              onChange={setRestaurant}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div className="col-md-4 mt-3">
            <p className="fw-bold">Catégorie de repas</p>
            <Select
              name="calculusBasis"
              options={mealCategoryOptions}
              isClearable
              isSearchable
              onChange={setMealCategory}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div className="col-md-4 mt-3">
            <p className="fw-bold">Options du repas</p>
            <Select
              name="options"
              isMulti
              options={mealOptionOptions}
              isClearable
              isSearchable
              onChange={setMealOptions}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div className="col-md-4 mt-3">
            <p className="fw-bold">Tags</p>
            <Select
              name="tags"
              isMulti
              options={tagOptions}
              isClearable
              isSearchable
              onChange={setTags}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div className="row">
            {supplements.map((supplement, index) => (
              <div className="col-md-4 mt-3">
                <div className="">
                  <p className="fw-bold">Supplément {index + 1} </p>
                  <div className="row">
                    <div className="row">
                      <div className="col-md-8">
                        <Select
                          name="supplements"
                          options={mealSupplementOptions}
                          isClearable
                          isSearchable
                          onChange={(element) =>
                            handleSupplementChange(element, supplement.key)
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control"
                          id="price"
                          value={supplement.price}
                          onChange={(e) =>
                            handleSupplementPriceChange(
                              e.target.value,
                              supplement.key
                            )
                          }
                          placeholder="Prix"
                          aria-label="Prix"
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <button
                          onClick={() =>
                            onSupplementDelete({ key: supplement.key })
                          }
                          className="btn btn-ghost-secondary btn-icon rounded-circle"
                          type="button"
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-md-4 mt-3">
              <button
                className="btn btn-ghost-secondary btn-icon rounded-circle"
                type="button"
                onClick={addSupplement}
              >
                <i className="bi bi-plus"></i>
              </button>
            </div>
          </div>

          <div className="row">
            {images.map((image, index) => (
              <div className="col-md-4 mt-3 d-flex  align-items-start">
                <div className="">
                  <label htmlFor="mealCategoryImage" className="form-label">
                    {image?.value?.name ?? "Image"}
                  </label>

                  <input
                    type="file"
                    id="image"
                    value=""
                    onChange={(e) => {
                      handleUpload(e.target.files[0], image.key);
                    }}
                    className="form-control"
                  />
                </div>

                <div>
                  <button
                    onClick={() => onImageDelete({ key: image.key })}
                    className="btn btn-ghost-secondary btn-icon rounded-circle"
                    type="button"
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            ))}
            <div className="col-md-3 mt-3">
              <button
                className="btn btn-ghost-secondary btn-icon rounded-circle"
                type="button"
                onClick={addImage}
              >
                <i className="bi bi-plus"></i>
              </button>
            </div>
          </div>

          <div className="mt-5 d-flex bd-highlight justify-content-start">
            <div className="mx-2">
              <button className="btn btn-primary" type="submit">
                Créer
              </button>
            </div>

            <div className="mx-2">
              <Link to="/meal">
                <button className="btn btn-white" type="button">
                  Annuler
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateMeal;
