import React, { useState } from "react";
import {
  useAddMealCategoryMutation,
  useGetMealCategoriesQuery,
} from "./mealCategoriesApiSlice";
import Swal from "sweetalert2";

function CreateMealCategoryModal() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  const [addMealCategory] = useAddMealCategoryMutation();

  const { refetch } = useGetMealCategoriesQuery();

  const handleSubmit = (e) => {
    e.preventDefault();

    // add meal category
    addMealCategory({ name, description, image })
      .unwrap()
      .then(() => {
        refetch();
        Swal.fire({
          title: "Succees",
          text: `Catégorie de repas créée avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        console.log("ERROR RESATUARANT CATEGORY", error);

        let message = "<ul>";
        for (const [key, value] of Object.entries(error.data.errors)) {
          message += `<li>${key} : ${value.join(" ")}</li>`;
        }
        message += "</ul>";

        Swal.fire({
          title: "Erreur",
          html: message,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 15000,
          timerProgressBar: true,
        });
      });

    setName("");
    setDescription("");
    setImage(null);
  };

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#createMealCategoryModal"
      >
        <i className="bi bi-plus fs-3"></i>
      </button>

      <div
        className="modal fade"
        id="createMealCategoryModal"
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Création d'une catégorie de repas
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <div className="modal-body">
              <div className="container-fluid">
                <form
                  className="row"
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                >
                  <div className="col-md-6 mt-3 d-flex align-items-end">
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      placeholder="Nom de la catégorie"
                      aria-label="Nom de la catégorie"
                    />
                  </div>

                  <div className="col-md-6 mt-3">
                    <label htmlFor="mealCategoryImage" className="form-label">
                      {image ? image.name : "Photo"}
                    </label>

                    <input
                      type="file"
                      id="image"
                      value=""
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                      className="form-control"
                    />
                  </div>

                  <div className="col-md-12 mt-3">
                    <textarea
                      type="text"
                      id="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="form-control"
                      placeholder="Description de la catégorie du repas"
                      aria-label="Description de la catégorie du repas"
                    />
                  </div>

                  <div className="mt-5 d-flex bd-highlight justify-content-start">
                    <div className="mx-2">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Créer
                      </button>
                    </div>

                    <div className="mx-2">
                      <button
                        className="btn btn-white"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateMealCategoryModal;
