import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer from "../features/auth/authSlice";
import restaurantReducer from "../features/restaurants/restaurantSlice";
import crudReducer from "../features/crud/crudSlice";
import dataTableReducer from "../features/dataTable/dataTableSlice";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import sessionStorage from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  restaurant: restaurantReducer,
  crud: crudReducer,
  dataTable: dataTableReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, thunk),
  devTools: true, // todo switch to false in production
});

export const persistor = persistStore(store);
