import React, { useEffect, useRef, useState } from "react";
import Title from "../../components/Shared/Title";
import { v4 as uuidv4 } from "uuid";
import Geolocation from "../../components/Shared/Geolocation";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Input from "../../components/formik/Input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useRegisterDeliveryCompanyMutation } from "../auth/authApiSlice";
import TextInput from "../../components/formik/TextInput";

function CreateDeliveryCompany() {
  const errRef = useRef();
  const [user, setUser] = useState("admin@admin.com");
  const [password, setPassword] = useState("password");
  const [telephone, setTelephone] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const [registerDeliveryCompany, { isLoading }] =
    useRegisterDeliveryCompanyMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    setErrorMessage("");
  }, [user, password]);

  const handleSubmit = (data) => {
    data.nbr_of_delivery_men = data.nbrOfDeliveryMen;
    data.telephone = telephone;
    registerDeliveryCompany(data)
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Informations soumises avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        navigate("/delivery-company");
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la soumission des informations`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  const handleUserInput = (e) => setUser(e.target.value);

  return (
    <div>
      <Title
        backPath="/delivery-company"
        title="Nouvelle compagnie de livraison"
      />
      {/* <!-- Body --> */}
      <div className="container-fluid">
        <Formik
          enableReinitialize
          initialValues={{
            firstname: "",
            lastname: "",
            password: "",
            telephone: "",
            email: "",
            name: "",
            description: "",
            address: "",
            slogan: "",
            nbrOfDeliveryMen: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Obligatoire"),
            description: Yup.string().required("Obligatoire"),
            address: Yup.string().required("Obligatoire"),
            email: Yup.string()
              .email("Adresse email invalide")
              .required("*Obligatoire"),
            nbrOfDeliveryMen: Yup.number().required("*Obligatoire"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit({ ...values });
            setSubmitting(false);
            resetForm();
          }}
        >
          <Form className="row">
            <div className="mt-3 col-md-4">
              <Input
                label="Nom du responsable"
                name="lastname"
                type="text"
                placeholder="Nom"
                className="form-control"
              />
            </div>

            <div className="mt-3 col-md-4">
              <Input
                label="Prénom(s) du responsable"
                name="firstname"
                type="text"
                placeholder="Prénom(s)"
                className="form-control"
              />
            </div>

            <div className="mt-3 col-md-4">
              <Input
                label="Mot de passe"
                name="password"
                type="password"
                placeholder=""
                className="form-control"
              />
            </div>

            <div className="mt-3 col-md-4">
              <p>Numéro du responsable</p>
              <PhoneInput
                inputProps={{ className: "border-0 form-control" }}
                country={"bj"}
                enableSearch={false}
                onlyCountries={["bj"]}
                masks={{ bj: "..  ..  ..  .." }}
                disableCountryCode={true}
                disableDropdown={true}
                value={telephone}
                onChange={setTelephone}
                className=""
                containerStyle={{ color: "red" }}
                placeholder="97 97 97 97"
              />
            </div>

            <div className="mt-3 col-md-4">
              <Input
                label="Adresse"
                name="address"
                type="text"
                placeholder="Adresse"
                className="form-control"
              />
            </div>

            <div className="mt-3 col-md-4">
              <Input
                label="Email"
                name="email"
                type="email"
                placeholder=""
                className="form-control"
              />
            </div>

            <div className="mt-3 col-md-4">
              <Input
                label="Nom de la compagnie de livraison"
                name="name"
                type="text"
                placeholder="Compagnie"
                className="form-control"
              />
            </div>

            <div className="mt-3 col-md-4">
              <Input
                label="Slogan"
                name="slogan"
                type="text"
                placeholder="Slogan"
                className="form-control"
              />
            </div>

            <div className="mt-3 col-md-4">
              <Input
                label="Nombre de livreurs"
                name="nbrOfDeliveryMen"
                type="number"
                placeholder=""
                className="form-control"
              />
            </div>

            <div className="mt-3">
              <TextInput
                label="Description de la compagnie de livraison"
                name="description"
                type="text"
                placeholder="Compagnie"
                className="form-control"
              />
            </div>

            <div className="row">
              <div className="mt-5 d-flex bd-highlight justify-content-start">
                <div className="mr-2">
                  <button type="submit" className="btn btn-primary">
                    Créer
                  </button>
                </div>

                <div className="mx-2">
                  <Link to="/delivery-company">
                    <button className="btn btn-white" type="button">
                      Annuler
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Form>
          {/* <form
                      onSubmit={handleSubmit}
                      className="js-validate needs-validation"
                      noValidate
                    >
                      <input type="hidden" name="grant_type" value="password" />
                      <input
                        type="hidden"
                        name="client_id"
                        value="998921fb-65c6-49ce-9e6c-3e852d23761d"
                      />
                      <input
                        type="hidden"
                        name="client_secret"
                        value="Zc7ptgzGfsgHjvZBOmZ1kqVeEY0Jn2ogz0WlcxWa"
                      />
                      
                    </form> */}
        </Formik>
        {/* <form onSubmit={handleSubmit} className="row">
          <div className="col-md-3 mt-3">
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              placeholder="Nom du restaurant"
              aria-label="Nom du restaurant"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="Email"
              aria-label="Email"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Manager"
              aria-label="Manager"
            />
          </div>

          <div className="col-md-3 mt-3">
            <div className="tom-select-custom">
              <select
                className="js-select form-select w-full"
                autoComplete="off"
                id="eventColorLabel"
                data-hs-tom-select-options='{
                        "searchInDropdown": false,
                        "placeholder": "Catégories"
                      }'
              >
                <option
                  value=""
                  data-option-template='<span className="d-flex align-items-center">Catégories</span>'
                >
                  Catégories
                </option>
                <option
                  value="fullcalendar-custom-event-hs-team"
                  data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-primary me-2"></span>HS Team</span>'
                >
                  1 Etoile
                </option>
                <option
                  value="fullcalendar-custom-event-reminders"
                  data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-danger me-2"></span>Reminders</span>'
                >
                  2 Etoiles
                </option>
                <option
                  value="fullcalendar-custom-event-tasks"
                  data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-warning me-2"></span>Tasks</span>'
                >
                  3 Etoiles
                </option>
                <option
                  value="fullcalendar-custom-event-holidays"
                  data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-dark me-2"></span>Success</span>'
                >
                  4 Etoiles
                </option>
              </select>
            </div>
          </div>

          <div className="row">
            {telephones.map((telephone, index) => (
              <div className="col-md-4 mt-3 d-flex">
                <div>
                  <input
                    type="text"
                    value={telephone.value}
                    onChange={(e) =>
                      onTelephoneChange({
                        key: telephone.key,
                        value: e.target.value,
                      })
                    }
                    id="telephone"
                    className="form-control"
                    placeholder="Numero de telephone"
                    aria-label="Numero de telephone"
                  />
                </div>
                <div>
                  <button
                    onClick={() => onTelephoneDelete({ key: telephone.key })}
                    className="btn btn-ghost-secondary btn-icon rounded-circle"
                    type="button"
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            ))}
            <div className="col-md-3 mt-3">
              <button
                className="btn btn-ghost-secondary btn-icon rounded-circle"
                type="button"
                onClick={addTelephone}
              >
                <i className="bi bi-plus"></i>
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mt-3">
              <label htmlFor="formFile" className="form-label">
                Logo
              </label>
              <input className="form-control" type="file" id="formFile" />
            </div>

            <div className="col-md-6 mt-3">
              <label htmlFor="formFile" className="form-label">
                Banière
              </label>
              <input className="form-control" type="file" id="formFile" />
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="form-control"
              placeholder="Adresse du restaurant"
              aria-label="Adresse du restaurant"
            />
          </div>

          <div className="col-md-3 mt-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={canUseCurrentLocation}
                id="datatableCheckAll"
                onChange={() =>
                  setCanUseCurrentLocation(!canUseCurrentLocation)
                }
              />
              <label className="form-check-label" htmlFor="datatableCheckAll">
                Utiliser ma geolocalisation
              </label>
            </div>
          </div>
          {!canUseCurrentLocation ? (
            <>
              <div className="col-md-3 mt-3">
                <input
                  type="text"
                  className="form-control"
                  id="eventLocationLabel"
                  placeholder="Longitude"
                  aria-label="Longitude"
                />
              </div>

              <div className="col-md-3 mt-3">
                <input
                  type="text"
                  className="form-control"
                  id="eventLocationLabel"
                  placeholder="Latitude"
                  aria-label="Latitude"
                />
              </div>
            </>
          ) : (
            <Geolocation />
          )}

          <h6 className="mt-3">Disponibilités</h6>

          <div className="container">
            {availability.map((slot, index) => {
              return (
                <div key={uuidv4()} className="row">
                  <div className="col-md-2 mt-3">
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        id="eventLocationLabel"
                        placeholder={slot.day}
                        aria-label="Heure d'ouverture"
                      />
                    </div>
                  </div>

                  <div className="col-md-1 mt-3 d-flex justify-content-center align-items-center">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheckChecked-${index}`}
                        checked={slot.is_available}
                        onChange={() => switchToggleButton(slot)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`flexSwitchCheckChecked-${index}`}
                      ></label>
                    </div>
                  </div>

                  <div className="col-md-9">
                    <div className="">
                      <div className="d-flex">
                        <div className="col-md-1"></div>
                        <div className="col-md-4 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            id="eventLocationLabel"
                            placeholder="Heure d'ouverture"
                            aria-label="Heure d'ouverture"
                          />
                        </div>
                        <div className="col-md-3 mt-3 d-flex justify-content-center align-items-center">
                          A
                        </div>
                        <div className="col-md-4 mt-3">
                          <input
                            type="text"
                            className="form-control"
                            id="eventLocationLabel"
                            placeholder="Heure de fermeture"
                            aria-label="Heure de fermeture"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="mt-5 d-flex bd-highlight justify-content-start">
            <div className="mx-2">
              <button className="btn btn-primary" type="submit">
                Créer
              </button>
            </div>

            <div className="mx-2">
              <Link to="/restaurant">
                <button className="btn btn-white" type="button">
                  Annuler
                </button>
              </Link>
            </div>
          </div>
        </form> */}
      </div>
    </div>
  );
}

export default CreateDeliveryCompany;
