import React, { useState } from "react";
import { useAddTagMutation, useGetTagsQuery } from "./tagsApiSlice";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Input from "../../components/formik/Input";

function CreateTagModal() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [addTag] = useAddTagMutation();
  const { refetch } = useGetTagsQuery();

  const handleSubmit = (values) => {
    addTag({ ...values })
      .unwrap()
      .then((response) => {
        if (response?.error?.originalStatus === 500) {
          Swal.fire({
            title: "Erreur",
            text: `Erreur du serveur lors de la création du tag`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          refetch();
          Swal.fire({
            title: "Succees",
            text: `Tag créé avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la création du tag`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
    setName("");
    setDescription("");
  };

  return (
    <div>
      <button
        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#createTagModal"
      >
        <i className="bi bi-plus"></i>
      </button>

      {/* <!-- Update Plan Modal --> */}
      <div
        className="modal fade"
        id="createTagModal"
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Création d'un tag
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <Formik
              enableReinitialize
              initialValues={{ name: "", description: "" }}
              validationSchema={Yup.object({
                name: Yup.string().required("Obligatoire"),
                description: Yup.string().required("Obligatoire"),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit({ ...values });
                setSubmitting(false);
                resetForm();
              }}
            >
              <Form className="modal-body">
                <div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-end mt-3">
                        <Input
                          label="Nom"
                          name="name"
                          type="text"
                          placeholder="Nom"
                          className="form-control"
                        />
                      </div>

                      <div className="col-md-6 d-flex align-items-end mt-3">
                        <Input
                          label="Description"
                          name="description"
                          type="text"
                          placeholder="Description"
                          className="form-control"
                        />
                      </div>

                      <div className="mt-5 d-flex bd-highlight justify-content-start">
                        <div className="mx-2">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Créer
                          </button>
                        </div>

                        <div className="mx-2">
                          <button
                            type="button"
                            className="btn btn-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Annuler
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      {/* <!-- End Update Plan Modal --> */}
    </div>
  );
}

export default CreateTagModal;
