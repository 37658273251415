import { apiSlice } from "../../app/api/apiSlice";

const dataForm = (data) => {
  const formdata = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (key !== "images") {
      formdata.append(key, value);
    }
  }
  data.images.forEach((element, index) => {
    formdata.append(`images[${index}]`, element);
  });

  return formdata;
};

const advertisementsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Advertisements"],
  endpoints: (builder) => ({
    getAdvertisements: builder.query({
      query: ({url}) => url,
      providesTags: ["Advertisements"],
    }),
    getCreateAdvertisementData: builder.query({
      query: () => "advertisements/create",
    }),
    addAdvertisement: builder.mutation({
      query: (advertisement) => ({
        url: "advertisements",
        method: "POST",
        body: dataForm({ ...advertisement }),
      }),
      invalidatesTags: ["Advertisements"],
    }),
    updateAdvertisement: builder.mutation({
      query: (advertisement) => ({
        url: `advertisements/${advertisement.id}`,
        method: "POST",
        body: { ...advertisement },
      }),
      invalidatesTags: ["Advertisements"],
    }),
    deleteAdvertisement: builder.mutation({
      query: (advertisementId) => ({
        url: `advertisements/${advertisementId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Advertisements"],
    }),
  }),
});

export const {
  useGetAdvertisementsQuery,
  useGetCreateAdvertisementDataQuery,
  useAddAdvertisementMutation,
  useUpdateAdvertisementMutation,
  useDeleteAdvertisementMutation,
} = advertisementsApiSlice;
