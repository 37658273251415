import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectRoles, setCredentials } from "../authSlice";
import {
  useLoginMutation,
  useResetPasswordMutation,
  useVerifyUserQuery,
} from "../authApiSlice";

import Swal from "sweetalert2";

function ResetPassword() {
  const errRef = useRef();
  const [email, setEmail] = useState("admin@admin.com");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { token } = useParams();

  const currentRoles = useSelector(selectRoles);
  const currentRolesNames = currentRoles?.map((role) => role.name);

  const [login, { isLoading }] = useLoginMutation();
  const [resetPassword] = useResetPasswordMutation();
  const dispatch = useDispatch();

  const verifyUserRequest = useVerifyUserQuery({ token });

  useEffect(() => {
    if (verifyUserRequest.status === "fulfilled") {
      if (verifyUserRequest.isSuccess) {
        setEmail(verifyUserRequest.data.email);
      }
    } else if (verifyUserRequest.status === "rejected") {
      const swal = Swal.fire({
        icon: "error",
        title: verifyUserRequest.error.data.message,
      });
    }

    console.log("verifyUserRequest", verifyUserRequest);
  }, [verifyUserRequest.status]);

  useEffect(() => {
    setErrorMessage("");
  }, [email, password, passwordConfirmation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      const swal = await Swal.fire({
        icon: "error",
        title: "Les mots de passe sont différents",
      });
      return;
    }

    try {
      console.log("RESET PASSWORD DATA", {
        password,
        password_confirmation: passwordConfirmation,
        email,
        token,
        username: email,
      });
      const response = await resetPassword({
        password,
        password_confirmation: passwordConfirmation,
        email,
        token,
        username: email,
      }).unwrap();

      console.log("response", response);

      const userData = await login({ user: email, password }).unwrap();
      dispatch(
        setCredentials({ accessToken: userData.access_token, user: email })
      );
      setEmail("");
      setPassword("");
      setPasswordConfirmation("");

      navigate("/dashboard");
    } catch (error) {
      if (!error?.originalStatus) {
        setErrorMessage("No Server Response");
      } else if (error.originalStatus?.status === 400) {
        setErrorMessage("Missing Username or Password");
      } else if (error.originalStatus?.status === 401) {
        setErrorMessage("Unauthorized");
      } else {
        setErrorMessage("Login failed");
      }
      errRef.current.focus();
    }
  };

  const handlePasswordInput = (e) => setPassword(e.target.value);

  return (
    <main id="content" role="main" className="">
      {/* <!-- ========== MAIN CONTENT ========== --> */}
      <div
        className="position-fixed top-0 end-0 start-0 bg-img-start"
        style={{
          height: "32rem",
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/assets/svg/components/card-6.svg"
          })`,
        }}
      >
        {/* <!-- Shape --> */}
        <div className="shape shape-bottom zi-1">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </div>
        {/* <!-- End Shape --> */}
      </div>
      {/* <!-- Content --> */}
      <div className="container py-5 py-sm-7">
        <a className="d-flex justify-content-center mb-5" href="./index.html">
          <img
            className="zi-2"
            src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.png"}
            alt="Image Description"
            style={{ width: "8rem" }}
          />
        </a>

        <div className="mx-auto" style={{ maxWidth: "30rem" }}>
          {/* <!-- Card --> */}
          <div className="card card-lg mb-5">
            <div className="card-body">
              {/* <!-- Form --> */}
              {isLoading ? (
                <h1>Loading ...</h1>
              ) : (
                <div>
                  <p ref={errRef}>{errorMessage}</p>
                  <form
                    onSubmit={handleSubmit}
                    className="js-validate needs-validation"
                    noValidate
                  >
                    <input type="hidden" name="grant_type" value="password" />
                    <div className="text-center">
                      <div className="mb-5">
                        <h1 className="display-5">Définir le mot de passe</h1>
                      </div>
                    </div>

                    {/* <!-- Password --> */}
                    <div className="mb-4">
                      <label
                        className="form-label w-100"
                        htmlFor="password"
                        tabIndex="0"
                      >
                        <span className="d-flex justify-content-between align-items-center">
                          <span>Mot de passe</span>
                        </span>
                      </label>

                      <div
                        className="input-group input-group-merge"
                        data-hs-validation-validate-class
                      >
                        <input
                          type="password"
                          className=" form-control form-control-lg"
                          name="password"
                          id="password"
                          onChange={handlePasswordInput}
                          value={password}
                          placeholder="8+ caracteres requis"
                          aria-label="8+ caracteres requis"
                          required
                          // minLength="8"
                        />
                      </div>
                    </div>
                    {/* <!-- End Password --> */}

                    {/* <!-- Password Confirmation --> */}
                    <div className="mb-4">
                      <label
                        className="form-label w-100"
                        htmlFor="password"
                        tabIndex="0"
                      >
                        <span className="d-flex justify-content-between align-items-center">
                          <span>Confirmation du mot de passe</span>
                        </span>
                      </label>

                      <div
                        className="input-group input-group-merge"
                        data-hs-validation-validate-class
                      >
                        <input
                          type="password"
                          className="form-control form-control-lg"
                          name="passwordConfirmation"
                          id="passwordConfirmation"
                          onChange={(e) =>
                            setPasswordConfirmation(e.target.value)
                          }
                          value={passwordConfirmation}
                          placeholder="8+ caracteres requis"
                          aria-label="8+ caracteres requis"
                          required
                          // minLength="8"
                        />
                      </div>
                    </div>
                    {/* <!-- End Password --> */}

                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary btn-lg">
                        Valider
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {/* <!-- End Form --> */}
            </div>
          </div>
          {/* <!-- End Card --> */}
        </div>
      </div>
      {/* <!-- End Content --> */}
      {/* <!-- ========== END MAIN CONTENT ========== --> */}
    </main>
  );
}

export default ResetPassword;
