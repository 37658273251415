import React from "react";
import Swal from "sweetalert2";
import { useDeleteSupplementMutation } from "./supplementsApiSlice";

function DeleteSupplementModal({ supplement }) {
  const [deleteSupplement] = useDeleteSupplementMutation();

  async function displayConfirmation() {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Voulez-vous vraiment supprimer ${supplement.name} ?`,
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isDeleteConfirmed = swal.isConfirmed;

    if (isDeleteConfirmed) {
      deleteSupplement(supplement.id)
        .unwrap()
        .then(() => {
          // refetch();
          Swal.fire({
            title: "Succees",
            text: `Supplément supprimée avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((response) => {
          Swal.fire({
            title: "Erreur",
            text: `Impossible de supprimer ${supplement.name}`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    }
  }
  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        onClick={displayConfirmation}
      >
        <i className="bi bi-trash fs-3"></i>
      </button>
    </div>
  );
}

export default DeleteSupplementModal;
