import React, { useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import ReadRestaurantCategoryModal from "../../components/RestaurantCategory/crud/ReadRestaurantCategoryModal";
// import UpdateRestaurantCategoryModal from "./UpdateRestaurantCategoryModal";
// import DeleteRestaurantCategoryModal from "./DeleteRestaurantCategoryModal";
import { useGetTagsQuery } from "./tagsApiSlice";
import ReadTagModal from "../../components/Tag/ReadTagModal";
import UpdateTagModal from "./UpdateTagModal";
import DeleteTagModal from "./DeleteTagModal";

function TagList({ handleModalHasChanged }) {
  const [baseUrl, setBaseUrl] = useState("tags");
  const [url, setUrl] = useState(baseUrl);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetTagsQuery({url});

  const columns = [
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
    },
  ];

  let data = [];
  let meta = {};

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((tag) => ({
      id: tag.id,
      name: tag.name,
      description: tag.description,
    }));

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (tag) => {
    return (
      <div className="fw-bold d-flex">
        <ReadTagModal tag={tag} />
        <UpdateTagModal tag={tag} />
        <DeleteTagModal tag={tag} />
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        baseUrl={baseUrl}
        setUrl={setUrl}
        meta={meta}
      />
    </div>
  );
}

export default TagList;
