import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentToken, selectRoles, setCredentials } from "../authSlice";
import { useLoginMutation, useLoginQuery } from "../authApiSlice";
import Swal from "sweetalert2";

function Login() {
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const token = useSelector(selectCurrentToken);

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    } else {
      userRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setErrorMessage("");
  }, [user, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userData = await login({ user, password }).unwrap();
      dispatch(setCredentials({ accessToken: userData.access_token, user }));
      setUser("");
      setPassword("");
      navigate("/dashboard");
    } catch (error) {
      if (!error?.originalStatus) {
        await Swal.fire({
          title: "Connexion échouée",
          text: error.data.message,
          icon: "error",
          toast: true,
          timer: 15000,
          timerProgressBar: true,
        });
      } else if (error.originalStatus?.status === 400) {
        setErrorMessage("Missing Username or Password");
      } else if (error.originalStatus?.status === 401) {
        setErrorMessage("Unauthorized");
      } else {
        setErrorMessage("Login failed");
      }
      errRef.current.focus();
    }
  };

  const handleUserInput = (e) => setUser(e.target.value);

  const handlePasswordInput = (e) => setPassword(e.target.value);

  return (
    <main id="content" role="main" className="">
      {/* <!-- ========== MAIN CONTENT ========== --> */}
      <div
        className="position-fixed top-0 end-0 start-0 bg-img-start"
        style={{
          height: "32rem",
          backgroundImage: 'url("./assets/svg/components/card-6.svg")',
        }}
      >
        {/* <!-- Shape --> */}
        <div className="shape shape-bottom zi-1">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </div>
        {/* <!-- End Shape --> */}
      </div>
      {/* <!-- Content --> */}
      <div className="container py-5 py-sm-7">
        <a className="d-flex justify-content-center mb-5" href="./index.html">
          <img
            className="zi-2"
            src="./assets/svg/logos/logo.png"
            alt="Image Description"
            style={{ width: "8rem" }}
          />
        </a>

        <div className="mx-auto" style={{ maxWidth: "30rem" }}>
          {/* <!-- Card --> */}
          <div className="card card-lg mb-5">
            <div className="card-body">
              {/* <!-- Form --> */}
              <div className="">
                <p ref={errRef}>{errorMessage}</p>
                <form
                  onSubmit={handleSubmit}
                  className="js-validate needs-validation"
                  noValidate
                >
                  <input type="hidden" name="grant_type" value="password" />
                  <input
                    type="hidden"
                    name="client_id"
                    value="998921fb-65c6-49ce-9e6c-3e852d23761d"
                  />
                  <input
                    type="hidden"
                    name="client_secret"
                    value="Zc7ptgzGfsgHjvZBOmZ1kqVeEY0Jn2ogz0WlcxWa"
                  />
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-5">Connexion</h1>
                    </div>
                  </div>

                  {/* <!-- Form --> */}
                  <div className="mb-4">
                    <label className="form-label" htmlFor="username">
                      Votre email
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      name="email"
                      id="username"
                      ref={userRef}
                      value={user}
                      onChange={handleUserInput}
                      tabIndex="1"
                      placeholder="email@address.com"
                      aria-label="email@address.com"
                      required
                    />
                    <span className="invalid-feedback">
                      Veuillez saisir un email valide.
                    </span>
                  </div>
                  {/* <!-- End Form --> */}

                  {/* <!-- Form --> */}
                  <div className="mb-4">
                    <label
                      className="form-label w-100"
                      htmlFor="password"
                      tabIndex="0"
                    >
                      <span className="d-flex justify-content-between align-items-center">
                        <span>Mot de passe</span>
                        <Link
                          className="form-label-link mb-0"
                          to="/forgotten-password"
                        >
                          Mot de passe oublié?
                        </Link>
                      </span>
                    </label>

                    <div
                      className="input-group input-group-merge"
                      data-hs-validation-validate-class
                    >
                      <input
                        type="password"
                        className="js-toggle-password form-control form-control-lg"
                        name="password"
                        id="password"
                        onChange={handlePasswordInput}
                        value={password}
                        placeholder="8+ caracteres requis"
                        aria-label="8+ caracteres requis"
                        required
                        minLength="8"
                        data-hs-toggle-password-options='{
                             "target": "#changePassTarget",
                             "defaultClass": "bi-eye-slash",
                             "showClass": "bi-eye",
                             "classChangeTarget": "#changePassIcon"
                           }'
                      />
                      <button
                        id="changePassTarget"
                        className="input-group-append input-group-text d-none"
                        type="button"
                      >
                        <i id="changePassIcon" className="bi-eye"></i>
                      </button>
                    </div>

                    <span className="invalid-feedback">
                      Mot de passe invalide.
                    </span>
                  </div>
                  {/* <!-- End Form --> */}

                  <div className="d-grid">
                    <button
                      type="submit"
                      className={`btn btn-primary btn-lg ${
                        isLoading ? "disabled" : ""
                      }`}
                    >
                      Connexion
                    </button>
                  </div>
                </form>
              </div>

              {/* <!-- End Form --> */}
            </div>
          </div>
          {/* <!-- End Card --> */}
        </div>
      </div>
      {/* <!-- End Content --> */}
      {/* <!-- ========== END MAIN CONTENT ========== --> */}
    </main>
  );
}

export default Login;
