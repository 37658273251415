import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/Shared/Title";
import Rating from "@mui/material/Rating";
import { useGetMealQuery } from "./mealsApiSlice";
import Avatar from "@mui/material/Avatar";
import { nanoid } from "@reduxjs/toolkit";

function ReadMeal() {
  const { mealId } = useParams();
  const [meal, setMeal] = useState({ name: "Repas" });
  const [image, setImage] = useState(null);
  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetMealQuery({ mealId });

  useEffect(() => {
    if (isSuccess) {
      setMeal(response);
      setImage(response.images[0]?.path);
    }
  }, [isLoading, isSuccess]);

  return (
    <>
      {/* Begin presentation */}

      <div className="profile-cover">
        <div className="profile-cover-img-wrapper">
          {true && (
            <img
              className="profile-cover-img"
              src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.png"}
              alt="Bannière"
            />
          )}
        </div>
      </div>

      <div className="text-center mb-5">
        <div className="avatar avatar-xxl avatar-circle profile-cover-avatar">
          {image ? (
            <img
              className="avatar-img"
              src={process.env.REACT_APP_BACK_END + image?.path}
              alt="Restaurant Logo"
            />
          ) : (
            <span className="avatar-initials">
              {response?.name.charAt(0)} {response?.name.charAt(1)}
            </span>
          )}
          <span className="avatar-status avatar-status-success"></span>
        </div>

        <h1 className="page-header-title">{response?.name}</h1>
        <div className="text-center">
          <Rating
            name="half-rating-read"
            defaultValue={2.5}
            precision={0.5}
            readOnly
            size="large"
          />
        </div>
      </div>

      <div className="row">
        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      5
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Ventes</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>

        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      2
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Commandes en attente</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      0
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Promotions</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      7
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Montant des ventes</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}
      </div>

      <div className="content container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="table-responsive datatable-custom">
              <table
                id="datatable"
                className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th>Élément</th>
                    <th>Information</th>
                  </tr>
                </thead>

                <tbody>
                  <tr key={nanoid()}>
                    <td>Nom</td>
                    <td>{meal.name}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Description</td>
                    <td>{meal.description}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Prix</td>
                    <td>{meal.price} Fcfa</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Catégorie</td>
                    <td>{meal.category?.name}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>En promotion</td>
                    <td>{response?.promo_codes.length > 0 ? "Oui" : "Non"}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Tags</td>
                    <td>{meal.tags?.map((tag) => tag.name).join(", ")}</td>
                  </tr>
                  {meal.supplements?.map((supplement, index) => (
                    <tr key={nanoid()}>
                      <td>Supplément </td>
                      <td>{`${supplement.name} (${supplement.pivot.price} Fcfa)`}</td>
                    </tr>
                  ))}
                  {meal.options?.map((option, index) => (
                    <tr key={nanoid()}>
                      <td>Option </td>
                      <td>{option.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="card-footer"></div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card">
              {/* <!-- Header --> */}
              <div class="card-header">
                <h4 class="card-header-title">
                  Restaurant: {meal.restaurant?.name}
                </h4>
              </div>
              {/* <!-- End Header --> */}

              {/* <!-- Body --> */}
              <div class="card-body">
                {/* <!-- List Group --> */}
                <ul class="list-group list-group-flush list-group-no-gutters">
                  <li class="list-group-item">
                    <div class="d-flex align-items-center justify-content-around">
                      <p>Logo</p>
                      <Avatar
                        alt=" "
                        src={meal.restaurant?.logo_url}
                        sx={{ width: 56, height: 56 }}
                      />
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="d-flex align-items-center justify-content-around">
                      <p>Bannière</p>
                      <Avatar
                        alt=" "
                        src={meal.restaurant?.banner_url}
                        sx={{ width: 56, height: 56 }}
                      />
                    </div>
                  </li>

                  {[{}, {}].telephones?.map((item, index) => (
                    <li key={nanoid()} class="list-group-item">
                      <div class="d-flex align-items-center justify-content-around">
                        <p>{`Téléphone ${index + 1}`}</p>
                        <p>{item}</p>
                      </div>
                    </li>
                  ))}
                </ul>
                {/* <!-- End List Group --> */}
              </div>
              {/* <!-- End Body --> */}
            </div>
            {/* <!-- End Card --> */}
          </div>
        </div>
      </div>

      {/* End presentation */}
    </>
  );
}

export default ReadMeal;
