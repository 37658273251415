import React, { useEffect, useState } from "react";
import {
  useGetConversationMessagesQuery,
  useSendNewMessageMutation,
} from "./chatApiSlice";
import Avatar from "@mui/material/Avatar";
import { localDateFormat } from "../../components/Shared/Utils";
import Swal from "sweetalert2";

const ChatConversation = ({ correspondantId }) => {
  const [newMessage, setNewMessage] = useState("");

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetConversationMessagesQuery({ correspondantId });

  const [sendNewMessage] = useSendNewMessageMutation();

  let avatarImg = process.env.PUBLIC_URL + "/assets/svg/logos/logo.png";

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { message: newMessage };

    sendNewMessage({ correspondantId, data })
      .unwrap()
      .then(() => {
        setNewMessage("");
        refetch();
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de l'envoi du message`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  return (
    <section>
      {/* https://mdbootstrap.com/docs/standard/extended/chat/ */}
      <div class="container py-5">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-11">
            <ul class="list-unstyled">
              {response?.messages.map((message) => (
                <li class="d-flex justify-content-between mb-4">
                  <Avatar
                    alt=" "
                    src={message.sender_id === correspondantId ? "" : avatarImg}
                  />
                  <div class="card  w-100 ">
                    <div class="card-header d-flex justify-content-between p-3">
                      {/* sx={{ width: 56, height: 56 }} */}
                      <p class="fw-bold mb-0">
                        {localDateFormat(message?.created_at)}
                      </p>
                      <p class="text-muted small mb-0">
                        <i class="far fa-clock"></i>
                      </p>
                    </div>
                    <div class="card-body">
                      <p class="mb-0">{message.message}</p>
                    </div>
                  </div>
                </li>
              ))}

              <li class="d-flex justify-content-between mb-4">
                {/* bg-white mb-3  */}
                <Avatar alt=" " src={avatarImg} />
                {/* sx={{ width: 56, height: 56 }} */}

                <form
                  onSubmit={handleSubmit}
                  class="form-outline w-100 border-top"
                >
                  <textarea
                    class="form-control"
                    id="textAreaExample2"
                    rows="4"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                  ></textarea>
                  <label class="form-label" for="textAreaExample2">
                    Message
                  </label>
                  <button
                    type="submit"
                    class="btn btn-primary btn-rounded float-end mt-2"
                  >
                    Envoyer
                  </button>
                </form>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatConversation;
