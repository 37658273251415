import React, { useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import ReadRestaurantCategoryModal from "../../components/RestaurantCategory/crud/ReadRestaurantCategoryModal";
import { useGetRestaurantCategoriesQuery } from "./restaurantCategoriesApiSlice";
import UpdateRestaurantCategoryModal from "./UpdateRestaurantCategoryModal";
import DeleteRestaurantCategoryModal from "./DeleteRestaurantCategoryModal";

function RestaurantCategoryList({ handleModalHasChanged }) {
  const [baseUrl, setBaseUrl] = useState("restaurant-categories");
  const [url, setUrl] = useState(baseUrl);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetRestaurantCategoriesQuery({ url });

  const columns = [
    {
      name: "Photo",
      selector: "image",
      sortable: true,
      type: "image",
    },
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
    },
  ];

  let data = [];
  let meta = {};

  let content = "";
  if (isLoading) {
    content = <div>Loading ...</div>;
  } else if (isSuccess) {
    data = response.data.map((restaurantCategory) => ({
      id: restaurantCategory.id,
      name: restaurantCategory.name,
      description: restaurantCategory.description,
      image: restaurantCategory.image_url,
    }));

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
  } else if (error) {
    content = <div>{JSON.stringify(error)}</div>;
  }

  const actions = (restaurantCategory) => {
    return (
      <div className="fw-bold d-flex">
        <ReadRestaurantCategoryModal restaurantCategory={restaurantCategory} />
        <UpdateRestaurantCategoryModal
          restaurantCategory={restaurantCategory}
          handleModalHasChanged={handleModalHasChanged}
        />
        <DeleteRestaurantCategoryModal
          restaurantCategory={restaurantCategory}
          handleModalHasChanged={handleModalHasChanged}
          refetch={refetch}
        />
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        baseUrl={baseUrl}
        setUrl={setUrl}
        meta={meta}
      />
    </div>
  );
}

export default RestaurantCategoryList;
