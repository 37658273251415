import React, { useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import { Link } from "react-router-dom";
import { useGetDeliveryCompaniesQuery } from "./deliveryCompanyApiSlice";
import ValidateDeliveryCompanyModal from "./ValidateDeliveryCompanyModal";
import DeleteDeliveryCompanyModal from "./DeleteDeliveryCompanyModal";
import STATUS from "../../components/Shared/Status";

function DeliveryCompanyList() {
  const [baseUrl, setBaseUrl] = useState("delivery-companies");
  const [url, setUrl] = useState(baseUrl);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetDeliveryCompaniesQuery({ url });

  const columns = [
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
    {
      name: "Statut",
      selector: "status",
    },
    {
      name: "Adresse",
      selector: "address",
    },
  ];

  let data = [];
  let meta = {};

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((deliveryCompany) => ({
      id: deliveryCompany.id,
      name: deliveryCompany.name,
      status: STATUS[deliveryCompany.status],
      address: deliveryCompany.address,
      validated_by: deliveryCompany.validated_by,
      status_key: deliveryCompany.status,
    }));

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (deliveryCompany) => {
    return (
      <div className="fw-bold d-flex">
        {deliveryCompany.status_key === "pending" && (
          <ValidateDeliveryCompanyModal deliveryCompany={deliveryCompany} />
        )}
        <Link to={`/delivery-company/${deliveryCompany.id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
            type="button"
          >
            <i className="bi bi-eye fs-3"></i>
          </button>
        </Link>

        {/* <DeleteDeliveryCompanyModal deliveryCompany={deliveryCompany} /> */}
      </div>
    );
  };

  return (
    <div className="container">
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        baseUrl={baseUrl}
        setUrl={setUrl}
        meta={meta}
      />
    </div>
  );
}

export default DeliveryCompanyList;
