import React, { useEffect, useState } from "react";
import Title from "../../components/Shared/Title";
import { v4 as uuidv4 } from "uuid";
import Geolocation from "../../components/Shared/Geolocation";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import {
  useAddMetaDataMutation,
  useGetCreateMetaDataDataQuery,
  useGetMetaDataQuery,
} from "./metaDataApiSlice";
import Select from "react-select";

function CreateMetaData() {
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  // const [description, setDescription] = useState(null);
  // const [ranges, setRanges] = useState([{ key: nanoid() }]);
  // const [type, setType] = useState(null);
  // const [fixedAmount, setFixedAmount] = useState(null);
  // const [firstKmAmount, setFirstKmAmount] = useState(null);
  // const [amountByKm, setAmountByKm] = useState(null);

  // const [availability, setAvailability] = useState([]);

  const dispatch = useDispatch();
  const [addDeliveryFee] = useAddMetaDataMutation();
  const navigate = useNavigate();

  const createRequest = useGetCreateMetaDataDataQuery();
  const { refetch } = useGetMetaDataQuery();

  useEffect(() => {
    if (createRequest.isSuccess) {
    }
  }, [createRequest.isLoading, createRequest.isSuccess]);

  // fixed_amount, amount_by_km,
  const handleSubmit = (e) => {
    e.preventDefault();

    let data = { name, value };

    addDeliveryFee(data)
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Tarification de livraison créée avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        navigate("/delivery-fee");
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la création de la tarification`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  return (
    <div>
      <Title backPath="/delivery-fee" title="Nouvelle tarification" />
      {/* <!-- Body --> */}
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="row">
          <div className="col-md-3 mt-3 d-flex align-items-end">
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              placeholder="Nom"
              aria-label="Nom"
            />
          </div>

          <div className="col-md-6 mt-3 d-flex align-items-end">
            <input
              type="text"
              id="value"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="form-control"
              placeholder="Value"
              aria-label="Value"
            />
          </div>

          <div className="mt-5 d-flex bd-highlight justify-content-start">
            <div className="mx-2">
              <button className="btn btn-primary" type="submit">
                Créer
              </button>
            </div>

            <div className="mx-2">
              <Link to="/delivery-fee">
                <button className="btn btn-white" type="button">
                  Annuler
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateMetaData;
