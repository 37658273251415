import React from "react";
import Swal from "sweetalert2";

function DeleteDeliveryPersonModal() {
  async function displayConfirmation() {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: "Voulez-vous vraiment supprimer ce livreur ?",
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isDeleteConfirmed = swal.isConfirmed;
  }
  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle"
        type="button"
        onClick={displayConfirmation}
        title="Supprimer"
      >
        <i className="bi bi-trash"></i>
      </button>
    </div>
  );
}

export default DeleteDeliveryPersonModal;
