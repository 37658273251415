import React, { useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import { useGetOptionsQuery } from "./optionsApiSlice";
import DeleteOptionModal from "./DeleteOptionModal";

function OptionList({ setTitle }) {
  const [baseUrl, setBaseUrl] = useState("options");
  const [url, setUrl] = useState(baseUrl);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetOptionsQuery({ url });

  const columns = [
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
  ];

  let data = [];
  let meta = {};

  let content;
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((supplement) => ({
      id: supplement.id,
      name: supplement.name,
      description: supplement.description,
    }));
    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
    content = "";
  } else if (isError) {
    content = <p> {JSON.stringify(error)} </p>;
  }

  const actions = (option) => {
    return (
      <div className="fw-bold d-flex">
        <DeleteOptionModal option={option} />
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        baseUrl={baseUrl}
        setUrl={setUrl}
        meta={meta}
      />
    </div>
  );
}

export default OptionList;
