import Title from "../components/Shared/Title";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import MealList from "../features/meals/MealList";
import React from "react";
import useRedirectUserIfNoRestaurantSelected from "../hooks/restaurants/useRedirectUserIfNoRestaurantSelected";
import { useSelector } from "react-redux";
import { selectSelectedRestaurant } from "../features/restaurants/restaurantSlice";
import RestaurantMealList from "../features/restaurants/meals/RestaurantMealList";
import { selectRoles } from "../features/auth/authSlice";
import { useGetMealsStatsQuery } from "../features/meals/mealsApiSlice";

function Meal() {
  const [restaurantIsSelected, setRestaurantIsSelected] = useState(false);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState(null);
  const [statistics, setStatistics] = useState([
    { quantity: 0, description: "Total des Repas" },
    { quantity: 0, description: "Repas Non Validés" },
    { quantity: 0, description: "Repas Validés" },
    { quantity: 0, description: "Repas en Promo" },
  ]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const statsRequest = useGetMealsStatsQuery();

  useEffect(() => {
    if (statsRequest.status === "fulfilled") {
      const { approved, pending, promoted, total } = statsRequest.data;
      setStatistics([
        { quantity: approved, description: "Repas en Approuvés" },
        { quantity: pending, description: "Repas En Attente" },
        { quantity: promoted, description: "Repas En Promotion" },
        { quantity: total, description: "Total des Repas" },
      ]);
    }
  }, [statsRequest.status]);

  useRedirectUserIfNoRestaurantSelected();
  const selectedRestaurant = useSelector(selectSelectedRestaurant);
  const currentRoles = useSelector(selectRoles);
  const currentRolesNames = currentRoles?.map((role) => role.name);

  useEffect(() => {
    if (selectedRestaurant?.id) {
      setRestaurantIsSelected(true);
      setSelectedRestaurantId(selectedRestaurant?.id);
    }
  }, []);

  return (
    <>
      <Title title="Repas" />

      <div className="row">
        {statistics.map((stat) => (
          <div key={uuidv4()} className="col-lg-3 mb-3 mb-lg-5">
            {/* <!-- Card --> */}
            <div className="d-grid gap-2 gap-lg-4">
              <span className="card card-hover-shadow">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <span
                        className="text-inherit avatar avatar-lg avatar-4x3 d-flex"
                        style={{ minHeight: "5rem", fontSize: "5vh" }}
                        data-hs-theme-appearance="default"
                      >
                        {stat.quantity}
                      </span>
                    </div>

                    <div className="flex-grow-1 ms-4">
                      <h3 className="text-inherit mb-1 text-center"></h3>
                      <span className="text-body">{stat.description}</span>
                    </div>

                    <div className="ms-2 text-end">
                      <i className="d-none bi-chevron-right text-body text-inherit"></i>
                    </div>
                  </div>
                </div>
              </span>
            </div>
            {/* <!-- End Card --> */}
          </div>
        ))}
      </div>

      <div className="container-fluid mt-5 mb-5">
        <p>Liste des repas</p>
      </div>

      <div className="container-fluid d-flex justify-content-end bd-highlight d-none">
        <Link to="/meal/create">
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
            type="button"
          >
            <i className="bi bi-plus fs-3"></i>
          </button>
        </Link>
      </div>

      {restaurantIsSelected && (
        <RestaurantMealList restaurantId={selectedRestaurantId} />
      )}

      {["admin"].some((role) => currentRolesNames?.includes(role)) && (
        <MealList />
      )}
    </>
  );
}

export default Meal;
