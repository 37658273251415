import React, { useEffect, useState } from "react";
import Title from "../../components/Shared/Title";
import { Link, useNavigate, useParams } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import {
  useAddMealMutation,
  useGetCreateMealDataQuery,
  useGetMealQuery,
  useGetMealsQuery,
  useUpdateMealMutation,
} from "./mealsApiSlice";
import Select from "react-select";

function UpdateMeal() {
  const [name, setName] = useState("");
  const [images, setImages] = useState([{ key: nanoid(), value: null }]);
  const [description, setDescription] = useState(null);
  const [price, setPrice] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [restaurantOptions, setRestaurantOptions] = useState(null);
  const [mealCategory, setMealCategory] = useState(null);
  const [mealCategoryOptions, setMealCategoryOptions] = useState(null);
  const [supplements, setSupplements] = useState([
    { key: nanoid(), supplement_id: null, price: 0 },
  ]);
  const [mealOptions, setMealOptions] = useState(null);
  const [mealOptionOptions, setMealOptionOptions] = useState(null);
  const [mealSupplementOptions, setMealSupplementOptions] = useState(null);
  const [mealSupplementObjects, setMealSupplementObjects] = useState(null);
  const [mealOptionObjects, setMealOptionObjects] = useState(null);

  const [updateMeal] = useUpdateMealMutation();
  const navigate = useNavigate();
  const { mealId } = useParams();

  const createRequest = useGetCreateMealDataQuery();

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetMealQuery({ mealId });

  useEffect(() => {
    // refetch();
    if (isSuccess) {
      setName(response.name);
      setDescription(response.description);
      setPrice(response.price);
      setRestaurant({
        label: response.restaurant.name,
        value: response.restaurant.id,
      });
      setMealCategory({
        label: response.category.name,
        value: response.category.id,
      });
      const formattedSupplements = response.supplements.map((supplement) => ({
        key: nanoid(),
        supplement_id: supplement.id,
        price: supplement.pivot.price,
        name: supplement.name,
      }));
      setSupplements(formattedSupplements);
      const formattedOptions = response.options.map((option) => ({
        label: option.name,
        value: option.id,
        key: nanoid(),
      }));
      setMealOptions(formattedOptions);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (createRequest.isSuccess) {
      const { restaurants, categories, supplements, options } =
        createRequest.data;
      const formattedRestaurants = restaurants.map((restaurant) => ({
        label: restaurant.name,
        value: restaurant.id,
      }));
      setRestaurantOptions(formattedRestaurants);
      const formattedCategories = categories.map((category) => ({
        label: category.name,
        value: category.id,
      }));
      setMealCategoryOptions(formattedCategories);
      setMealSupplementObjects(supplements);
      const formattedSupplements = supplements.map((supplement) => ({
        label: supplement.name,
        value: supplement.id,
      }));
      setMealSupplementOptions(formattedSupplements);

      setMealOptionObjects(options);
      const formattedOptions = options.map((option) => ({
        label: option.name,
        value: option.id,
      }));
      setMealOptionOptions(formattedOptions);
    }
  }, [createRequest.isLoading, createRequest.isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      name,
      description,
      price,
      restaurant_id: restaurant.value,
      meal_category_id: mealCategory.value,
      id: mealId,
      _method: "put",
    };

    let files = images.map((image) => image.value);
    data.images = files;
    console.log(files);
    if (files[0] == null) {
      files = null;
      delete data.images;
    }

    const options = mealOptions?.map((option) => option.value);
    data.options = options;
    data.supplements = supplements;
    data.is_suggested = 1;
    console.log("UPDATE DATA ", data);

    updateMeal(data)
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Repas modifié avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        navigate("/meal");
      })
      .catch((response) => {
        console.log("MESSAGE ERREUR", response);
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la modification du repas`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  const handleUpload = (file, key) => {
    let imagesCopy = [...images];
    const index = imagesCopy.findIndex((image) => image.key === key);
    imagesCopy[index].value = file;
    setImages(imagesCopy);
  };

  const addImage = () => {
    const imagesCopy = [...images];
    imagesCopy.push({ key: nanoid(), value: "" });
    setImages(imagesCopy);
  };

  const onImageDelete = ({ key }) => {
    if (images.length <= 1) {
      setImages([{ key: nanoid(), value: "" }]);
      return;
    }
    let imagesCopy = [...images];
    const index = imagesCopy.findIndex((image) => image.key === key);
    imagesCopy.splice(index, 1);
    setImages(imagesCopy);
  };

  const addSupplement = () => {
    const supplementsCopy = [...supplements];
    supplementsCopy.push({ key: nanoid(), supplement_id: null, price: 0 });
    setSupplements(supplementsCopy);
  };

  const onSupplementDelete = ({ key }) => {
    if (supplements.length <= 1) {
      setSupplements([{ key: nanoid(), supplement_id: null, price: 0 }]);
      return;
    }
    let supplementsCopy = [...supplements];
    const index = supplementsCopy.findIndex(
      (supplement) => supplement.key === key
    );
    supplementsCopy.splice(index, 1);
    setSupplements(supplementsCopy);
  };

  const handleSupplementChange = (element, key) => {
    let supplementsCopy = [...supplements];
    const index = supplementsCopy.findIndex(
      (supplement) => supplement.key === key
    );
    supplementsCopy[index].supplement_id = element?.value;
    setSupplements(supplementsCopy);
  };

  const handleSupplementPriceChange = (price, key) => {
    let supplementsCopy = [...supplements];
    const index = supplementsCopy.findIndex(
      (supplement) => supplement.key === key
    );
    console.log("SUPPLEMENT", supplementsCopy[index]);
    supplementsCopy[index].price = price;
    setSupplements(supplementsCopy);
  };

  return (
    <div>
      <Title backPath="/meal" title="Mettre à jour les informations du repas" />
      {/* <!-- Body --> */}
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="row">
          <div className="col-md-3 mt-3 d-flex align-items-end">
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              placeholder="Nom"
              aria-label="Nom"
            />
          </div>

          <div className="col-md-6 mt-3">
            <input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
              placeholder="Description"
              aria-label="Description"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Prix"
              aria-label="Prix"
            />
          </div>

          <div className="col-md-4 mt-3 d-flex align-items-end">
            <div>
              <p>Restaurant: {restaurant?.label} </p>
              <Select
                name="restaurants"
                options={restaurantOptions}
                isClearable
                isSearchable
                onChange={setRestaurant}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>

          <div className="col-md-4 mt-3 d-flex align-items-end">
            <div>
              <p>Catégorie de repas : {mealCategory?.label} </p>
              <div className="">
                <Select
                  name="mealCategory"
                  options={mealCategoryOptions}
                  isClearable
                  isSearchable
                  onChange={setMealCategory}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 mt-3">
            <p>
              Options du repas:{" "}
              {mealOptions?.map((option) => `${option.label} | `)}
            </p>
            <Select
              name="options"
              isMulti
              options={mealOptionOptions}
              isClearable
              isSearchable
              onChange={setMealOptions}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="row">
            {supplements.map((supplement, index) => (
              <div className="col-md-4 mt-3">
                <div className="">
                  <p>{supplement.name ?? `Supplément ${index + 1}`} </p>
                  <div className="row">
                    <div className="row">
                      <div className="col-md-8">
                        <Select
                          name="supplements"
                          options={mealSupplementOptions}
                          isClearable
                          isSearchable
                          onChange={(element) =>
                            handleSupplementChange(element, supplement.key)
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control"
                          id="price"
                          value={supplement.price}
                          onChange={(e) =>
                            handleSupplementPriceChange(
                              e.target.value,
                              supplement.key
                            )
                          }
                          placeholder="Prix"
                          aria-label="Prix"
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <button
                          onClick={() =>
                            onSupplementDelete({ key: supplement.key })
                          }
                          className="btn btn-ghost-secondary btn-icon rounded-circle"
                          type="button"
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-md-4 mt-3">
              <button
                className="btn btn-ghost-secondary btn-icon rounded-circle"
                type="button"
                onClick={addSupplement}
              >
                <i className="bi bi-plus"></i>
              </button>
              <span className="fw-bold">Ajouter Supplément(s)</span>
            </div>
          </div>
          <div className="row">
            {images.map((image, index) => (
              <div className="col-md-4 mt-3 d-flex  align-items-end">
                <div className="">
                  <label htmlFor="mealCategoryImage" className="form-label">
                    {image?.value?.name ?? "Image"}
                  </label>

                  <input
                    type="file"
                    id="image"
                    value=""
                    onChange={(e) => {
                      handleUpload(e.target.files[0], image.key);
                    }}
                    className="form-control"
                  />
                </div>

                <div>
                  <button
                    onClick={() => onImageDelete({ key: image.key })}
                    className="btn btn-ghost-secondary btn-icon rounded-circle"
                    type="button"
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            ))}
            <div className="col-md-3 mt-3">
              <button
                className="btn btn-ghost-secondary btn-icon rounded-circle"
                type="button"
                onClick={addImage}
              >
                <i className="bi bi-plus"></i>
              </button>
            </div>
          </div>

          <div className="mt-5 d-flex bd-highlight justify-content-start">
            <div className="mx-2">
              <button className="btn btn-primary" type="submit">
                Modifier
              </button>
            </div>

            <div className="mx-2">
              <Link to="/meal">
                <button className="btn btn-white" type="button">
                  Annuler
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateMeal;
