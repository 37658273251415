import React from "react";

function Main({ content }) {
  return (
    <main id="content" role="main" className="main">
      {/* Content */}
      <div className="content container-fluid">{content}</div>
      {/* End Content */}
      {/* Footer */}
      <div className="footer">
        <div className="row justify-content-between align-items-center">
          <div className="col">
            <p className="fs-6 mb-0">
              &copy; Nautilus Technology
              <span className="d-none d-sm-inline-block"></span>
            </p>
          </div>
          {/* End Col */}

          {/* End Col */}
        </div>
        {/* End Row */}
      </div>
      {/* End Footer */}
    </main>
  );
}

export default Main;
