import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

const drinksApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Drinks"],
  endpoints: (builder) => ({
    getDrinksStats: builder.query({
      query: () => "stats/drinks",
      providesTags: ["Drinks"],
    }),
    getDrinks: builder.query({
      query: ({ url }) => url,
      providesTags: ["Drinks"],
    }),
    getCreateDrinkData: builder.query({
      query: () => "drinks/create",
    }),
    addDrink: builder.mutation({
      query: (drink) => ({
        url: "drinks",
        method: "POST",
        body: { ...drink },
      }),
      invalidatesTags: ["Drinks"],
    }),
    updateDrink: builder.mutation({
      query: (drink) => ({
        url: `drinks/${drink.id}`,
        method: "POST",
        body: { ...drink },
      }),
      invalidatesTags: ["Drinks"],
    }),
    deleteDrink: builder.mutation({
      query: (drinkId) => ({
        url: `drinks/${drinkId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Drinks"],
    }),
  }),
});

export const {
  useGetDrinksStatsQuery,
  useGetDrinksQuery,
  useGetCreateDrinkDataQuery,
  useAddDrinkMutation,
  useUpdateDrinkMutation,
  useDeleteDrinkMutation,
} = drinksApiSlice;
