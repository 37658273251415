import React from "react";
import { Link, NavLink } from "react-router-dom";
import RequireAuth from "../auth/RequireAuth";
import { useSelector } from "react-redux";
import { selectRoles } from "../auth/authSlice";
import { useGetRecentCorrespondentsQuery } from "./chatApiSlice";

function ChatNavbar() {
  const currentRoles = useSelector(selectRoles);
  const currentRolesNames = currentRoles?.map((role) => role.name);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetRecentCorrespondentsQuery();
  console.log(response);

  return (
    <aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white">
      <div className="navbar-vertical-container">
        <div className="navbar-vertical-footer-offset">
          {/*Logo*/}
          <Link className="navbar-brand" to="/" aria-label="Front">
            <img
              className="navbar-brand-logo img-fluid"
              src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.png"}
              alt="Logo"
              data-hs-theme-appearance="default"
              style={{ height: "50px" }}
            />
            <img
              className="navbar-brand-logo"
              src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.png"}
              alt="Logo"
              data-hs-theme-appearance="dark"
            />
            <img
              className="navbar-brand-logo-mini"
              src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.png"}
              alt="Logo"
              data-hs-theme-appearance="default"
            />
            <img
              className="navbar-brand-logo-mini"
              src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.png"}
              alt="Logo"
              data-hs-theme-appearance="dark"
            />
          </Link>

          {/* End Logo */}

          {/* Navbar Vertical Toggle  */}
          <button
            type="button"
            className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
          >
            <i
              className="bi-arrow-bar-left navbar-toggler-short-align"
              data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Réduire"
            ></i>
            <i
              className="bi-arrow-bar-right navbar-toggler-full-align"
              data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Ouvrir"
            ></i>
          </button>

          {/* End Navbar Vertical Toggle */}

          {/* Content */}
          <div className="navbar-vertical-content">
            <div
              id="navbarVerticalMenu"
              className="nav nav-pills nav-vertical card-navbar-nav"
            >
              {response?.recentCorrespondents?.map((correspondant) => (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to={`/chat/messenger/${correspondant.user.id}`}
                  >
                    <i className="bi bi-chat-dots nav-icon"></i>
                    <span className="nav-link-title">
                      {correspondant.user.identity.fullName}
                    </span>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
          {/* End Content */}

          {/* Footer */}
          <div className="navbar-vertical-footer"></div>
          {/* End Footer */}
        </div>
      </div>
    </aside>
  );
}

export default ChatNavbar;
