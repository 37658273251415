import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import { useUpdatePromotionMutation } from "./promotionsApiSlice";

function UpdatePromotionStatusModal({ promotionId }) {
  const [updatePromotion] = useUpdatePromotionMutation();

  const handleStatusUpdate = (status) => {
    const data = { id: promotionId };
    if (status === "suspend") {
      data.suspend = true;
    } else {
      data.status = status;
    }
    updatePromotion(data)
      .unwrap()
      .then((response) => {
        if (response?.error?.originalStatus === 500) {
          Swal.fire({
            title: "Erreur",
            text: `Impossible de mettre à jour le status de la promotion`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Succees",
            text: `Status de la promotion mis à jour.`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Impossible de mettre à jour le status de la promotion`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#createMealCategoryModal"
        title="Metrre a jour le statut"
      >
        <i className="bi bi-arrow-down-up  fs-3"></i>
      </button>

      <div
        className="modal fade"
        id="createMealCategoryModal"
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Mettre à jour le statut de la promotion
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <div className="modal-body">
              <div className="container-fluid">
                <form
                  className="row"
                  encType="multipart/form-data"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="mt-5 d-flex bd-highlight justify-content-start">
                    <div className="mx-2">
                      <button
                        onClick={() => handleStatusUpdate("valid")}
                        className="btn btn-white"
                        type="submit"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Valider
                      </button>
                    </div>

                    <div className="mx-2">
                      <button
                        onClick={() => handleStatusUpdate("invalid")}
                        className="btn btn-white"
                        type="submit"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Invalider
                      </button>
                    </div>

                    <div className="mx-2">
                      <button
                        onClick={() => handleStatusUpdate("suspend")}
                        className="btn btn-white"
                        type="submit"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Suspendre
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePromotionStatusModal;
