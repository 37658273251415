import React from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  useDeleteDeliveryFeeMutation,
  useGetDeliveryFeeQuery,
} from "./deliveryFeeApiSlice";

function DeleteDeliveryFeeModal({ deliveryFee }) {
  const [deleteDeliveryFee] = useDeleteDeliveryFeeMutation();
  const { refetch } = useGetDeliveryFeeQuery();

  const navigate = useNavigate();

  async function displayConfirmation() {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Voulez-vous vraiment supprimer ${deliveryFee.name} ?`,
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isDeleteConfirmed = swal.isConfirmed;

    if (isDeleteConfirmed) {
      deleteDeliveryFee(deliveryFee.id)
        .unwrap()
        .then(() => {
          refetch();
          Swal.fire({
            title: "Succees",
            text: `Tarification supprimée avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((response) => {
          Swal.fire({
            title: "Erreur",
            text: `Erreur lors de la suppression du tarification`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    }
  }
  return (
    <div>
      <button
        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
        type="button"
        onClick={displayConfirmation}
      >
        <i className="bi bi-trash"></i>
      </button>
    </div>
  );
}

export default DeleteDeliveryFeeModal;
