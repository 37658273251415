import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

const tagsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Tags"],
  endpoints: (builder) => ({
    getTags: builder.query({
      query: () => "tags",
      providesTags: ["Tags"],
    }),
    addTag: builder.mutation({
      query: (tag) => ({
        url: "tags",
        method: "POST",
        body: dataForm(tag),
      }),
      invalidatesTags: ["Tags"],
    }),
    updateTag: builder.mutation({
      query: (tag) => ({
        url: `tags/${tag.id}`,
        method: "POST",
        body: { ...tag },
      }),
      invalidatesTags: ["Tags"],
    }),
    deleteTag: builder.mutation({
      query: (tagId) => ({
        url: `tags/${tagId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tags"],
    }),
  }),
});

export const {
  useGetTagsQuery,
  useAddTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
} = tagsApiSlice;
