import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

const rechargesApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Recharges"],
  endpoints: (builder) => ({
    getRecharges: builder.query({
      query: ({url}) => url,
      providesTags: ["Recharges"],
    }),
    getRecharge: builder.query({
      query: ({ rechargeId }) => {
        return `recharges/${rechargeId}`;
      },
      providesTags: ["Recharge"],
    }),
    getCreateRechargeData: builder.query({
      query: () => "recharges/create",
    }),
    addRecharge: builder.mutation({
      query: (recharge) => ({
        url: "recharges",
        method: "POST",
        body: recharge,
      }),
      invalidatesTags: ["Recharges"],
    }),
    updateRecharge: builder.mutation({
      query: (recharge) => ({
        url: `recharges/${recharge.id}`,
        method: "PUT",
        body: recharge,
      }),
      invalidatesTags: ["Recharges", "Recharge"],
    }),
    deleteRecharge: builder.mutation({
      query: (rechargeId) => ({
        url: `recharges/${rechargeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Recharges"],
    }),
  }),
});

export const {
  useGetRechargesQuery,
  useGetRechargeQuery,
  useGetCreateRechargeDataQuery,
  useAddRechargeMutation,
  useUpdateRechargeMutation,
  useDeleteRechargeMutation,
} = rechargesApiSlice;
