import React from "react";
import Swal from "sweetalert2";
import { useDeleteAdvertisementMutation } from "./advertisementsApiSlice";

function DeleteAdvertisementModal({ advertisement }) {
  const [deleteAdvertisement] = useDeleteAdvertisementMutation();

  async function displayConfirmation() {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Voulez-vous vraiment supprimer la publicité  ${advertisement.title} ?`,
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isDeleteConfirmed = swal.isConfirmed;

    if (isDeleteConfirmed) {
      deleteAdvertisement(advertisement.id)
        .unwrap()
        .then((response) => {
          if (response?.error?.originalStatus === 500) {
            Swal.fire({
              title: "Suppression impossible",
              text: `La suppresison de la publicité n'est pas possible actuellement`,
              icon: "error",
              toast: true,
              position: "top-end",
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              title: "Succees",
              text: `Publicité supprimée avec succès`,
              icon: "success",
              toast: true,
              position: "top-end",
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((error) => {
          if (error.originalStatus === 409) {
            Swal.fire({
              title: "Suppression impossible",
              text: `La publicité ${advertisement.title} est liée à d'autres ressources`,
              icon: "error",
              toast: true,
              position: "top-end",
              timer: 10000,
              timerProgressBar: true,
            });
          }
        });
    }
  }

  return (
    <div>
      <button
        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
        type="button"
        onClick={displayConfirmation}
      >
        <i className="bi bi-trash"></i>
      </button>
    </div>
  );
}

export default DeleteAdvertisementModal;
