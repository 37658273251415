import React, { useEffect, useState } from "react";
import Title from "../../components/Shared/Title";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch } from "react-redux";
import {
  useAddStaffMutation,
  useGetCreateStaffDataQuery,
} from "./staffApiSlice";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const animatedComponent = makeAnimated();

function CreateStaff() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState(null);
  const [options, setOptions] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createRequest = useGetCreateStaffDataQuery();
  const [addStaff] = useAddStaffMutation();

  useEffect(() => {
    if (createRequest.isSuccess) {
      const { roles } = createRequest.data;
      let formattedOptions = roles.map((role) => ({
        ...role,
        value: role.name,
      }));
      setOptions(formattedOptions);
    }
  }, [createRequest.isLoading, createRequest.isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let formatedRoles = roles.map((role) => role.name);
    addStaff({ firstname, lastname, telephone, email, roles: formatedRoles })
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Staff créé avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        navigate("/staff");
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la création du staff`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  return (
    <div>
      <Title title="Enregistrer un nouveau staff" backPath="/staff" />
      {/* <!-- Body --> */}
      <form onSubmit={handleSubmit} className="container-fluid">
        <div className="row">
          <div className="col-md-3 mt-3">
            <input
              type="text"
              id="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              className="form-control"
              placeholder="Nom du staff"
              aria-label="Nom du staff"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              id="firstname"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              className="form-control"
              placeholder="Prénom(s) du staff"
              aria-label="Prénom(s) du staff"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              id="telephone"
              value={telephone}
              onChange={(e) => {
                setTelephone(e.target.value);
              }}
              className="form-control"
              placeholder="Numero de telephone"
              aria-label="Numero de telephone"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="Email"
              aria-label="Email"
            />
          </div>

          <div className="col-md-12 mt-3">
            <Select
              isMulti
              isClearable
              isSearchable
              name="roles"
              onChange={setRoles}
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mt-3">
            <label htmlFor="formFile" className="form-label">
              Photo
            </label>
            <input className="form-control" type="file" id="formFile" />
          </div>

          <div className="col-md-6 mt-3 d-flex align-items-end">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Adresse du staff"
              aria-label="Adresse du staff"
            />
          </div>

          <div className="mt-5 d-flex bd-highlight justify-content-start">
            <div className="mx-2">
              <button className="btn btn-primary">Enregistrer</button>
            </div>

            <div className="mx-2">
              <Link to="/staff">
                <button className="btn btn-white">Annuler</button>
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateStaff;
