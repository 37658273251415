import React from "react";

import AsyncSelect from "react-select/async";

const colourOptions = [{}];
const filter = (inputValue) => {
  return colourOptions.filter((i) =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const loadOptions = (inputValue, callback) => {
  setTimeout(() => {
    callback(filter(inputValue));
  }, 1000);
};

export const Asyncearch = () => (
  <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions />
);

const SearchableSelect = () => {
  return (
    <select
      id="select-repo"
      placeholder="Pick a repository..."
      multiple
    ></select>
  );
};

export default SearchableSelect;
