import { apiSlice } from "../../app/api/apiSlice";

const dataForm = (data) => {
  const formdata = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (key !== "days") {
      if (key === "telephones") {
        // phone numbers
        for (const [k, v] of Object.entries(value)) {
          formdata.append(`${key}[]`, v);
        }
      } else if (value) {
        formdata.append(key, value);
      }
    }
  }

  data["days"].forEach((element, index) => {
    formdata.append(`days[${index}][id]`, element.id);
    formdata.append(`days[${index}][is_available]`, 1);
    formdata.append(`days[${index}][open_at]`, element.open_at);
    formdata.append(`days[${index}][close_at]`, element.close_at);
  });

  return formdata;
};

const restaurantsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Restaurants", "RestaurantOrders", "Promotions"],
  endpoints: (builder) => ({
    getRestaurantsStats: builder.query({
      query: () => ({
        url: `stats/restaurants`,
        method: "GET",
      }),
      providesTags: ["Restaurants"],
    }),
    getRestaurantMeals: builder.query({
      query: ({ url }) => ({
        url: url,
        method: "GET",
      }),
      providesTags: ["Restaurants"],
    }),
    getRestaurants: builder.query({
      query: ({ url }) => url,
      providesTags: ["Restaurants"],
    }),
    getRestaurant: builder.query({
      query: ({ restaurantId }) => {
        return `restaurants/${restaurantId}`;
      },
      providesTags: ["Restaurant"],
    }),
    getCreateRestaurantData: builder.query({
      query: () => "restaurants/create",
    }),
    addRestaurant: builder.mutation({
      query: (restaurant) => ({
        url: "restaurants",
        method: "POST",
        body: dataForm(restaurant),
      }),
      invalidatesTags: ["Restaurants"],
    }),
    updateRestaurant: builder.mutation({
      query: (restaurant) => ({
        url: `restaurants/${restaurant.id}`,
        method: "POST",
        body: dataForm(restaurant),
      }),
      invalidatesTags: ["Restaurants", "Restaurant"],
    }),
    deleteRestaurant: builder.mutation({
      query: (restaurantId) => ({
        url: `restaurants/${restaurantId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Restaurants"],
    }),
    validateRestaurant: builder.mutation({
      query: (data) => ({
        url: `validate/restaurant`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Restaurants", "Restaurant"],
    }),

    // Restaurant orders
    getRestaurantOrders: builder.query({
      query: ({ url }) => url,
      providesTags: ["RestaurantOrders"],
    }),
    acceptRestaurantOrder: builder.mutation({
      query: (data) => ({
        url: `restaurant/accept/order`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["RestaurantOrders"],
    }),
    processRestaurantOrder: builder.mutation({
      query: (data) => ({
        url: `restaurant/processing/order`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["RestaurantOrders"],
    }),
    readyRestaurantOrder: builder.mutation({
      query: (data) => ({
        url: `restaurant/ready/order`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["RestaurantOrders"],
    }),

    // Restaurant promotion
    getCreateRestaurantPromotionData: builder.query({
      query: () => "promo-codes/create",
    }),
    addRestaurantPromotion: builder.mutation({
      query: (promotion) => ({
        url: "promo-codes",
        method: "POST",
        body: promotion,
      }),
      invalidatesTags: ["Promotions"],
    }),

    // Stats
    getRestaurantStats: builder.query({
      query: ({ restaurantId }) =>
        `stats/dashboard/restaurants/${restaurantId}`,
    }),

    getAdminDashboardStats: builder.query({
      query: () => `stats/dashboard/admin`,
    }),

    // Refund
    refundOrder: builder.mutation({
      query: (data) => ({
        url: "refund",
        method: "POST",
        body: { ...data },
      }),
    }),
  }),
});

export const {
  // Restaurants
  useGetRestaurantsStatsQuery,
  useGetRestaurantMealsQuery,
  useGetRestaurantsQuery,
  useGetRestaurantQuery,
  useGetCreateRestaurantDataQuery,
  useAddRestaurantMutation,
  useUpdateRestaurantMutation,
  useDeleteRestaurantMutation,
  useValidateRestaurantMutation,

  // Orders
  useGetRestaurantOrdersQuery,
  useAcceptRestaurantOrderMutation,
  useProcessRestaurantOrderMutation,
  useReadyRestaurantOrderMutation,

  // Restaurant promotion
  useGetCreateRestaurantPromotionDataQuery,
  useAddRestaurantPromotionMutation,

  // Stats
  useGetRestaurantStatsQuery,
  useGetAdminDashboardStatsQuery,

  // Refund
  useRefundOrderMutation,
} = restaurantsApiSlice;
