import React, { useEffect, useState } from "react";
import DataTable from "../../dataTable/DataTableBase";
import { Link, useNavigate } from "react-router-dom";
import { useGetRestaurantOrdersQuery } from "../restaurantsApiSlice";
import UpdateOrderStatusModal from "./UpdateOrderStatusModal";
import { useSelector } from "react-redux";
import { selectRoles } from "../../auth/authSlice";
import { selectSelectedRestaurant } from "../restaurantSlice";
import useRedirectUserIfNoRestaurantSelected from "../../../hooks/restaurants/useRedirectUserIfNoRestaurantSelected";
import STATUS from "../../../components/Shared/Status";

function RestaurantOrderList({ restaurantId }) {
  const [activeItem, setActiveItem] = useState("all");
  const [baseUrl, setBaseUrl] = useState(`restaurant/orders/${restaurantId}`);
  const [url, setUrl] = useState(baseUrl);
  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetRestaurantOrdersQuery({ url });

  useEffect(() => {
    if (activeItem !== "all") {
      setUrl(baseUrl + `?status=${activeItem}`);
    }
  }, [activeItem, baseUrl]);

  const columns = [
    {
      name: "Référence",
      selector: "reference",
      sortable: true,
    },
    {
      name: "Client",
      selector: "client",
      sortable: true,
    },
    {
      name: "Montant total",
      selector: "total_amount",
      type: "price",
    },
    {
      name: "Statut",
      selector: "status",
      type: "status",
    },
    {
      name: "Adresse",
      selector: "address",
    },
  ];

  let data = [];
  let meta = {};

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.map((order) => ({
      id: order.id,
      client: order.client.identity.fullName,
      status: order.status,
      address: order.address,
      reference: order.reference,
      accepted_at: order.treatment?.accepted_at,
      set_as_ready_at: order.treatment?.set_as_ready_at,
      set_as_processing_at: order.treatment?.set_as_processing_at,
      set_as_ready_by_id: order.treatment?.set_as_ready_by_id,
      date: order.created_at,
      total_amount: order.transaction.total_amount,
    }));
    data.sort(function (a, b) {
      var keyA = new Date(a.date),
        keyB = new Date(b.date);
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (order) => {
    return (
      <div className="fw-bold d-flex">
        <Link to={`/order/${order.id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
            type="button"
          >
            <i className="bi bi-eye fs-3"></i>
          </button>
        </Link>

        <UpdateOrderStatusModal order={order} />
      </div>
    );
  };

  return (
    <>
      {/* <!-- Page Header --> */}
      <div class="page-header">
        <div class="row align-items-center mb-3">
          <div class="col-sm">
            <h1 class="page-header-title">
              Commandes
              <span class="badge bg-soft-dark text-dark ms-2 d-none">
                106,905
              </span>
            </h1>

            <div class="d-flex mt-2 d-none">
              <a
                class="text-body me-3"
                href="javascript:;"
                data-bs-toggle="modal"
                data-bs-target="#exportOrdersModal"
              >
                <i class="bi-download me-1"></i> Export
              </a>

              {/* <!-- Dropdown --> */}
              <div class="dropdowm">
                <a
                  class="text-body"
                  href="javascript:;"
                  id="moreOptionsDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  More options <i class="bi-chevron-down"></i>
                </a>

                <div
                  class="dropdown-menu mt-1"
                  aria-labelledby="moreOptionsDropdown"
                >
                  <a class="dropdown-item" href="#">
                    <i class="bi-folder-plus dropdown-item-icon"></i> New order
                  </a>
                  <a class="dropdown-item" href="#">
                    <i class="bi-folder dropdown-item-icon"></i> New order -
                    Development
                  </a>
                  <a class="dropdown-item" href="#">
                    <i class="bi-folder dropdown-item-icon"></i> New order -
                    Staging
                  </a>
                </div>
              </div>
              {/* <!-- End Dropdown --> */}
            </div>
          </div>
          {/* <!-- End Col --> */}
        </div>
        {/* <!-- End Row --> */}

        {/* <!-- Nav Scroller --> */}
        <div class="js-nav-scroller hs-nav-scroller-horizontal">
          <span class="hs-nav-scroller-arrow-prev" style={{ display: "none" }}>
            <a class="hs-nav-scroller-arrow-link" href="javascript:;">
              <i class="bi-chevron-left"></i>
            </a>
          </span>

          <span class="hs-nav-scroller-arrow-next" style={{ display: "none" }}>
            <a class="hs-nav-scroller-arrow-link" href="javascript:;">
              <i class="bi-chevron-right"></i>
            </a>
          </span>

          {/* <!-- Nav --> */}
          <ul class="nav nav-tabs page-header-tabs">
            <li class="nav-item" onClick={() => setActiveItem("all")}>
              <button
                class={`nav-link ${
                  activeItem === "all" ? "active" : "disabled"
                }`}
              >
                Tous
              </button>
            </li>
            <li class="nav-item" onClick={() => setActiveItem("pending")}>
              <button
                class={`nav-link ${
                  activeItem === "pending" ? "active" : "disabled"
                }`}
              >
                En Attente
              </button>
            </li>
            <li class="nav-item" onClick={() => setActiveItem("processing")}>
              <button
                class={`nav-link ${
                  activeItem === "processing" ? "active" : "disabled"
                }`}
              >
                En Préparation
              </button>
            </li>
            <li class="nav-item" onClick={() => setActiveItem("ready")}>
              <button
                class={`nav-link ${
                  activeItem === "ready" ? "active" : "disabled"
                }`}
              >
                Préparé
              </button>
            </li>
          </ul>
          {/* <!-- End Nav --> */}
        </div>
        {/* <!-- End Nav Scroller --> */}
      </div>
      {/* <!-- End Page Header --> */}
      <div className="container">
        <DataTable
          columns={columns}
          data={data}
          actions={actions}
          baseUrl={baseUrl}
          setUrl={setUrl}
          meta={meta}
        />
      </div>
    </>
  );
}

export default RestaurantOrderList;
