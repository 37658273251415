import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  useAddRestaurantStaffMutation,
  useGetCreateRestaurantStaffDataQuery,
  useGetRestaurantStaffQuery,
  useUpdateRestaurantStaffMutation,
} from "./restaurantStaffApiSlice";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";

function CreateRestaurantStaffModal() {
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantsOptions, setRestaurantsOptions] = useState([]);
  const [staff, setStaff] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);

  const [addRestaurantStaff] = useAddRestaurantStaffMutation();
  const { refetch } = useGetRestaurantStaffQuery();

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCreateRestaurantStaffDataQuery();

  useEffect(() => {
    if (isSuccess) {
      console.log("response", response);
      let formattedRestaurants = response.restaurants.map((restaurant) => ({
        ...restaurant,
        label: restaurant.name,
        value: restaurant.id,
      }));
      setRestaurantsOptions(formattedRestaurants);

      let formattedStaff = response.restaurantAdmins.map((staff) => ({
        ...staff,
        label: staff.identity.fullName,
        value: staff.id,
      }));
      setStaffOptions(formattedStaff);
    }
  }, [isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let selectedRestaurants = restaurants.map((restaurant) => restaurant.id);

    addRestaurantStaff({
      staff_id: staff.id,
      restaurants: selectedRestaurants,
    })
      .unwrap()
      .then(() => {
        refetch();
        Swal.fire({
          title: "Succees",
          text: `Staff du restaurant créé avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la création du staff du restaurant`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#createRestaurantStaffModal`}
      >
        <i className="bi bi-plus fs-3"></i>
      </button>

      {/* <!-- Update Plan Modal --> */}
      <div
        class="modal fade"
        id={`createRestaurantStaffModal`}
        tabindex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div class="modal-content">
            {/* <!-- Header --> */}
            <div class="modal-header">
              <h4 class="modal-title" id="accountUpdatePlanModalLabel">
                Affectation de staff aux restaurants
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <form
              onSubmit={handleSubmit}
              class="modal-body"
              encType="multipart/form-data"
            >
              <div>
                {/* <!-- Body --> */}
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <p>Staff : {staff !== null ? staff.label : ""}</p>
                      <Select
                        // defaultValue={restaurants}
                        name="staff"
                        options={staffOptions}
                        isClearable
                        isSearchable
                        onChange={setStaff}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <p>
                        Restaurants :{" "}
                        {restaurants?.map((restaurant) => (
                          <span key={nanoid()}>{restaurant.label} | </span>
                        ))}
                      </p>
                      <Select
                        defaultValue={restaurants}
                        isMulti
                        name="restaurants"
                        options={restaurantsOptions}
                        isClearable
                        isSearchable
                        onChange={setRestaurants}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>

                    <div className="mt-5 d-flex bd-highlight justify-content-start">
                      <div className="mx-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Valider
                        </button>
                      </div>

                      <div className="mx-2">
                        <button
                          type="button"
                          className="btn btn-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Update Plan Modal --> */}
    </div>
  );
}

export default CreateRestaurantStaffModal;
