import React, { useEffect, useState } from "react";
import Title from "../../components/Shared/Title";
import { v4 as uuidv4 } from "uuid";
import Geolocation from "../../components/Shared/Geolocation";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  useAddPromotionMutation,
  useGetCreatePromotionDataQuery,
  useGetPromotionQuery,
  useGetPromotionsQuery,
  useUpdatePromotionMutation,
} from "./promotionsApiSlice";

function CreatePromotion() {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState(null);
  const [typeOptions, setTypeOptions] = useState(null);
  const [value, setValue] = useState(null);
  const [maxBeneficiaries, setMaxBeneficiaries] = useState(null);
  const [valueType, setValueType] = useState(null);
  const [valueTypeOptions, setValueTypeOptions] = useState(null);
  const [calculationBasis, setCalculationBasis] = useState(null);
  const [calculationBasisOptions, setCalculationBasisOptions] = useState(null);
  const [cible, setCible] = useState(null);
  const [cibleOptions, setCibleOptions] = useState(null);

  const [updatePromotion] = useUpdatePromotionMutation();
  const navigate = useNavigate();
  const { promotionId } = useParams();

  const createRequest = useGetCreatePromotionDataQuery();

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetPromotionQuery({ promotionId });

  useEffect(() => {
    if (createRequest.isSuccess) {
      const { cible, type, value_type } = createRequest.data;
      setCalculationBasisOptions(cible);
      setTypeOptions(type);
      setValueTypeOptions(value_type);
    }
  }, [createRequest.isLoading, createRequest.isSuccess]);

  useEffect(() => {
    refetch();
    if (isSuccess) {
      setName(response.name);
      setCode(response.code);
      const selectedType = typeOptions?.find(
        (option) => option.value === response.type
      );

      setType(selectedType);
      const selectedValueTye = valueTypeOptions.find(
        (option) => option.value === response.value_type
      );
      setValueType(selectedValueTye);
      setValue(response.value);
      setMaxBeneficiaries(response.maximum_beneficiaries);
      const selectedCalculationBasis = calculationBasisOptions.find(
        (option) => option.value === response.calculation_basis
      );
      setCalculationBasis(selectedCalculationBasis);
    }
  }, [isLoading, isSuccess, typeOptions]);

  const handleSubmit = (e) => {
    e.preventDefault();

    updatePromotion({
      name,
      code,
      value_type: valueType.value,
      value,
      maximum_beneficiaries: maxBeneficiaries,
      calculation_basis: calculationBasis.value,
      cible,
      id: promotionId,
      _method: "put",
    })
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Code promo mis à jour avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        navigate("/promotion");
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la mise à jour du code promo`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  return (
    <div>
      <Title backPath="/promotion" title="Modifier la promotion" />
      {/* <!-- Body --> */}
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="row">
          <div className="col-md-3 mt-3 d-flex align-items-end">
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              placeholder="Nom"
              aria-label="Nom"
            />
          </div>

          <div className="col-md-3 mt-3 d-flex align-items-end">
            <input
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="form-control"
              placeholder="Code"
              aria-label="Code"
            />
          </div>

          <div className="col-md-3 mt-3">
            <p>Type de valeur : {valueType?.label} </p>
            <Select
              name="valueType"
              options={valueTypeOptions}
              isClearable
              isSearchable
              onChange={setValueType}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div className="col-md-3 mt-3 d-flex align-items-end">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Valeur"
              aria-label="Valeur"
            />
          </div>

          <div className="col-md-3 mt-3 d-flex align-items-end">
            <input
              type="text"
              className="form-control"
              id="maxBeneficiaries"
              value={maxBeneficiaries}
              onChange={(e) => setMaxBeneficiaries(e.target.value)}
              placeholder="Nombre maximum de bénéficiaires"
              aria-label="Nombre maximum de bénéficiaires"
            />
          </div>

          <div className="col-md-3 mt-3 d-flex align-items-end">
            <div>
              <p>Base de calcule: {calculationBasis?.label} </p>
              <Select
                name="calculusBasis"
                options={calculationBasisOptions}
                isClearable
                isSearchable
                onChange={setCalculationBasis}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>

          <div className="col-md-3 mt-3 d-flex align-items-end d-none">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Date d'expiration"
              aria-label="Date d'expiration"
            />
          </div>

          <div className="mt-5 d-flex bd-highlight justify-content-start">
            <div className="mx-2">
              <button className="btn btn-primary" type="submit">
                Modifier
              </button>
            </div>

            <div className="mx-2">
              <Link to="/promotion">
                <button className="btn btn-white" type="button">
                  Annuler
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreatePromotion;
