import React, { useState } from "react";
import { useUpdateRestaurantCategoryMutation } from "./restaurantCategoriesApiSlice";

function UpdateRestaurantCategoryModal({
  restaurantCategory,
  handleModalHasChanged,
}) {
  const [name, setName] = useState(restaurantCategory.name);
  const [description, setDescription] = useState(
    restaurantCategory.description
  );
  const [image, setImage] = useState({ name: restaurantCategory.image });

  const [updateRestaurantCategory] = useUpdateRestaurantCategoryMutation();

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("BEFORE SEND", {
      ...restaurantCategory,
      name,
      description,
      image,
      method: "POST",
    });

    // update restaurant category
    updateRestaurantCategory({
      ...restaurantCategory,
      name,
      description,
      image,
      method: "POST",
    });

    setName("");
    setDescription("");
    setImage({ name: null });
    // handleModalHasChanged();
  };

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#updateRestaurantCategoryModal${restaurantCategory.id}`}
      >
        <i className="bi bi-pencil fs-3"></i>
      </button>

      {/* <!-- Update Plan Modal --> */}
      <div
        class="modal fade"
        id={`updateRestaurantCategoryModal${restaurantCategory.id}`}
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div class="modal-content">
            {/* <!-- Header --> */}
            <div class="modal-header">
              <h4 class="modal-title" id="accountUpdatePlanModalLabel">
                Mise à jour d'une catégorie de restaurant
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <form
              onSubmit={handleSubmit}
              class="modal-body"
              encType="multipart/form-data"
            >
              <div>
                {/* <!-- Body --> */}
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-end mt-3">
                      <input
                        type="text"
                        id="updateRestaurantCategoryName"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        placeholder="Nom de la catégorie"
                        aria-label="Nom de la catégorie"
                      />
                    </div>

                    <div className="col-md-6 mt-3">
                      {/* <label htmlFor="formFile" class="form-label">
                        {image.name === null ? (
                          "Photo"
                        ) : (
                          <img src={image.name} />
                        )}
                      </label> */}
                      <input
                        class="form-control"
                        type="file"
                        id={`udapteRestaurantCategoryImage${restaurantCategory.id}`}
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <input
                        type="text"
                        id={`udateRestaurantCategoryDescription${restaurantCategory.id}`}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-control"
                        placeholder="Description de la catégorie du restaurant"
                        aria-label="Description de la catégorie du restaurant"
                      />
                    </div>

                    <div className="mt-5 d-flex bd-highlight justify-content-start">
                      <div className="mx-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Modifier
                        </button>
                      </div>

                      <div className="mx-2">
                        <button
                          type="button"
                          className="btn btn-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Update Plan Modal --> */}
    </div>
  );
}

export default UpdateRestaurantCategoryModal;
