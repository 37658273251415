import { Link } from "react-router-dom";
import { useState } from "react";
import React from "react";
import ConfigLink from "../components/ConfigLink";

function Setting() {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  return (
    <>
      <div className="row">
        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <Link className="card card-hover-shadow" to="/delivery-fee">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      <i className="bi bi-cash nav-icon"></i>
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">
                      Tarification des livraisons
                    </span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <Link className="card card-hover-shadow" to="/meta-data">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      <i className="bi bi-globe nav-icon"></i>
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Méta données</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/* <!-- End Card --> */}

        <ConfigLink
          link={"/notif-templates"}
          icon={"bi bi-bell"}
          title={"Modèles de notification"}
        />
      </div>
    </>
  );
}

export default Setting;
