import React, { useState } from "react";
import Title from "../../Shared/Title";
import { v4 as uuidv4 } from "uuid";

function UpdateDeliveryPerson() {
  return (
    <div>
      <Title
        title="Modifier les informations du livreur"
        backPath="/delivery-person"
      />
      {/* <!-- Body --> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Nom du livreur"
              aria-label="Nom du livreur"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Prénom(s) du livreur"
              aria-label="Prénom(s) du livreur"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Numero de telephone"
              aria-label="Numero de telephone"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Email"
              aria-label="Email"
            />
          </div>

          <div className="col-md-3 mt-3">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Date de naissance"
              aria-label="Date de naissance"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mt-3">
            <label for="formFile" class="form-label">
              Photo
            </label>
            <input class="form-control" type="file" id="formFile" />
          </div>

          <div className="col-md-6 mt-3 d-flex align-items-end">
            <input
              type="text"
              className="form-control"
              id="eventLocationLabel"
              placeholder="Adresse du livreur"
              aria-label="Adresse du livreur"
            />
          </div>

          <div className="mt-5 d-flex bd-highlight justify-content-start">
            <div className="mx-2">
              <button className="btn btn-primary">Modifier</button>
            </div>

            <div className="mx-2">
              <button className="btn btn-white">Annuler</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateDeliveryPerson;
