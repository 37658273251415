import { useState } from "react";
import Swal from "sweetalert2";
import {
  useAddDrinkCategoryMutation,
  useGetDrinkCategoriesQuery,
} from "./drinkCategoriesApiSlice";
import React from "react";

function CreateDrinkCategoryModal() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  const [addTag] = useAddDrinkCategoryMutation();
  const { refetch } = useGetDrinkCategoriesQuery();

  const handleSubmit = (e) => {
    e.preventDefault();

    addTag({ name, description, image })
      .unwrap()
      .then((response) => {
        if (response?.error?.originalStatus === 500) {
          Swal.fire({
            title: "Erreur",
            text: `Erreur du serveur lors de la création la catégorie de boisson `,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          refetch();
          Swal.fire({
            title: "Succees",
            text: `Catégorie de boisson créée avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la création de la catégorie de boisson`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });

    setName("");
    setDescription("");
    setImage(null);
  };

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#createDrinkModal"
      >
        <i className="bi bi-plus fs-3"></i>
      </button>

      {/* <!-- Update Plan Modal --> */}
      <div
        className="modal fade"
        id="createDrinkModal"
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Création d'une catégorie de boisson
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="modal-body"
            >
              <div>
                {/* <!-- Body --> */}
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 d-flex flex-column mt-3">
                      <label>Nom</label>
                      <input
                        type="text"
                        id="createRestaurantCategoryName"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        placeholder="Nom de la catégorie de boisson"
                        aria-label="Nom de la catégorie de boisson"
                      />
                    </div>

                    <div className="col-md-12 mt-3 d-flex flex-column">
                      <label>Description</label>
                      <input
                        type="text"
                        id="createRestaurantCategoryDescription"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-control"
                        placeholder="Description de la catégorie de boisson"
                        aria-label="Description de la catégorie de boisson"
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <label htmlFor="mealCategoryImage" className="form-label">
                        {image ? image.name : "Photo"}
                      </label>

                      <input
                        type="file"
                        id="image"
                        value=""
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                        className="form-control"
                      />
                    </div>

                    <div className="mt-5 d-flex bd-highlight justify-content-start">
                      <div className="mx-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Créer
                        </button>
                      </div>

                      <div className="mx-2">
                        <button
                          type="button"
                          className="btn btn-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Update Plan Modal --> */}
    </div>
  );
}

export default CreateDrinkCategoryModal;
