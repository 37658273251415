import { createSlice } from "@reduxjs/toolkit";

const dataTableSlice = createSlice({
  name: "dataTable",
  initialState: {
    items: [],
  },
  reducers: {
    setDataTableItems: (state, action) => {
      const { items } = action.payload;
      state.items = items;
    },
  },
});

export const { setDataTableItems } = dataTableSlice.actions;

export default dataTableSlice.reducer;

export const selectDataTableItems = (state) => state.dataTable.items;
