import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Title from "../../components/Shared/Title";
import {
  localDateFormat,
  localPriceFormat,
  stringAvatar,
} from "../../components/Shared/Utils";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import { useGetCustomerQuery } from "./customerApiSlice";
import { nanoid } from "@reduxjs/toolkit";
import STATUS from "../../components/Shared/Status";

function ReadCustomer() {
  const { customerId } = useParams();
  const [staff, setStaff] = useState(null);
  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCustomerQuery({ customerId });

  useEffect(() => {
    if (isSuccess) {
      setStaff({
        fullName: response.identity.fullName,
        telephone: response.identity.telephone,
        lastname: response.identity.lastname,
        firstname: response.identity.firstname,
        email: response.identity.email,
        orders: response.orders,
        created_at: response.created_at,
      });
    }
  }, [isLoading, isSuccess]);

  return (
    <>
      <Title title={staff?.fullName} backPath="/customer"></Title>

      <div className="row">
        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      5
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Livraisons</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      2
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Commandes en attente</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      3
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Paiements électronique</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      4
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Paiements Cash</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}
      </div>

      <div className="content container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="table-responsive datatable-custom">
              <table
                id="datatable"
                className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th>Commandes</th>
                    <th>Information</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {staff?.orders?.length <= 0 ? (
                    <tr key={nanoid()}>
                      <td colspan="3" className="text-center fw-bold">
                        Aucune commande
                      </td>
                    </tr>
                  ) : (
                    staff?.orders?.map((order) => (
                      <tr key={nanoid()}>
                        <td>
                          <Link to={`/order/${order.id}`}>
                            {order.reference}
                          </Link>
                        </td>
                        <td>
                          {localPriceFormat(
                            order.total_amount.toString(),
                            true
                          )}
                        </td>
                        <td>{STATUS[order.status]} </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="card-footer"></div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card">
              {/* <!-- Header --> */}
              <div class="card-header">
                <h4 class="card-header-title">Informations personnelles</h4>
              </div>
              {/* <!-- End Header --> */}

              {/* <!-- Body --> */}
              <div class="card-body">
                {/* <!-- List Group --> */}
                <ul class="list-group list-group-flush list-group-no-gutters">
                  <li class="list-group-item" key={nanoid()}>
                    <div class="d-flex align-items-center justify-content-around">
                      <p>Nom</p>
                      <p>{staff?.lastname}</p>
                    </div>
                  </li>

                  <li class="list-group-item" key={nanoid()}>
                    <div class="d-flex align-items-center justify-content-around">
                      <p>Prénom</p>
                      <p>{staff?.firstname}</p>
                    </div>
                  </li>

                  <li class="list-group-item" key={nanoid()}>
                    <div class="d-flex align-items-center justify-content-around">
                      <p>Téléphone</p>
                      <p>{staff?.telephone}</p>
                    </div>
                  </li>

                  <li class="list-group-item" key={nanoid()}>
                    <div class="d-flex align-items-center justify-content-around">
                      <p>Email</p>
                      <p>{staff?.email}</p>
                    </div>
                  </li>

                  <li class="list-group-item" key={nanoid()}>
                    <div class="d-flex align-items-center justify-content-around">
                      <p>Inscription le : </p>
                      <p>{localDateFormat(staff?.created_at)}</p>
                    </div>
                  </li>
                </ul>
                {/* <!-- End List Group --> */}
              </div>
              {/* <!-- End Body --> */}
            </div>
            {/* <!-- End Card --> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReadCustomer;
