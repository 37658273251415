import React, { useState } from "react";
import Title from "../../components/Shared/Title";
import { useUpdateMealCategoryMutation } from "./mealCategoriesApiSlice";

function UpdateMealCategoryModal({ mealCategory }) {
  const [name, setName] = useState(mealCategory.name);
  const [description, setDescription] = useState(mealCategory.description);
  const [image, setImage] = useState({ name: mealCategory.image });

  const [updateMealCategory] = useUpdateMealCategoryMutation();

  const handleSubmit = (e) => {
    e.preventDefault();

    // update meal category
    updateMealCategory({
      ...mealCategory,
      name,
      description,
      image,
      _method: "put",
    });

    setName("");
    setDescription("");
    setImage({ name: null });
  };

  const handleUpload = (file) => {
    setImage(file);
  };

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#mealCategoryUpdateModal${mealCategory.id}`}
      >
        <i className="bi bi-pencil fs-3"></i>
      </button>

      {/* <!-- Update Plan Modal --> */}
      <div
        className="modal fade"
        id={`mealCategoryUpdateModal${mealCategory.id}`}
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Modifier une catégorie de repas
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <form
              onSubmit={handleSubmit}
              className="modal-body"
              encType="multipart/form-data"
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 mt-3 d-flex align-items-end">
                    <input
                      type="text"
                      id="mealCategoryNameOnUpdate"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      placeholder="Nom de la catégorie"
                      aria-label="Nom de la catégorie"
                    />
                  </div>

                  <div className="col-md-12 mt-3">
                    <input
                      type="text"
                      id="mealCatetegoryDescriptionOnUpdate"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="form-control"
                      placeholder="Description de la catégorie du repas"
                      aria-label="Description de la catégorie du repas"
                    />
                  </div>

                  <div className="col-md-12 mt-3 d-flex  align-items-end">
                    <div className="">
                      <label htmlFor="mealCategoryImage" className="form-label">
                        {image?.value?.name ?? "Image"}
                      </label>

                      <input
                        type="file"
                        id="image"
                        value=""
                        onChange={(e) => {
                          handleUpload(e.target.files[0]);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="mt-5 d-flex bd-highlight justify-content-start">
                    <div className="mx-2">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Modifier
                      </button>
                    </div>

                    <div className="mx-2">
                      <button
                        className="btn btn-white"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Update Plan Modal --> */}
    </div>
  );
}

export default UpdateMealCategoryModal;
