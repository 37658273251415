import React from "react";
import Swal from "sweetalert2";
import { useDeleteDrinkMutation } from "./drinksApiSlice";

function DeleteDrinkModal({ drink }) {
  const [deleteDrink] = useDeleteDrinkMutation();

  async function displayConfirmation() {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Voulez-vous vraiment supprimer  ${drink.name} ?`,
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isDeleteConfirmed = swal.isConfirmed;

    if (isDeleteConfirmed) {
      deleteDrink(drink.id)
        .unwrap()
        .then((response) => {
          if (response?.error?.originalStatus === 500) {
            Swal.fire({
              title: "Suppression impossible",
              text: `La suppresison de la boisson n'est pas possible actuellement`,
              icon: "error",
              toast: true,
              position: "top-end",
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              title: "Succees",
              text: `Boisson supprimée avec succès`,
              icon: "success",
              toast: true,
              position: "top-end",
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((error) => {
          let text = error.data.message;
          Swal.fire({
            title: "Suppression impossible",
            text,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 10000,
            timerProgressBar: true,
          });
        });
    }
  }

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        onClick={displayConfirmation}
      >
        <i className="bi bi-trash fs-3"></i>
      </button>
    </div>
  );
}

export default DeleteDrinkModal;
