import React from "react";
import { Link } from "react-router-dom";

function Title({ title, backPath, children }) {
  return (
    <div className="page-header">
      <div className="row">
        <div className="text-center">
          <h1 className="page-header-title text-center">{title}</h1>
        </div>
      </div>
      {children}
    </div>
  );
}

export default Title;
