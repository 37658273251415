import { apiSlice } from "../../app/api/apiSlice";

const chatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecentCorrespondents: builder.query({
      query: () => "chat",
    }),
    getConversationMessages: builder.query({
      query: ({ correspondantId }) => `chat/${correspondantId}`,
    }),
    sendNewMessage: builder.mutation({
      query: ({ correspondantId, data }) => ({
        url: `chat/${correspondantId}`,
        method: "POST",
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useGetRecentCorrespondentsQuery,
  useGetConversationMessagesQuery,
  useSendNewMessageMutation,
} = chatApiSlice;
