import Title from "../../components/Shared/Title";
import { Link } from "react-router-dom";
import React from "react";
import NotifTemplateList from "../../features/notif-templates/NotifTemplateList";

export default function NotifTemplates() {
  return (
    <>
      <Title title={"Modèles de notification"} />

      <div className="row">
        <div className="col-lg-3 mb-3 mb-lg-5"></div>
      </div>

      <div className="container-fluid d-flex justify-content-end bd-highlight">
        <Link to="/delivery-fee/create">
          <button
            className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
            type="button"
          >
            <i className="bi bi-plus"></i>
          </button>
        </Link>
      </div>
      <NotifTemplateList />
    </>
  );
}
