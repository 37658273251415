import { apiSlice } from "../../app/api/apiSlice";

const dataForm = (data) => {
  const formdata = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (key !== "images") {
      formdata.append(key, value);
    }
  }
  return formdata;
};

const drinkCategoriesApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["DrinkCategories"],
  endpoints: (builder) => ({
    getDrinkCategories: builder.query({
      query: ({url}) => url,
      providesTags: ["DrinkCategories"],
    }),
    addDrinkCategory: builder.mutation({
      query: (drinkCategory) => ({
        url: "drink-categories",
        method: "POST",
        body: dataForm(drinkCategory),
      }),
      invalidatesTags: ["DrinkCategories"],
    }),
    updateDrinkCategory: builder.mutation({
      query: (drinkCategory) => ({
        url: `drink-categories/${drinkCategory.id}`,
        method: "POST",
        body: dataForm(drinkCategory),
      }),
      invalidatesTags: ["DrinkCategories"],
    }),
    deleteDrinkCategory: builder.mutation({
      query: (drinkCategoryId) => ({
        url: `drink-categories/${drinkCategoryId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DrinkCategories"],
    }),
  }),
});

export const {
  useGetDrinkCategoriesQuery,
  useAddDrinkCategoryMutation,
  useUpdateDrinkCategoryMutation,
  useDeleteDrinkCategoryMutation,
} = drinkCategoriesApiSlice;
