import Title from "../components/Shared/Title";
import React from "react";

function Commission() {
  return (
    <>
      <Title title="Commissions" />
      <div className="container-fluid d-flex flex-row-reverse bd-highlight"></div>
      {/* <RestaurantListing /> */}
    </>
  );
}

export default Commission;
