import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

const restaurantStaffApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["RestaurantStaff"],
  endpoints: (builder) => ({
    getRestaurantStaff: builder.query({
      query: ({ url }) => url,
      providesTags: ["RestaurantStaff"],
    }),
    getRestaurantStaffById: builder.query({
      query: ({ id }) => `staff/${id}`,
      providesTags: ["RestaurantStaff"],
    }),
    getCreateRestaurantStaffData: builder.query({
      query: () => "restaurant-staff/create",
    }),
    addRestaurantStaff: builder.mutation({
      query: (data) => ({
        url: "restaurant-staff",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["RestaurantStaff"],
    }),
    updateRestaurantStaff: builder.mutation({
      query: (restaurantStaff) => ({
        url: `restaurant-staff/${restaurantStaff.id}`,
        method: "PUT",
        body: { ...restaurantStaff },
      }),
      invalidatesTags: ["RestaurantStaff"],
    }),
    deleteRestaurantStaff: builder.mutation({
      query: (restaurantStaffId) => ({
        url: `restaurant-staff/${restaurantStaffId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RestaurantStaff"],
    }),
  }),
});

export const {
  useGetRestaurantStaffQuery,
  useGetRestaurantStaffByIdQuery,
  useGetCreateRestaurantStaffDataQuery,
  useAddRestaurantStaffMutation,
  useUpdateRestaurantStaffMutation,
  useDeleteRestaurantStaffMutation,
} = restaurantStaffApiSlice;
