import React from "react";
import DataTable from "../dataTable/DataTableBase";
import { useGetTagsQuery } from "./tagsApiSlice";

function ZoneList({ handleModalHasChanged }) {
  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetTagsQuery();

  const columns = [
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
    },
  ];

  let data = [];

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((tag) => ({
      id: tag.id,
      name: tag.name,
      description: tag.description,
    }));
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (tag) => {
    return (
      <div className="fw-bold d-flex">
        {/* <ReadTagModal tag={tag} /> */}
        {/* <UpdateTagModal tag={tag} /> */}
        {/* <DeleteTagModal tag={tag} /> */}
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <DataTable columns={columns} data={data} actions={actions} />
    </div>
  );
}

export default ZoneList;
