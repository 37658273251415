import React from "react";
import { useParams } from "react-router-dom";
import ChatConversation from "../features/chat/ChatConversation";

const Chat = () => {
  const { userId } = useParams();

  return (
    <div>
      {userId ? (
        <ChatConversation correspondantId={userId} />
      ) : (
        "Bienvenu(e) dans votre messagerie"
      )}
    </div>
  );
};

export default Chat;
