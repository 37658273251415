import React from "react";
import DataTable from "../dataTable/DataTableBase";
import DeleteRestaurantModal from "../../components/Staff/crud/DeleteDeliveryPersonModal";
import { Link } from "react-router-dom";
import {
  useGetAllPartnersQuery,
  useGetAllStaffQuery,
} from "./partnersApiSlice";
import { useReadAllQuery } from "../crud/crudApiSlice";

function PartnersList() {
  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllPartnersQuery();

  const columns = [
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
    {
      name: "Numero",
      selector: "telephone",
    },
  ];

  let data = [];

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((staff) => ({
      id: staff.id,
      name: staff.identity.fullName,
      telephone: staff.identity.telephone,
    }));
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (staff) => {
    return (
      <div className="fw-bold d-flex">
        <Link to={`/staff/${staff.id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle"
            type="button"
          >
            <i className="bi bi-eye"></i>
          </button>
        </Link>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <DataTable columns={columns} data={data} actions={actions} />
    </div>
  );
}

export default PartnersList;
