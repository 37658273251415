import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

const deliveryCompanyApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["DeliveryCompanies", "DeliveryCompany", "DeliveryCompanyOrders"],
  endpoints: (builder) => ({
    getDeliveryCompanies: builder.query({
      query: ({ url }) => url,
      providesTags: ["DeliveryCompanies"],
    }),
    getDeliveryCompany: builder.query({
      query: ({ deliveryCompanyId }) => {
        return `delivery-companies/${deliveryCompanyId}`;
      },
      providesTags: ["DeliveryCompany"],
    }),
    getCreateDeliveryCompanyData: builder.query({
      query: () => "delivery-companies/create",
    }),
    addDeliveryCompany: builder.mutation({
      query: (deliveryCompany) => ({
        url: "delivery-companies",
        method: "POST",
        body: deliveryCompany,
      }),
      invalidatesTags: ["DeliveryCompanies"],
    }),
    updateDeliveryCompany: builder.mutation({
      query: (deliveryCompany) => ({
        url: `delivery-companies/${deliveryCompany.id}`,
        method: "PUT",
        body: deliveryCompany,
      }),
      invalidatesTags: ["DeliveryCompanies", "DeliveryCompany"],
    }),
    deleteDeliveryCompany: builder.mutation({
      query: (deliveryCompanyId) => ({
        url: `delivery-companies/${deliveryCompanyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DeliveryCompanies"],
    }),
    validateDeliveryCompany: builder.mutation({
      query: ({ deliveryCompanyId }) => ({
        url: `delivery-companies/${deliveryCompanyId}/validate`,
        method: "PUT",
      }),
      invalidatesTags: ["DeliveryCompanies", "DeliveryCompany"],
    }),

    // Delivery company orders
    getDeliveryCompanyOrders: builder.query({
      query: ({ url }) => url,
      providesTags: ["DeliveryCompanyOrders"],
    }),
    acceptDeliveryCompanyOrder: builder.mutation({
      query: (data) => ({
        url: `accept/order`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["DeliveryCompanyOrders"],
    }),
    processDeliveryCompanyOrder: builder.mutation({
      query: (data) => ({
        url: `processing/order`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["DeliveryCompanyOrders"],
    }),
    readyDeliveryCompanyOrder: builder.mutation({
      query: (data) => ({
        url: `ready/order`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["DeliveryCompanyOrders"],
    }),
  }),
});

export const {
  // DeliveryCompanies
  useGetDeliveryCompaniesQuery,
  useGetDeliveryCompanyQuery,
  useGetCreateDeliveryCompanyDataQuery,
  useAddDeliveryCompanyMutation,
  useUpdateDeliveryCompanyMutation,
  useDeleteDeliveryCompanyMutation,
  useValidateDeliveryCompanyMutation,

  // DeliveryCompanyOrders
  useGetDeliveryCompanyOrdersQuery,
  useAcceptDeliveryCompanyOrderMutation,
  useProcessDeliveryCompanyOrderMutation,
  useReadyDeliveryCompanyOrderMutation,
} = deliveryCompanyApiSlice;
