import React, { useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import DeleteRestaurantModal from "../../components/Staff/crud/DeleteDeliveryPersonModal";
import { Link } from "react-router-dom";
import { useGetAllStaffQuery } from "./staffApiSlice";

function StaffList() {
  const [baseUrl, setBaseUrl] = useState("staff");
  const [url, setUrl] = useState(baseUrl);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllStaffQuery({ url });

  const columns = [
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
    {
      name: "Numero",
      selector: "telephone",
    },
  ];

  let data = [];
  let meta = {};

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((staff) => ({
      id: staff.id,
      name: staff.identity.fullName,
      telephone: staff.identity.telephone,
    }));

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (staff) => {
    return (
      <div className="fw-bold d-flex">
        <Link to={`/staff/${staff.id}`}>
          <button
            className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
            type="button"
          >
            <i className="bi bi-eye"></i>
          </button>
        </Link>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        baseUrl={baseUrl}
        setUrl={setUrl}
        meta={meta}
      />
    </div>
  );
}

export default StaffList;
