import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Avatar from "@mui/material/Avatar";
import STATUS from "../../components/Shared/Status";
import {
  localDateFormat,
  localPriceFormat,
} from "../../components/Shared/Utils";
import { useDispatch } from "react-redux";
import { setDataTableItems } from "./dataTableSlice";

function DataTableBase({
  columns,
  data,
  actions,
  baseUrl,
  setUrl,
  meta,
  canSelect = false,
}) {
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setItems(data.map((element) => ({ ...element, isSelected: false })));
  }, [data]);

  useEffect(() => {
    dispatch(setDataTableItems({ items }));
  }, [items]);

  const toggleItemSelection = (id) => {
    setItems(
      items.map((item) => {
        if (item.id === id) {
          return { ...item, isSelected: !item.isSelected };
        } else {
          return item;
        }
      })
    );
  };

  const previous = () => {
    if (meta.currentPage > 1) {
      setUrl(baseUrl + `?page=${meta.currentPage - 1}`);
    }
  };

  const next = () => {
    console.log("NEXT BEGIN");
    if (meta.currentPage < meta.lastPage) {
      setUrl(baseUrl + `?page=${meta.currentPage + 1}`);
    }
    console.log(`NEXT END`);
  };
  return (
    <div className="card mb-3 mb-lg-5">
      {/* <!-- Card --> */}
      {/* <!-- Table --> */}
      <div className="table-responsive datatable-custom">
        <table
          id="datatable"
          className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
          data-hs-datatables-options='{
                   "columnDefs": [{
                      "targets": [0, 1, 4],
                      "orderable": false
                    }],
                   "order": [],
                   "info": {
                     "totalQty": "#datatableWithPaginationInfoTotalQty"
                   },
                   "search": "#datatableSearch",
                   "entries": "#datatableEntries",
                   "pageLength": 8,
                   "isResponsive": false,
                   "isShowPaging": false,
                   "pagination": "datatablePagination"
                 }'
        >
          <thead className="thead-light">
            <tr>
              {canSelect && <th key={uuidv4()}>Sélection</th>}
              {columns.map((column) => (
                <th key={uuidv4()}>{column.name}</th>
              ))}
              {actions ? <th key={uuidv4()}>Actions</th> : null}
            </tr>
          </thead>

          <tbody>
            {items.map((row) => (
              <tr key={uuidv4()}>
                {canSelect && (
                  <td>
                    <div className="form-check">
                      <input
                        className="form-check-input border border-primary"
                        type="checkbox"
                        id={`checkbox`}
                        checked={row.isSelected}
                        onChange={() => toggleItemSelection(row.id)}
                      />
                    </div>
                  </td>
                )}
                {columns.map((column) => {
                  if (column.type === "image") {
                    return (
                      <td key={uuidv4()}>
                        <Avatar alt=" " src={row[column.selector]} />
                      </td>
                    );
                  } else if (column.type === "status") {
                    return (
                      <td key={uuidv4()}>{STATUS[row[column.selector]]}</td>
                    );
                  } else if (column.type === "price") {
                    return (
                      <td key={uuidv4()}>
                        {localPriceFormat(row[column.selector], true)}{" "}
                      </td>
                    );
                  } else if (column.type === "date") {
                    return (
                      <td key={uuidv4()}>
                        {localDateFormat(row[column.selector])}
                      </td>
                    );
                  } else if (column.type === "list") {
                    return (
                      <td key={uuidv4()}>
                        <ul>
                          {row[column.selector].map((element) => (
                            <li key={uuidv4()}>{element}</li>
                          ))}
                        </ul>
                      </td>
                    );
                  } else if (column.type === "list_status") {
                    return (
                      <td key={uuidv4()}>
                        <ul>
                          {row[column.selector].map((element) => (
                            <li key={uuidv4()}>
                              {`${element.value}`} {}
                            </li>
                          ))}
                        </ul>
                      </td>
                    );
                  } else {
                    return <td key={uuidv4()}>{row[column.selector]}</td>;
                  }
                })}
                {actions ? <td>{actions(row)}</td> : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <!-- End Table --> */}

      {/* <!-- Footer --> */}
      <div className="card-footer">
        {/* <!-- Pagination --> */}
        {meta.total && (
          <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
            <div className="col-sm mb-2 mb-sm-0">
              <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                <span className="me-2">Éléments: </span>

                <span className="text-secondary me-2">{`${
                  (meta.currentPage - 1) * meta.perPage + 1
                } à ${
                  meta.currentPage === meta.lastPage
                    ? meta.total
                    : meta.currentPage * meta.perPage
                }`}</span>

                <span className="text-secondary me-2">sur</span>

                {/* <!-- Pagination Quantity --> */}
                <span id="datatableWithPaginationInfoTotalQty">
                  {meta.total}
                </span>
              </div>
            </div>
            {/* <!-- End Col --> */}

            <div className="col-sm-auto">
              <div className="d-flex justify-content-center justify-content-sm-end">
                {/* <!-- Pagination --> */}

                <nav id="datatablePagination" aria-label="Activity pagination">
                  <div
                    class="dataTables_paginate paging_simple_numbers"
                    id="datatable_paginate"
                  >
                    <ul
                      id="datatable_pagination"
                      class="pagination datatable-custom-pagination"
                    >
                      <li
                        class={`paginate_item page-item  ${
                          meta.currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          class={`paginate_button previous page-link`}
                          onClick={previous}
                        >
                          <span aria-hidden="true">Précédent</span>
                        </button>
                      </li>
                      <li class="paginate_item page-item active">
                        <button className="paginate_button page-link">
                          {meta.currentPage}
                        </button>
                      </li>
                      <li
                        class={`paginate_item page-item ${
                          meta.currentPage === meta.lastPage ? "disabled" : ""
                        }`}
                      >
                        <button
                          class="paginate_button next page-link"
                          onClick={next}
                        >
                          <span aria-hidden="true">Suivant</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            {/* <!-- End Col --> */}
          </div>
        )}
        {/* <!-- End Pagination --> */}
      </div>
      {/* <!-- End Footer --> */}
      {/* <!-- End Card --> */}
    </div>
  );
}

export default DataTableBase;
