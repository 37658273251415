import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({
  name = "",
  description = "",
  image = null,
  method = null,
}) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  if (method !== "put" && method !== "PUT") {
    formdata.append("image", image[0]);
  }
  formdata.append("_method", method);

  return formdata;
};

const restaurantCategoriesApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["RestaurantCategories"],
  endpoints: (builder) => ({
    getRestaurantCategories: builder.query({
      query: ({ url }) => url,
      providesTags: ["RestaurantCategories"],
      keepUnusedDataFor: 0,
    }),
    addRestaurantCategory: builder.mutation({
      query: (restaurantCategory) => ({
        url: "restaurant-categories",
        method: "POST",
        body: dataForm(restaurantCategory),
      }),
      invalidatesTags: ["RestaurantCategories"],
    }),
    updateRestaurantCategory: builder.mutation({
      query: (restaurantCategory) => ({
        url: `restaurant-categories/${restaurantCategory.id}`,
        method: "POST",
        body: () => {
          return {
            name: restaurantCategory.name,
            description: restaurantCategory.description,
          };
        },
      }),
      // invalidatesTags: ["RestaurantCategories"],
    }),
    deleteRestaurantCategory: builder.mutation({
      query: (restaurantCategoryId) => ({
        url: `restaurant-categories/${restaurantCategoryId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RestaurantCategories"],
    }),
  }),
});

export const {
  useGetRestaurantCategoriesQuery,
  useAddRestaurantCategoryMutation,
  useUpdateRestaurantCategoryMutation,
  useDeleteRestaurantCategoryMutation,
} = restaurantCategoriesApiSlice;
