import { useState } from "react";
import Swal from "sweetalert2";
import Input from "../../components/formik/Input";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { useUpdateNotifTemplateMutation } from "./notifTemplateApiSlice";
import TextInput from "../../components/formik/TextInput";

function ReadNotifTemplateModal({ notifTemplate }) {
  return (
    <div>
      <button
        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#readNotifTemplateModal${notifTemplate.id}`}
      >
        <i className="bi bi-eye"></i>
      </button>

      <div
        className="modal fade"
        id={`readNotifTemplateModal${notifTemplate.id}`}
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Consulter le template de notification
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* ------- */}

            <div className="modal-body">
              <div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <p className="text-center">Label: </p>
                      <p className="text-body">{notifTemplate.label}</p>
                    </div>
                    <div className="col-md-12 mt-3">
                      <p className="text-center">Template: </p>
                      <p>{notifTemplate.template}</p>
                    </div>
                    <div className="col-md-12 mt-3">
                      <p className="text-center">Titre:</p>
                      <p>{notifTemplate.title}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ------- */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReadNotifTemplateModal;
