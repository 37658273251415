import React from "react";
import Swal from "sweetalert2";
import { useDeleteTagMutation, useGetTagsQuery } from "./tagsApiSlice";
import useHandleResourceDeletion from "../../hooks/shared/useHandleResourceDeletion";

function DeleteTagModal({ tag }) {
  const [deleteTag] = useDeleteTagMutation();
  const { refetch } = useGetTagsQuery();

  async function displayConfirmation() {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Voulez-vous vraiment supprimer  ${tag.name} ?`,
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isDeleteConfirmed = swal.isConfirmed;

    if (isDeleteConfirmed) {
      deleteTag(tag.id)
        .unwrap()
        .then(() => {
          Swal.fire({
            title: "Succees",
            text: `Tag supprimé avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((error) => {
          let message = "<ul>";
          if (error.data.message) {
            message += error.data.message;
          }

          if (error.data.errors) {
            for (const [key, value] of Object.entries(error.data.errors)) {
              message += `<li>${key} : ${value.join(" ")}</li>`;
            }
          }

          message += "</ul>";

          Swal.fire({
            title: "Erreur",
            html: message,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 15000,
            timerProgressBar: true,
          });
        });
    }
  }

  return (
    <div>
      <button
        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
        type="button"
        onClick={displayConfirmation}
      >
        <i className="bi bi-trash"></i>
      </button>
    </div>
  );
}

export default DeleteTagModal;
