import React from "react";

function KeyboardShortcuts() {
  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="offcanvasKeyboardShortcuts"
      aria-labelledby="offcanvasKeyboardShortcutsLabel"
    >
      <div className="offcanvas-header">
        <h4 id="offcanvasKeyboardShortcutsLabel" className="mb-0">
          Keyboard shortcuts
        </h4>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div className="list-group list-group-sm list-group-flush list-group-no-gutters mb-5">
          <div className="list-group-item">
            <h5 className="mb-1">Formatting</h5>
          </div>
          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span className="fw-semi-bold">Bold</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">b</kbd>
              </div>
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <em>italic</em>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">i</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <u>Underline</u>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">u</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <s>Strikethrough</s>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">Alt</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">s</kbd>
                {/* End Col */}
              </div>
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span className="small">Small text</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">s</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <mark>Highlight</mark>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">e</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
        </div>

        <div className="list-group list-group-sm list-group-flush list-group-no-gutters mb-5">
          <div className="list-group-item">
            <h5 className="mb-1">Insert</h5>
          </div>
          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>
                  Mention person <a href="#">(@Brian)</a>
                </span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">@</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>
                  Link to doc <a href="#">(+Meeting notes)</a>
                </span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">+</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <a href="#">#hashtag</a>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">#hashtag</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Date</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">/date</kbd>
                <kbd className="d-inline-block mb-1">Space</kbd>
                <kbd className="d-inline-block mb-1">/datetime</kbd>
                <kbd className="d-inline-block mb-1">/datetime</kbd>
                <kbd className="d-inline-block mb-1">Space</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Time</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">/time</kbd>
                <kbd className="d-inline-block mb-1">Space</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Note box</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">/note</kbd>
                <kbd className="d-inline-block mb-1">Enter</kbd>
                <kbd className="d-inline-block mb-1">/note red</kbd>
                <kbd className="d-inline-block mb-1">/note red</kbd>
                <kbd className="d-inline-block mb-1">Enter</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
        </div>

        <div className="list-group list-group-sm list-group-flush list-group-no-gutters mb-5">
          <div className="list-group-item">
            <h5 className="mb-1">Editing</h5>
          </div>
          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Find and replace</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">r</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Find next</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">n</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Find previous</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">p</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Indent</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Tab</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Un-indent</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Shift</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">Tab</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Move line up</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">Shift</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">
                  <i className="bi-arrow-up-short"></i>
                </kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Move line down</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">Shift</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">
                  <i className="bi-arrow-down-short fs-5"></i>
                </kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Add a comment</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">Alt</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">m</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Undo</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">z</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Redo</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">y</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
        </div>

        <div className="list-group list-group-sm list-group-flush list-group-no-gutters">
          <div className="list-group-item">
            <h5 className="mb-1">Application</h5>
          </div>
          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Create new doc</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">Alt</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">n</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Present</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">Shift</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">p</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Share</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">Shift</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">s</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Search docs</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">Shift</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">o</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>

          <div className="list-group-item">
            <div className="row align-items-center">
              <div className="col-5">
                <span>Keyboard shortcuts</span>
              </div>
              {/* End Col */}

              <div className="col-7 text-end">
                <kbd className="d-inline-block mb-1">Ctrl</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">Shift</kbd>
                <span className="text-muted small">+</span>
                <kbd className="d-inline-block mb-1">/</kbd>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default KeyboardShortcuts;
