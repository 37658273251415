import React, { useState } from "react";
import {
  useAddRestaurantCategoryMutation,
  useGetRestaurantCategoriesQuery,
} from "./restaurantCategoriesApiSlice";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

function CreateRestaurantCategoryModal() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  const [addRestaurantCategory] = useAddRestaurantCategoryMutation();
  const { refetch } = useGetRestaurantCategoriesQuery({
    url: "restaurant-categories",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    addRestaurantCategory({ name, description, image })
      .unwrap()
      .then(() => {
        refetch();

        Swal.fire({
          title: "Succees",
          text: `Catégorie de restaurant créée avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        console.log("CREATE RESTAURANT CATEGORY MODAL ERROR", error);

        let message = "<ul>";
        for (const [key, value] of Object.entries(error.data.errors)) {
          message += `<li>${key} : ${value.join(" ")}</li>`;
        }
        message += "</ul>";

        Swal.fire({
          title: "Erreur",
          html: message,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 15000,
          timerProgressBar: true,
        });
      });

    setName("");
    setDescription("");
    setImage(null);
  };

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#createRestaurantCategoryModal"
      >
        <i className="bi bi-plus fs-3"></i>
      </button>

      {/* <!-- Update Plan Modal --> */}
      <div
        className="modal fade"
        id="createRestaurantCategoryModal"
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Création d'une catégorie de restaurant
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="modal-body"
            >
              <div>
                {/* <!-- Body --> */}
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-start flex-column mt-3">
                      <p className="">Nom de la catégorie</p>
                      <p>
                        <input
                          type="text"
                          id="createRestaurantCategoryName"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                          placeholder="Nom de la catégorie"
                          aria-label="Nom de la catégorie"
                        />
                      </p>
                    </div>

                    <div className="col-md-6 mt-3">
                      <p>Image : {image?.name}</p>
                      <p>
                        <input
                          className="form-control"
                          type="file"
                          id="createRestaurantCategoryImage"
                          value=""
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </p>
                    </div>

                    <div className="col-md-12 mt-3">
                      <p>Description</p>
                      <textarea
                        type="text"
                        id="createRestaurantCategoryDescription"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-control"
                        placeholder="Description de la catégorie du restaurant"
                        aria-label="Description de la catégorie du restaurant"
                      />
                    </div>

                    <div className="mt-5 d-flex bd-highlight justify-content-start">
                      <div className="mx-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Créer
                        </button>
                      </div>

                      <div className="mx-2">
                        <button
                          type="button"
                          className="btn btn-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Update Plan Modal --> */}
    </div>
  );
}

export default CreateRestaurantCategoryModal;
