import { createSlice } from "@reduxjs/toolkit";

const crudSlice = createSlice({
  name: "crud",
  initialState: { url: null },
  reducers: {
    setUrl: (state, action) => {
      const { url } = action.payload;
      state.url = url;
    },
  },
});

export const { setUrl } = crudSlice.actions;

export default crudSlice.reducer;

export const selectUrl = (state) => state.crud.url;
