import React, { useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import { useGetAdvertisementsQuery } from "./advertisementsApiSlice";
import ReadAdvertisementModal from "../../components/Advertisement/ReadAdvertisementModal";
import UpdateAdvertisementModal from "./UpdateAdvertisementModal";
import DeleteAdvertisementModal from "./DeleteAdvertisementModal";

function AdvertisementList() {
  const [baseUrl, setBaseUrl] = useState("advertisements");
  const [url, setUrl] = useState(baseUrl);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAdvertisementsQuery({ url });

  const columns = [
    {
      name: "Titre",
      selector: "title",
      sortable: true,
    },
  ];

  let data = [];
  let meta = {};

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((advertisement) => ({
      ...advertisement,
    }));

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (advertisement) => {
    return (
      <div className="fw-bold d-flex">
        <ReadAdvertisementModal advertisement={advertisement} />
        <UpdateAdvertisementModal advertisement={advertisement} />
        <DeleteAdvertisementModal advertisement={advertisement} />
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        baseUrl={baseUrl}
        setUrl={setUrl}
        meta={meta}
      />
    </div>
  );
}

export default AdvertisementList;
