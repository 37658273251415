import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetUserInfoQuery, useLoginQuery } from "../authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIdentity,
  selectRoles,
  selectUserWallet,
  setUserInfo,
} from "../authSlice";
import RequireRestaurantSelection from "../../restaurants/RequireRestaurantSelection";
import { selectSelectedRestaurant } from "../../restaurants/restaurantSlice";
import Avatar from "@mui/material/Avatar";
import { localPriceFormat } from "../../../components/Shared/Utils";
import {
  useGetAdminDashboardStatsQuery,
  useGetRestaurantStatsQuery,
} from "../../restaurants/restaurantsApiSlice";

function Dashboard() {
  const currentRoles = useSelector(selectRoles);
  const currentRolesNames = currentRoles?.map((role) => role.name);
  const currentIdentity = useSelector(selectIdentity);
  const selectedRestaurant = useSelector(selectSelectedRestaurant);
  const userWallet = useSelector(selectUserWallet);
  let avatarImg = process.env.PUBLIC_URL + "/assets/svg/logos/logo.png";

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUserInfoQuery();

  const restaurantStats = useGetRestaurantStatsQuery({
    restaurantId: selectedRestaurant?.id,
  });

  const adminStats = useGetAdminDashboardStatsQuery();

  let stats = null;
  if (restaurantStats) {
    stats = restaurantStats;
  }
  if (["admin"].some((role) => currentRolesNames?.includes(role))) {
    stats = adminStats;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUserInfo({ ...response }));
    }
  }, [isSuccess]);
  if (selectedRestaurant) {
    avatarImg =
      process.env.REACT_APP_BACK_END + selectedRestaurant.banner?.path;
  }

  return (
    <div className="container">
      {["restaurant-admin"].some((role) => currentRolesNames?.includes(role)) &&
      !selectedRestaurant ? (
        <RequireRestaurantSelection />
      ) : (
        <>
          {/* // <!-- Stats --> */}
          <div className="row">
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              {/* <!-- Card --> */}
              <a className="card card-hover-shadow h-100" href="#">
                <div className="card-body">
                  <h6 className="card-subtitle">Total Repas</h6>

                  <div className="row align-items-center gx-2 my-3">
                    {/* <div className="col-7"> */}
                    <h2 className="card-title text-inherit">
                      {stats && stats?.data?.total_meals}
                    </h2>
                    {/* </div> */}
                    {/* <!-- End Col --> */}
                  </div>
                  {/* <!-- End Row --> */}

                  <div className="row align-items-center gx-2 mb-1">
                    <Link to="/meal">
                      <button
                        class="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                        type="button"
                      >
                        <i class="bi bi-egg-fried me-1"></i>
                      </button>

                      <span className="text-body fs-6 ms-1">Repas</span>
                    </Link>
                  </div>
                </div>
              </a>
              {/* <!-- End Card --> */}
            </div>

            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              {/* <!-- Card --> */}
              <a className="card card-hover-shadow h-100" href="#">
                <div className="card-body">
                  <h6 className="card-subtitle">Commandes en attente</h6>

                  <div className="row align-items-center gx-2 my-3">
                    {/* <div className="col-7"> */}
                    <h2 className="card-title text-inherit">
                      {stats && stats?.data?.pending_orders}
                    </h2>
                    {/* </div> */}
                    {/* <!-- End Col --> */}
                  </div>
                  {/* <!-- End Row --> */}

                  <div className="row align-items-center gx-2 mb-1">
                    <Link to="/order">
                      <button
                        class="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                        type="button"
                      >
                        <i class="bi bi-clipboard me-1"></i>
                      </button>
                      <span className="text-body fs-6 ms-1">En Attente</span>
                    </Link>
                  </div>
                </div>
              </a>
              {/* <!-- End Card --> */}
            </div>

            {/* <!-- End Stats --> */}

            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              {/* <!-- Card --> */}
              <a className="card card-hover-shadow h-100" href="#">
                <div className="card-body">
                  <h6 className="card-subtitle">Livraisons En Attente</h6>

                  <div className="row align-items-center gx-2 my-3">
                    {/* <div className="col-7"> */}
                    <h2 className="card-title text-inherit">
                      {stats && stats?.data?.recupered_orders}
                    </h2>
                    {/* </div> */}
                    {/* <!-- End Col --> */}
                  </div>
                  {/* <!-- End Row --> */}

                  <div className="row align-items-center gx-2 mb-1">
                    <Link to="/order">
                      <button
                        class="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                        type="button"
                      >
                        <i class="bi bi-bicycle me-1"></i>
                      </button>

                      <span className="text-body fs-6 ms-1">Livraisons</span>
                    </Link>
                  </div>
                </div>
              </a>
              {/* <!-- End Card --> */}
            </div>

            {/* <!-- End Stats --> */}

            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
              {/* <!-- Card --> */}
              <a className="card card-hover-shadow h-100" href="#">
                <div className="card-body">
                  <h6 className="card-subtitle">Portefeuille</h6>

                  <div className="row align-items-center gx-2 my-3">
                    {/* <div className="col-7"> */}
                    <h2 className="card-title text-inherit">
                      {selectedRestaurant
                        ? localPriceFormat(selectedRestaurant.wallet.balance)
                        : "Aucun Portefeuille"}
                    </h2>
                    {/* </div> */}
                    {/* <!-- End Col --> */}
                  </div>
                  {/* <!-- End Row --> */}

                  <div className="row align-items-center gx-2 mb-1">
                    <Link to="/wallet">
                      <button
                        class="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                        type="button"
                      >
                        <i class="bi bi-wallet me-1"></i>
                      </button>
                      <span className="text-body fs-6 ms-1">Fcfa</span>
                    </Link>
                  </div>
                </div>
              </a>
              {/* <!-- End Card --> */}
            </div>

            {/* <!-- End Stats --> */}
          </div>

          <div className="row">
            {/* <!-- Card --> */}
            <div className="card mb-3 mb-lg-5">
              {/* <!-- Body --> */}
              <div className="card-body">
                <div className="d-flex align-items-md-center">
                  <div className="flex-shrink-0">
                    <span className="display-3 text-dark">
                      {stats && stats?.data?.total_orders}
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <div className="row mx-md-n3">
                      <div className="col-md px-md-4">
                        <span className="d-block">Commandes</span>
                        <span className="d-none badge bg-soft-danger text-danger rounded-pill p-1">
                          <i className="bi-graph-down"></i> -2 en retard
                        </span>
                      </div>
                      {/* <!-- End Col --> */}

                      <div className="col-md-9 col-lg-10 column-md-divider px-md-4">
                        <div className="row justify-content-start mb-2">
                          <div className="col-auto">
                            <span className="legend-indicator bg-primary"></span>
                            En cours ({stats && stats?.data?.recupered_orders})
                          </div>
                          {/* <!-- End Col --> */}

                          <div className="col-auto">
                            <span className="legend-indicator bg-success"></span>
                            Livrées ({stats && stats?.data?.delivered_orders})
                          </div>
                          {/* <!-- End Col --> */}

                          <div className="col-auto">
                            <span className="legend-indicator"></span>
                            En Attente ({stats && stats?.data?.pending_orders})
                          </div>
                          {/* <!-- End Col --> */}
                        </div>
                        {/* <!-- End Row --> */}

                        {/* <!-- Progress --> */}
                        <div className="progress rounded-pill">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "40%" }}
                            aria-valuenow="40"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{ width: "30%" }}
                            aria-valuenow="30"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        {/* <!-- End Progress --> */}
                      </div>
                      {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Row --> */}
                  </div>
                </div>
              </div>
              {/* <!-- End Body --> */}
            </div>
            {/* <!-- End Card --> */}
          </div>
          <div className="row">
            {/* <!-- Card --> */}
            <div className="card h-100">
              {/* <!-- Header --> */}
              <div className="card-header card-header-content-between">
                <h4 className="card-header-title">Bilan</h4>

                {/* <!-- Dropdown --> */}
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                    id="reportsOverviewDropdown1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi-three-dots-vertical"></i>
                  </button>

                  <div
                    className="dropdown-menu dropdown-menu-end mt-1"
                    aria-labelledby="reportsOverviewDropdown1"
                  >
                    <span className="dropdown-header">Settings</span>

                    <a className="dropdown-item" href="#">
                      <i className="bi-share-fill dropdown-item-icon"></i> Share
                      reports
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="bi-download dropdown-item-icon"></i>{" "}
                      Download
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="bi-alt dropdown-item-icon"></i> Connect
                      other apps
                    </a>

                    <div className="dropdown-divider"></div>

                    <span className="dropdown-header">Feedback</span>

                    <a className="dropdown-item" href="#">
                      <i className="bi-chat-left-dots dropdown-item-icon"></i>{" "}
                      Report
                    </a>
                  </div>
                </div>
                {/* <!-- End Dropdown --> */}
              </div>
              {/* <!-- End Header --> */}

              {/* <!-- Body --> */}
              <div className="card-body">
                <span className="h1 d-block mb-4">
                  {selectedRestaurant
                    ? localPriceFormat(selectedRestaurant.wallet.balance, true)
                    : "Aucun Portefeuille"}
                </span>

                {/* <!-- Progress --> */}
                <div className="progress rounded-pill mb-2">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "25%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Repas"
                  ></div>
                  <div
                    className="progress-bar opacity-50"
                    role="progressbar"
                    style={{ width: "33%" }}
                    aria-valuenow="33"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Boissons"
                  ></div>
                  <div
                    className="progress-bar opacity-25"
                    role="progressbar"
                    style={{ width: "9%" }}
                    aria-valuenow="9"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Suppléments"
                  ></div>
                </div>

                <div className="d-flex justify-content-between mb-4">
                  <span>0%</span>
                  <span>100%</span>
                </div>
                {/* <!-- End Progress --> */}

                {/* <!-- Table --> */}
                <div className="table-responsive">
                  <table className="table table-lg table-nowrap card-table mb-0">
                    <tr>
                      <th scope="row">Repas</th>
                      <td>{stats && stats?.data?.total_meals}</td>
                      <td>
                        <Link to="/meal">
                          <button
                            class="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                            type="button"
                          >
                            <i class="bi bi-egg-fried me-1"></i>
                          </button>
                        </Link>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">Boissons</th>
                      <td>{stats && stats?.data?.total_drinks}</td>
                      <td>
                        <Link to="/drink">
                          <button
                            class="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                            type="button"
                          >
                            <i class="bi bi-cup-straw me-1"></i>
                          </button>
                        </Link>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">Promos actives</th>
                      <td>{stats && stats?.data?.total_active_promos}</td>
                      <td>
                        <Link to="/promotion">
                          <button
                            class="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                            type="button"
                          >
                            <i class="bi bi-ticket-perforated me-1"></i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  </table>
                </div>
                {/* <!-- End Table --> */}
              </div>
              {/* <!-- End Body --> */}
            </div>
            {/* <!-- End Card --> */}
          </div>
        </>
      )}
    </div>
  );
}

export default Dashboard;
