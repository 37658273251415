import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import {
  useAddAdvertisementMutation,
  useGetCreateAdvertisementDataQuery,
} from "./advertisementsApiSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function UpdateAdvertisementModal({ advertisement }) {
  const [title, setTitle] = useState(advertisement.title);
  const [description, setDescription] = useState(advertisement.description);
  const [area, setArea] = useState({ value: advertisement.zone });
  const [areaOptions, setAreaOptions] = useState([
    { label: "En-tête", value: "header" },
  ]);
  const [action, setAction] = useState("");
  const [date, setDate] = useState(new Date());
  const [beginAt, setBeginAt] = useState();
  const [endAt, setEndAt] = useState();
  const [images, setImages] = useState([{ key: nanoid(), value: null }]);

  const [addAdvertisement] = useAddAdvertisementMutation();

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCreateAdvertisementDataQuery();

  useEffect(() => {
    if (isSuccess) {
    }
  }, [isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let files = images.map((image) => image.value);
    addAdvertisement({
      title,
      description,
      zone: area.value,
      action,
      begin_at: beginAt.toLocaleDateString("fr-fr"),
      end_at: endAt.toLocaleDateString("fr-fr"),
      images: files,
    })
      .unwrap()
      .then((response) => {
        if (response?.error?.originalStatus === 500) {
          Swal.fire({
            title: "Erreur",
            text: `Erreur du serveur lors de la modification de la publicité `,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Succees",
            text: `Publicité modifiée avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la modification de la publicité`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });

    setTitle("");
    setDescription("");
    setArea({});
    setAction("");
    setBeginAt(null);
    setEndAt(null);
    setImages([{ key: nanoid(), value: null }]);
  };

  const handleDateChange = (range) => {
    const [beginAt, endAt] = range;
    setBeginAt(beginAt);
    setEndAt(endAt);
  };

  const handleUpload = (file, key) => {
    let imagesCopy = [...images];
    const index = imagesCopy.findIndex((image) => image.key === key);
    imagesCopy[index].value = file;
    setImages(imagesCopy);
  };

  const addImage = () => {
    const imagesCopy = [...images];
    imagesCopy.push({ key: nanoid(), value: "" });
    setImages(imagesCopy);
  };

  const onImageDelete = ({ key }) => {
    if (images.length <= 1) {
      setImages([{ key: nanoid(), value: "" }]);
      return;
    }
    let imagesCopy = [...images];
    const index = imagesCopy.findIndex((image) => image.key === key);
    imagesCopy.splice(index, 1);
    setImages(imagesCopy);
  };

  return (
    <div>
      <button
        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#upateAdvertisementModal${advertisement.id}`}
      >
        <i className="bi bi-pencil"></i>
      </button>

      <div
        className="modal fade"
        id={`upateAdvertisementModal${advertisement.id}`}
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Modifier la publicité
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="modal-body"
            >
              <div>
                {/* <!-- Body --> */}
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-end mt-3">
                      <input
                        type="text"
                        id="createAdvertisementTitle"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="form-control"
                        placeholder="Titre"
                        aria-label="Titre"
                      />
                    </div>

                    <div className="col-md-6 d-flex align-items-end mt-3">
                      <input
                        type="text"
                        id="createAdvertisementDescription"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-control"
                        placeholder="Description"
                        aria-label="Description"
                      />
                    </div>

                    <div className="col-md-6 mt-3">
                      <p>
                        {area.label ??
                          areaOptions.find(
                            (area) => area.value == advertisement.zone
                          )?.label}
                      </p>
                      <Select
                        name="area"
                        options={areaOptions}
                        isClearable
                        isSearchable
                        onChange={setArea}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>

                    <div className="col-md-6 d-flex align-items-end mt-3">
                      <input
                        type="url"
                        id="createAdvertisementAction"
                        value={action}
                        onChange={(e) => setAction(e.target.value)}
                        className="form-control"
                        placeholder="Lien"
                        aria-label="Lien"
                      />
                    </div>

                    <div className="col-md-6 d-flex align-items-end mt-3 ">
                      <DatePicker
                        selected={beginAt}
                        onChange={handleDateChange}
                        startDate={beginAt}
                        endDate={endAt}
                        dateFormat="dd/MM/yyyy"
                        selectsRange
                        placeholderText="Période de validité"
                        className="form-control"
                      />
                    </div>

                    <div className="row">
                      {images.map((image, index) => (
                        <div className="col-md-6 mt-3 d-flex  align-items-end">
                          <div className="">
                            <label
                              htmlFor="mealCategoryImage"
                              className="form-label"
                            >
                              {image?.value?.name ?? "Image"}
                            </label>

                            <input
                              type="file"
                              id="image"
                              value=""
                              onChange={(e) => {
                                handleUpload(e.target.files[0], image.key);
                              }}
                              className="form-control"
                            />
                          </div>

                          <div>
                            <button
                              onClick={() => onImageDelete({ key: image.key })}
                              className="btn btn-ghost-secondary btn-icon rounded-circle"
                              type="button"
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="col-md-3 mt-3 d-flex align-items-end">
                        <button
                          className="btn btn-ghost-secondary btn-icon rounded-circle"
                          type="button"
                          onClick={addImage}
                        >
                          <i className="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>

                    <div className="mt-5 d-flex bd-highlight justify-content-start">
                      <div className="mx-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Modifier
                        </button>
                      </div>

                      <div className="mx-2">
                        <button
                          type="button"
                          className="btn btn-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Update Plan Modal --> */}
    </div>
  );
}

export default UpdateAdvertisementModal;
