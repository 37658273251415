import { apiSlice } from "../../app/api/apiSlice";

const dataForm = ({ name = "", description = "", image = null }) => {
  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("description", description);
  formdata.append("image", image);
  return formdata;
};

const transactionsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Transactions"],
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: ({ url }) => url,
      providesTags: ["Transactions"],
    }),
    getTransaction: builder.query({
      query: ({ transactionId }) => {
        return `transactions/${transactionId}`;
      },
      providesTags: ["Transaction"],
    }),
    getCreateTransactionData: builder.query({
      query: () => "transactions/create",
    }),
    addTransaction: builder.mutation({
      query: (transaction) => ({
        url: "transactions",
        method: "POST",
        body: transaction,
      }),
      invalidatesTags: ["Transactions"],
    }),
    updateTransaction: builder.mutation({
      query: (transaction) => ({
        url: `transactions/${transaction.id}`,
        method: "PUT",
        body: transaction,
      }),
      invalidatesTags: ["Transactions", "Transaction"],
    }),
    deleteTransaction: builder.mutation({
      query: (transactionId) => ({
        url: `transactions/${transactionId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Transactions"],
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useGetTransactionQuery,
  useGetCreateTransactionDataQuery,
  useAddTransactionMutation,
  useUpdateTransactionMutation,
  useDeleteTransactionMutation,
} = transactionsApiSlice;
