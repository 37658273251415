import React, { useEffect, useState } from "react";
import Title from "../../components/Shared/Title";
import { v4 as uuidv4 } from "uuid";
import Geolocation from "../../components/Shared/Geolocation";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import {
  useAddDeliveryFeeMutation,
  useGetCreateDeliveryFeeDataQuery,
  useGetDeliveryFeeQuery,
} from "./deliveryFeeApiSlice";
import Select from "react-select";

function CreateDeliveryFee() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState(null);
  const [ranges, setRanges] = useState([{ key: nanoid() }]);
  const [type, setType] = useState(null);
  const [fixedAmount, setFixedAmount] = useState(null);
  const [firstKmAmount, setFirstKmAmount] = useState(null);
  const [amountByKm, setAmountByKm] = useState(null);

  const [availability, setAvailability] = useState([]);

  const dispatch = useDispatch();
  const [addDeliveryFee] = useAddDeliveryFeeMutation();
  const navigate = useNavigate();

  const createRequest = useGetCreateDeliveryFeeDataQuery();
  const { refetch } = useGetDeliveryFeeQuery();

  useEffect(() => {
    if (createRequest.isSuccess) {
    }
  }, [createRequest.isLoading, createRequest.isSuccess]);

  const switchToggleButton = (slot) => {
    const slot_index = availability.findIndex(
      (element) => element.day === slot.day
    );
    const nextAvailability = [...availability];
    nextAvailability[slot_index].is_available = !slot.is_available;
    setAvailability(nextAvailability);
  };

  // fixed_amount, amount_by_km,
  const handleSubmit = (e) => {
    e.preventDefault();

    // const phones = telephones.map((telephone) => telephone.value);
    let data = { name, description, type: type.value, status: "disable" };
    if (type.value === "fixed") {
      data.fixed_amount = fixedAmount;
    } else if (type.value === "By_Km") {
      data.firstKmAmount = firstKmAmount;
      data.amount_by_km = amountByKm;
    } else if (type.value === "range") {
      data.ranges = ranges.map((range) => ({
        km_min: range.km_min,
        km_max: range.km_max,
        amount: range.amount,
      }));
    }

    addDeliveryFee(data)
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Tarification de livraison créée avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        navigate("/delivery-fee");
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la création de la tarification`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  const onMinKmChange = ({ key, value }) => {
    let rangesCopy = [...ranges];
    const index = rangesCopy.findIndex((range) => range.key === key);
    rangesCopy[index].km_min = value;
    setRanges(rangesCopy);
  };

  const onMaxKmChange = ({ key, value }) => {
    let rangesCopy = [...ranges];
    const index = rangesCopy.findIndex((range) => range.key === key);
    rangesCopy[index].km_max = value;
    setRanges(rangesCopy);
  };

  const onAmountChange = ({ key, value }) => {
    let rangesCopy = [...ranges];
    const index = rangesCopy.findIndex((range) => range.key === key);
    rangesCopy[index].amount = value;
    setRanges(rangesCopy);
  };

  const addRange = () => {
    const rangesCopy = [...ranges];
    rangesCopy.push({
      key: nanoid(),
      km_min: null,
      km_max: null,
      amount: null,
    });
    setRanges(rangesCopy);
  };

  const onRangeDelete = ({ key }) => {
    if (ranges.length <= 1) {
      setRanges([{ key: nanoid(), km_min: 0, km_max: 0, amount: 0 }]);
      return;
    }
    let rangesCopy = [...ranges];
    const index = rangesCopy.findIndex((range) => range.key === key);
    rangesCopy.splice(index, 1);
    setRanges(rangesCopy);
  };

  return (
    <div>
      <Title backPath="/delivery-fee" title="Nouvelle tarification" />
      {/* <!-- Body --> */}
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="row">
          <div className="col-md-3 mt-3 d-flex align-items-end">
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              placeholder="Nom"
              aria-label="Nom"
            />
          </div>

          <div className="col-md-6 mt-3 d-flex align-items-end">
            <input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
              placeholder="Description"
              aria-label="Description"
            />
          </div>

          <div className="col-md-3 mt-3">
            <p>Type : </p>
            <Select
              name="deliveryFeeType"
              options={[
                { label: "Plage", value: "range" },
                { label: "Fixe", value: "fixed" },
                { label: "Par Km", value: "By_Km" },
              ]}
              isClearable
              isSearchable
              onChange={setType}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          {type?.value === "fixed" && (
            <div className="row">
              <div className="col-md-3 mt-3 d-flex align-items-end">
                <div>
                  <p>Montant Fixe</p>
                  <input
                    type="text"
                    id="name"
                    value={fixedAmount}
                    onChange={(e) => setFixedAmount(e.target.value)}
                    className="form-control"
                    placeholder="Montant Fixe"
                    aria-label="Montant Fixe"
                  />
                </div>
              </div>
            </div>
          )}

          {type?.value === "By_Km" && (
            <div className="row">
              <div className="col-md-3 mt-3 d-flex align-items-end">
                <div>
                  <p>
                    Montant 1 <sup> er </sup> Km
                  </p>
                  <input
                    type="number"
                    value={firstKmAmount}
                    onChange={(e) => setFirstKmAmount(e.target.value)}
                    className="form-control"
                    placeholder="Montant Premier Km"
                    aria-label="Montant Premier Km"
                  />
                </div>
              </div>

              <div className="col-md-3 mt-3 d-flex align-items-end">
                <div>
                  <p>Montant par Km</p>
                  <input
                    type="number"
                    value={amountByKm}
                    onChange={(e) => setAmountByKm(e.target.value)}
                    className="form-control"
                    placeholder="Montant par Km"
                    aria-label="Montant par Km"
                  />
                </div>
              </div>
            </div>
          )}

          {type?.value === "range" && (
            <div className="">
              {ranges.map((range, index) => (
                <div className="row">
                  <div className="col-md-4 mt-3 d-flex align-items-end">
                    <div>
                      <p>Km (min)</p>
                      <input
                        type="text"
                        value={range.km_min}
                        onChange={(e) => {
                          onMinKmChange({
                            key: range.key,
                            value: e.target.value,
                          });
                        }}
                        className="form-control"
                        placeholder="Km minimum"
                        aria-label="Km minimum"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mt-3 d-flex align-items-end">
                    <div>
                      <p>Km (max)</p>
                      <input
                        type="text"
                        value={range.km_max}
                        onChange={(e) => {
                          onMaxKmChange({
                            key: range.key,
                            value: e.target.value,
                          });
                        }}
                        className="form-control"
                        placeholder="Km maximum"
                        aria-label="Km maximum"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mt-3 d-flex align-items-end">
                    <div>
                      <p>Montant</p>
                      <input
                        type="text"
                        value={range.amount}
                        onChange={(e) => {
                          onAmountChange({
                            key: range.key,
                            value: e.target.value,
                          });
                        }}
                        className="form-control"
                        placeholder="Montant"
                        aria-label="Montant"
                      />
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          onRangeDelete({ key: range.key });
                        }}
                        className="btn btn-ghost-secondary btn-icon rounded-circle"
                        type="button"
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-3 mt-3">
                <button
                  className="btn btn-ghost-secondary btn-icon rounded-circle"
                  type="button"
                  onClick={addRange}
                >
                  <i className="bi bi-plus"></i>
                </button>
                Ajouter
              </div>
            </div>
          )}
          <div className="mt-5 d-flex bd-highlight justify-content-start">
            <div className="mx-2">
              <button className="btn btn-primary" type="submit">
                Créer
              </button>
            </div>

            <div className="mx-2">
              <Link to="/delivery-fee">
                <button className="btn btn-white" type="button">
                  Annuler
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateDeliveryFee;
