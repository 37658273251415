import React, { useEffect, useState } from "react";

const EditableAvailabilityTable = ({
  initialDays,
  initialAvailability,
  setAvailability,
  canEdit,
}) => {
  const [days, setDays] = useState([]);

  useEffect(() => {
    setDays(initialDays);
  }, [initialDays]);

  useEffect(() => {
    setAvailability(days);
  }, [days]);

  useEffect(() => {
    if (initialAvailability?.length > 0 && initialDays?.length > 0) {
      let initialAvailabilityNumbers = [];
      let formattedAvailabilities = initialAvailability.map((element) => {
        initialAvailabilityNumbers.push(element.day.number);
        return {
          id: element.day.id,
          name: element.day.name,
          number: element.day.number,
          open_hour: element.open_at.split(":")[0],
          open_min: element.open_at.split(":")[1],
          close_hour: element.close_at.split(":")[0],
          close_min: element.close_at.split(":")[1],
          is_available: element.is_available,
        };
      });
      const newDays = initialDays.map((day) => {
        if (initialAvailabilityNumbers.includes(day.number)) {
          const matching = formattedAvailabilities.find(
            (item) => item.number === day.number
          );
          return matching;
        } else {
          return day;
        }
      });
      setDays(newDays);
    }
  }, [initialAvailability, initialDays]);

  const onChangeInput = (e, id) => {
    let { name, value, min, max } = e.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    const editData = days.map((item) =>
      item.id === id && name ? { ...item, [name]: value } : item
    );

    setDays(editData);
  };

  const onChangeAvailability = (id) => {
    const editData = days.map((item) =>
      item.id === id ? { ...item, is_available: !item.is_available } : item
    );
    setDays(editData);
  };

  return (
    <div className="table-responsive datatable-custom">
      <table
        id="datatable"
        className="table table-striped table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
      >
        <thead className="thead-light">
          <tr>
            <th>Jour</th>
            <th>Disponibilité</th>
            <th>Heure (début)</th>
            <th>Minutes (début)</th>
            <th>Heure (fin)</th>
            <th>Minutes (fin)</th>
          </tr>
        </thead>

        <tbody>
          {days?.map(
            ({
              id,
              name,
              open_hour,
              open_min,
              close_hour,
              close_min,
              is_available,
            }) => (
              <tr key={id}>
                <td>{name}</td>
                <td>
                  <div className="form-check">
                    <input
                      disabled={!canEdit}
                      className="form-check-input"
                      type="checkbox"
                      id={`checkbox-${id}`}
                      checked={is_available}
                      onChange={() => onChangeAvailability(id)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`checkbox-${id}`}
                    >
                      {is_available ? "Disponibile" : "Indisponibile "}
                    </label>
                  </div>
                </td>

                <td>
                  <input
                    name="open_hour"
                    value={open_hour}
                    onChange={(e) => onChangeInput(e, id)}
                    placeholder=""
                    size={2}
                    type="number"
                    min="00"
                    max="23"
                    className="text-center border-0"
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                  {" h "}
                </td>
                <td>
                  <input
                    name="open_min"
                    value={open_min}
                    onChange={(e) => onChangeInput(e, id)}
                    placeholder=""
                    size={2}
                    type="number"
                    min="00"
                    max="59"
                    className="text-center border-0"
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                  {" min "}
                </td>
                <td>
                  <input
                    name="close_hour"
                    value={close_hour}
                    onChange={(e) => onChangeInput(e, id)}
                    placeholder=""
                    size={2}
                    type="number"
                    min="00"
                    max="23"
                    className="text-center border-0"
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                  {" h "}
                </td>
                <td>
                  <input
                    name="close_min"
                    value={close_min}
                    onChange={(e) => onChangeInput(e, id)}
                    placeholder=""
                    size={2}
                    type="number"
                    min="00"
                    max="59"
                    className="text-center border-0"
                    onWheel={(e) => e.target.blur()}
                    disabled={!canEdit}
                  />
                  {" min "}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EditableAvailabilityTable;
