import VerticalNavbar from "./VerticalNavbar";
import Main from "./Main";
import KeyboardShortcuts from "./KeyboardShortcuts";
import Activity from "./Activity";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import React from "react";
import ChatNavbar from "../../features/chat/ChatNavbar";

function ChatWrapper() {
  return (
    <>
      <Header />
      <ChatNavbar />
      <Main content={<Outlet />} />
      <KeyboardShortcuts />
      <Activity />
    </>
  );
}

export default ChatWrapper;
