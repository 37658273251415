import React from "react";

import { useField } from "formik";

const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]

  // which we can spread on <input>. We can use field meta to show an error

  // message if the field is invalid and it has been touched (i.e. visited)

  const [field, meta] = useField(props);

  return (
    <div className="w-100">
      <label htmlFor={props.id || props.name}>{label}</label>

      {meta.touched && meta.error ? (
        <div className="error text-danger">{meta.error}</div>
      ) : null}

      <textarea className="text-input" {...field} {...props}></textarea>
    </div>
  );
};

export default TextInput;
