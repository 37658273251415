import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import {
  localDateFormat,
  localPriceFormat,
  stringAvatar,
} from "../../components/Shared/Utils";
import Title from "../../components/Shared/Title";
import { useGetOrderQuery } from "./adminOrdersApiSlice";
import { nanoid } from "@reduxjs/toolkit";
import STATUS from "../../components/Shared/Status";
import RefundOrder from "../restaurants/orders/RefundOrder";

function ReadOrder() {
  const [order, setOrder] = useState({});
  const { orderId } = useParams();
  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetOrderQuery({ orderId });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setOrder(response);
    }
  }, [isLoading, isSuccess]);

  return (
    <>
      <Title
        title={`Commande de ${order?.client?.identity?.fullName}`}
        backPath="/order"
      >
        <p className="text-center">Référence : {order?.reference}</p>
      </Title>

      <div className="content container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="table-responsive datatable-custom">
              <table
                id="datatable"
                className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th>Repas</th>
                    <th>Quantité</th>
                    <th>Montant</th>
                  </tr>
                </thead>

                <tbody>
                  {order?.items?.map((item) => (
                    <tr key={nanoid()}>
                      <td>{item?.meal?.name}</td>
                      <td>{item?.quantity}</td>
                      <td>{localPriceFormat(item.amount, true)} </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="card-footer"></div>
            </div>

            <div className="table-responsive datatable-custom">
              <table
                id="datatable"
                className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th>Boissons</th>
                    <th>Prix unitaire</th>
                    <th>Quantité</th>
                  </tr>
                </thead>

                <tbody>
                  {order?.order_drinks?.map((item) => (
                    <tr key={nanoid()}>
                      <td>{item.name}</td>
                      <td>{localPriceFormat(item.price, true)}</td>
                      <td>{item.pivot.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="card-footer"></div>
            </div>

            <div className="table-responsive datatable-custom">
              <table
                id="datatable"
                className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th>Suppléments</th>
                    <th>Quantité</th>
                    <th>Prix</th>
                    <th>Montant</th>
                  </tr>
                </thead>

                <tbody>
                  {order?.order_supplements?.map((item) => (
                    <tr key={nanoid()}>
                      <td>{item.name}</td>
                      <td>{item.pivot.quantity}</td>
                      <td>{localPriceFormat(item.pivot.price, true)}</td>
                      <td>{localPriceFormat(item.pivot.amount, true)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="card-footer"></div>
            </div>

            <div className="table-responsive datatable-custom">
              <table
                id="datatable"
                className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th>Options</th>
                  </tr>
                </thead>

                <tbody>
                  {order?.order_options?.map((item) => (
                    <tr key={nanoid()}>
                      <td>{item.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="card-footer"></div>
            </div>

            <div className="table-responsive datatable-custom">
              <table
                id="datatable"
                className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th>Bilan</th>
                    <th>Information</th>
                  </tr>
                </thead>

                <tbody>
                  <tr key={nanoid()}>
                    <td>Montant</td>
                    <td>{localPriceFormat(order?.amount, true)}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Frais de livraison</td>
                    <td>{localPriceFormat(order?.delivery_fees, true)}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Code promo</td>
                    <td>{order?.promo_code_id ?? "Aucun"}</td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Promo Restaurant</td>
                    <td>
                      {localPriceFormat(
                        order?.restaurant?.promocodes[0]?.value,
                        true
                      ) ?? "Aucun"}
                    </td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Réductions</td>
                    <td>
                      - {localPriceFormat(order?.promo_code_discount, true)}
                    </td>
                  </tr>
                  <tr key={nanoid()}>
                    <td>Mode de paiement</td>
                    <td>
                      {order?.transaction?.payment_method === "cash"
                        ? "En espèce"
                        : order?.transaction?.payment_method === "wallet"
                        ? "Électronic"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Montant total</td>
                    <td className="fw-bold">
                      {localPriceFormat(order?.total_amount, true)}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="card-footer"></div>
            </div>

            {/* <!-- Card --> */}
            <div class="card">
              {/* <!-- Header --> */}
              <div class="card-header">
                <h4 class="card-header-title">
                  Étapes de la commande {STATUS[order?.status]}
                </h4>
              </div>
              {/* <!-- End Header --> */}

              {/* <!-- Body --> */}
              <div class="card-body">
                {/* <!-- Step --> */}
                <ul class="step step-icon-xs">
                  {/* <!-- Step Item --> */}
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-divider">
                        Traitement de la commande
                      </span>
                    </div>
                  </li>
                  {/* <!-- End Step Item --> */}

                  {/* <!-- Step Item --> */}
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-icon step-icon-soft-dark step-icon-pseudo"></span>

                      <div class="step-content">
                        <h5 class="mb-1">
                          <a class="text-dark" href="#">
                            Commande émise
                          </a>
                        </h5>

                        <p class="fs-6 mb-0">
                          {localDateFormat(order?.treatment?.created_at)}
                        </p>
                      </div>
                    </div>
                  </li>
                  {/* <!-- End Step Item --> */}

                  {/* <!-- Step Item --> */}
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-icon step-icon-soft-dark step-icon-pseudo"></span>

                      <div class="step-content">
                        <h5 class="mb-1">
                          <a class="text-dark" href="#">
                            Commande acceptée par le restaurant
                          </a>
                        </h5>

                        <p class="fs-6 mb-0">
                          {localDateFormat(order?.treatment?.accepted_at)}
                        </p>
                      </div>
                    </div>
                  </li>
                  {/* <!-- End Step Item --> */}

                  {/* <!-- Step Item --> */}
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-icon step-icon-soft-dark step-icon-pseudo"></span>

                      <div class="step-content">
                        <h5 class="mb-1">
                          <a class="text-dark" href="#">
                            En préparation
                          </a>
                        </h5>

                        <p class="fs-6 mb-0">
                          {localDateFormat(
                            order?.treatment?.set_as_processing_at
                          )}
                        </p>
                      </div>
                    </div>
                  </li>
                  {/* <!-- End Step Item --> */}

                  {/* <!-- Step Item --> */}
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-icon step-icon-soft-dark step-icon-pseudo"></span>

                      <div class="step-content">
                        <h5 class="mb-1">
                          <a class="text-dark" href="#">
                            Préparé
                          </a>
                        </h5>

                        <p class="fs-6 mb-0">
                          {localDateFormat(order?.treatment?.set_as_ready_at)}
                        </p>
                      </div>
                    </div>
                  </li>
                  {/* <!-- End Step Item --> */}

                  {/* <!-- Step Item --> */}
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-icon step-icon-soft-dark step-icon-pseudo"></span>

                      <div class="step-content">
                        <h5 class="mb-1">
                          <a class="text-dark" href="#">
                            Acceptée par le livreur
                          </a>
                        </h5>

                        <p class="fs-6 mb-0">
                          {localDateFormat(
                            order?.treatment?.delivery_accepted_at
                          )}
                        </p>
                      </div>
                    </div>
                  </li>
                  {/* <!-- End Step Item --> */}

                  {/* <!-- Step Item --> */}
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-icon step-icon-soft-dark step-icon-pseudo"></span>

                      <div class="step-content">
                        <h5 class="mb-1">
                          <a class="text-dark" href="#">
                            Récupérée
                          </a>
                        </h5>

                        <p class="fs-6 mb-0">
                          {localDateFormat(order?.treatment?.recupered_at)}
                        </p>
                      </div>
                    </div>
                  </li>
                  {/* <!-- End Step Item --> */}
                </ul>
                {/* <!-- End Step --> */}

                <span class="small">Étapes de la commande</span>
              </div>
              {/* <!-- End Body --> */}
            </div>
            {/* <!-- End Card --> */}
          </div>
          <div class="col-lg-4">
            {/* <!-- Card --> */}
            <div class="card">
              {/* <!-- Header --> */}
              <div class="card-header">
                <h4 class="card-header-title">Client</h4>
              </div>
              {/* <!-- End Header --> */}

              {/* <!-- Body --> */}
              <div class="card-body">
                {/* <!-- List Group --> */}
                <ul class="list-group list-group-flush list-group-no-gutters">
                  <li class="list-group-item">
                    <span class="d-flex align-items-center">
                      <div class="avatar avatar-circle">
                        <Avatar
                          alt=" "
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/svg/logos/logo.svg"
                          }
                        />
                      </div>
                      <div class="flex-grow-1 ms-3">
                        <span class="text-body text-inherit">
                          {order?.client?.identity?.fullName}
                        </span>
                      </div>
                    </span>
                  </li>

                  <li class="list-group-item">
                    <span class="d-flex align-items-center">
                      <div class="icon icon-soft-info icon-circle">
                        <i class="bi-basket"></i>
                      </div>
                      <div class="flex-grow-1 ms-3">
                        <span class="text-body text-inherit">
                          {order?.items?.length} repas
                        </span>
                      </div>
                    </span>
                  </li>

                  <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5>Info</h5>
                    </div>

                    <ul class="list-unstyled list-py-2 text-body">
                      <li>
                        <i class="bi-phone me-2"></i>
                        {order?.client?.identity?.telephone}
                      </li>
                    </ul>
                  </li>

                  <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5>Adresse de livraison</h5>
                    </div>

                    <span class="d-block text-body">
                      Lieu: {order?.delivery_address?.name}
                    </span>

                    <span class="d-block text-body">
                      Latitude: {order?.delivery_address?.latitude}
                    </span>

                    <span class="d-block text-body">
                      Longitude: {order?.delivery_address?.longitude}
                    </span>
                  </li>
                </ul>
                {/* <!-- End List Group --> */}
              </div>
              {/* <!-- End Body --> */}
            </div>
            {/* <!-- End Card --> */}

            <div class="card mt-5">
              {/* <!-- Header --> */}
              <div class="card-header">
                <h4 class="card-header-title">Livreur</h4>
              </div>
              {/* <!-- End Header --> */}

              {/* <!-- Body --> */}
              <div class="card-body">
                {/* <!-- List Group --> */}
                <ul class="list-group list-group-flush list-group-no-gutters">
                  <li class="list-group-item">
                    <span class="d-flex align-items-center">
                      <div class="avatar avatar-circle">
                        <Avatar
                          alt=" "
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/svg/logos/logo.svg"
                          }
                        />
                      </div>
                      <div class="flex-grow-1 ms-3">
                        <span class="text-body text-inherit">
                          {order?.treatment?.deliverer?.email}
                        </span>
                      </div>
                    </span>
                  </li>

                  <li class="list-group-item d-none">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5>Info</h5>
                    </div>

                    <ul class="list-unstyled list-py-2 text-body">
                      <li>
                        <i class="bi-phone me-2"></i>
                        {order?.treatment?.deliverer?.email}
                      </li>
                    </ul>
                  </li>
                </ul>
                {/* <!-- End List Group --> */}
              </div>
              {/* <!-- End Body --> */}
            </div>
            {/* <!-- End Card --> */}

            <div class="card mt-5">
              {/* <!-- Header --> */}
              <div class="card-header">
                <h4 class="card-header-title">Restaurant</h4>
              </div>
              {/* <!-- End Header --> */}

              {/* <!-- Body --> */}
              <div class="card-body">
                {/* <!-- List Group --> */}
                <ul class="list-group list-group-flush list-group-no-gutters">
                  <li class="list-group-item">
                    <span class="d-flex align-items-center">
                      <div class="avatar avatar-circle">
                        <Avatar alt=" " src={order.restaurant?.logo_url} />
                      </div>

                      <p>{order.restaurant?.name}</p>
                      <div class="flex-grow-1 ms-3">
                        <span class="text-body text-inherit">
                          {/* {order?.client?.identity?.fullName} */}
                        </span>
                      </div>
                    </span>
                  </li>

                  <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5>Info</h5>
                    </div>

                    <ul class="list-unstyled list-py-2 text-body">
                      <li>
                        <i class="bi-phone me-2"></i>
                        {order?.restaurant?.telephones.join(", ")}
                      </li>
                    </ul>
                  </li>
                </ul>
                {/* <!-- End List Group --> */}
              </div>
              {/* <!-- End Body --> */}
            </div>
            {/* <!-- End Card --> */}

            <div class="card mt-5">
              {/* <!-- Header --> */}
              <div class="card-header">
                <h4 class="card-header-title">Actions</h4>
              </div>
              {/* <!-- End Header --> */}

              {/* <!-- Body --> */}
              <div class="card-body">
                {/* <!-- List Group --> */}
                <ul class="list-group list-group-flush list-group-no-gutters">
                  <li class="list-group-item">
                    <span class="d-flex align-items-center">
                      <p>
                        <RefundOrder order={order} />
                      </p>

                      <p>Rembourser</p>
                      <div class="flex-grow-1 ms-3">
                        <span class="text-body text-inherit">
                          {/* {order?.client?.identity?.fullName} */}
                        </span>
                      </div>
                    </span>
                  </li>
                </ul>
                {/* <!-- End List Group --> */}
              </div>
              {/* <!-- End Body --> */}
            </div>
            {/* <!-- End Card --> */}
          </div>
        </div>
      </div>

      {/* <div className="card mb-3 mb-lg-5"> */}
      {/* <!-- Card --> */}
      {/* <!-- Table --> */}
      {/* <!-- End Table --> */}

      {/* <!-- Footer --> */}

      {/* </div> */}
    </>
  );
}

export default ReadOrder;
