import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
// import { onMessageListener, requestPermission } from "../../firebase";
// import { getMessaging, onMessage } from "firebase/messaging";

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });

  // useEffect(() => {
  //   requestPermission();

  //   const unsubscribe = onMessageListener()?.then((payload) => {
  //     setNotification({
  //       title: payload?.notification?.title,
  //       body: payload?.notification?.body,
  //     });
  //   });
  //   return () => {
  //     unsubscribe?.catch((err) => console.log("failed", err));
  //   };
  // }, []);

  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.onBackgroundMessage` handler.

  // const messaging = getMessaging();
  // onMessage(messaging, (payload) => {
  //   alert("Message");
  //   console.log("Message received. ", payload);
  //   // ...
  // });
  return (
    <div>
      <Toaster />
    </div>
  );
};

export default Notification;
