import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import Select from "react-select";
import { selectDataTableItems } from "../../dataTable/dataTableSlice";
import {
  useAddRestaurantMutation,
  useAddRestaurantPromotionMutation,
  useGetCreateRestaurantPromotionDataQuery,
  useRefundOrderMutation,
} from "../restaurantsApiSlice";
import { selectSelectedRestaurant } from "../restaurantSlice";
import { selectRoles } from "../../auth/authSlice";
import { localPriceFormat } from "../../../components/Shared/Utils";

function RefundOrder({ order, id }) {
  const [orderItems, setOrderItems] = useState({
    meals: [],
    drinks: [],
    supplements: [],
    deliveryFees: {},
  });
  const [addPromotion] = useAddRestaurantPromotionMutation();
  const navigate = useNavigate();
  const [refundOrder] = useRefundOrderMutation();

  useEffect(() => {
    // orderItems,order_drinks
    let tempItems = { ...orderItems };
    for (let i = 0; i < order?.items?.length; i++) {
      tempItems.meals.push({
        id: order?.items[i]?.id,
        name: order?.items[i]?.meal?.name,
        quantity: order?.items[i]?.quantity,
        amount: order?.items[i]?.amount,
        canRefund: false,
      });
    }

    for (let i = 0; i < order?.order_drinks?.length; i++) {
      tempItems.drinks.push({
        id: order?.order_drinks[i]?.id,
        name: order?.order_drinks[i]?.name,
        quantity: order?.order_drinks[i]?.pivot?.quantity,
        price: order?.order_drinks[i]?.price,
        canRefund: false,
      });
    }

    for (let i = 0; i < order?.order_supplements?.length; i++) {
      tempItems.supplements.push({
        id: order?.order_supplements[i]?.id,
        name: order?.order_supplements[i]?.name,
        quantity: order?.order_supplements[i]?.pivot?.quantity,
        price: order?.order_supplements[i]?.pivot?.price,
        canRefund: false,
      });
    }

    tempItems.deliveryFees = {
      name: "Frais de livraison",
      amount: order.delivery_fees,
      key: nanoid(),
      canRefund: false,
    };

    setOrderItems(tempItems);
  }, [order]);

  // useEffect(() => {
  //   if (createRequest.isSuccess) {
  //     const { cible, type, value_type, validity, meals } = createRequest.data;
  //     setCibleOptions(cible);
  //     setCalculationBasisOptions(cible);
  //     setTypeOptions(type);
  //     setValueTypeOptions(value_type);
  //     setValidityOptions(validity);
  //     const formattedMealOptions = meals.map((meal) => ({
  //       label: meal.name,
  //       value: meal.id,
  //     }));
  //     setMealOptions(formattedMealOptions);
  //   }
  // }, [createRequest.isLoading, createRequest.isSuccess]);

  // useEffect(() => {
  //   const formattedRestaurants = restaurants.map((restaurant) => ({
  //     label: restaurant.name,
  //     value: restaurant.id,
  //   }));
  //   setCustomerOptions(formattedRestaurants);
  // }, [restaurants]);

  // useEffect(() => {
  //   setSelectedRestaurants(
  //     dataTableItems.filter((item) => item.isSelected === true)
  //   );
  // }, [dataTableItems]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = {};
    if (orderItems.deliveryFees.canRefund) {
      data.deliveryFees = orderItems.deliveryFees;
    }
    data.drinks = orderItems.drinks.filter((item) => item.canRefund === true);
    data.meals = orderItems.meals.filter((item) => item.canRefund === true);
    data.supplements = orderItems.supplements.filter(
      (item) => item.canRefund === true
    );
    data.orderId = order.id;

    console.log("DATA", data);

    refundOrder(data)
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Remboursement effectué avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors du remboursement`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  const handleRefundMealChange = (key) => {
    let orderItemsCopy = { ...orderItems };
    orderItemsCopy.meals = orderItemsCopy.meals.map((item) => {
      if (item.id === key) {
        item.canRefund = !item.canRefund;
      }
      return item;
    });
    setOrderItems(orderItemsCopy);
  };

  const handleRefundDrinkChange = (key) => {
    let orderItemsCopy = { ...orderItems };
    orderItemsCopy.drinks = orderItemsCopy.drinks.map((item) => {
      if (item.id === key) {
        item.canRefund = !item.canRefund;
      }
      return item;
    });
    setOrderItems(orderItemsCopy);
  };

  const handleRefundSupplementChange = (key) => {
    let orderItemsCopy = { ...orderItems };
    orderItemsCopy.supplements = orderItemsCopy.supplements.map((item) => {
      if (item.id === key) {
        item.canRefund = !item.canRefund;
      }
      return item;
    });
    setOrderItems(orderItemsCopy);
  };

  const handleRefundDeliveryFeeChange = () => {
    let orderItemsCopy = { ...orderItems };
    orderItemsCopy.deliveryFees.canRefund =
      !orderItemsCopy.deliveryFees.canRefund;
    setOrderItems(orderItemsCopy);
  };

  return (
    <div className="m-4">
      {/* btn-ghost-secondary */}
      <button
        className="btn btn-lg btn-ghost-secondary border rounded-circle"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#refund${order.id}`}
      >
        <i className="bi bi-cash-stack"></i>
      </button>

      <div
        className="modal fade"
        id={`refund${order.id}`}
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Cliquer les éléments à rembourser
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <form onSubmit={handleSubmit} className="modal-body">
              <div className="row">
                {orderItems.meals.map((order) => {
                  return (
                    <div className="col-md-6 mt-3" key={order.id}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={order.canRefund}
                          id={order.id}
                          onChange={() => handleRefundMealChange(order.id)}
                        />
                        <label className="form-label" htmlFor={order.id}>
                          {order.name}({order.quantity}) :
                          {localPriceFormat(order.amount, true)}
                        </label>
                      </div>
                    </div>
                  );
                })}

                {orderItems.drinks.map((order) => {
                  return (
                    <div className="col-md-6 mt-3" key={order.id}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={order.canRefund}
                          id={order.id}
                          onChange={() => handleRefundDrinkChange(order.id)}
                        />
                        <label className="form-label" htmlFor={order.id}>
                          {order.name}({order.quantity}x
                          {localPriceFormat(order.price, true)})
                        </label>
                      </div>
                    </div>
                  );
                })}

                {orderItems.supplements.map((order) => {
                  return (
                    <div className="col-md-6 mt-3" key={order.id}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={order.canRefund}
                          id={order.id}
                          onChange={() =>
                            handleRefundSupplementChange(order.id)
                          }
                        />
                        <label className="form-label" htmlFor={order.id}>
                          {order.name}({order.quantity}x
                          {localPriceFormat(order.price, true)})
                        </label>
                      </div>
                    </div>
                  );
                })}

                <div
                  className="col-md-6 mt-3"
                  key={orderItems.deliveryFees.key}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={orderItems.deliveryFees.canRefund}
                      id={orderItems.deliveryFees.key}
                      onChange={() => handleRefundDeliveryFeeChange()}
                    />
                    <label
                      className="form-label"
                      htmlFor={orderItems.deliveryFees.key}
                    >
                      {orderItems.deliveryFees.name} (
                      {localPriceFormat(order.delivery_fees, true)})
                    </label>
                  </div>
                </div>
              </div>
              {/* {localPriceFormat(order?.delivery_fees, true)} */}
              <div className="mt-5 d-flex bd-highlight justify-content-start">
                <div className="mx-2">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    data-bs-dismiss="modal"
                  >
                    Rembourser
                  </button>
                </div>

                <div className="mx-2">
                  <button
                    className="btn btn-white"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundOrder;
