import React, { useEffect, useState } from "react";
import Title from "../../components/Shared/Title";
import { v4 as uuidv4 } from "uuid";
import Geolocation from "../../components/Shared/Geolocation";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetRestaurantQuery,
  useGetCreateRestaurantDataQuery,
  useUpdateRestaurantMutation,
  useGetRestaurantsQuery,
} from "./restaurantsApiSlice";
import { useDispatch } from "react-redux";
import { setCreateRestaurantData } from "./restaurantSlice";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import EditableAvailabilityTable from "./EditableAvailabilityTable";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import Input from "../../components/formik/Input";
import PhoneInput from "react-phone-input-2";

function UpdateRestaurant() {
  const [canUseCurrentLocation, setCanUseCurrentLocation] = useState(false);
  const [name, setName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [telephones, setTelephones] = useState([]);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");

  const [days, setDays] = useState([]);
  const [categories, setCategories] = useState(null);

  const [availability, setAvailability] = useState([]);
  const [initialAvailability, setInitialAvailability] = useState([]);
  const [logo, setLogo] = useState(null);
  const [banner, setBanner] = useState(null);

  const { restaurantId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetRestaurantQuery({ restaurantId });

  const getRestaurantsQuery = useGetRestaurantsQuery();

  useEffect(() => {
    refetch();
    if (isSuccess) {
      let phones = [];
      if (response.telephones === null) {
        phones = [
          {
            key: nanoid(),
            value: "",
          },
        ];
      } else {
        phones = response.telephones?.map((phone) => ({
          key: nanoid(),
          value: phone,
        }));
      }

      setName(response.name);
      setTelephones(phones);
      setAddress(response.address);
      setInitialAvailability(response.availabilities);
      setLongitude(response.longitude);
      setLatitude(response.latitude);
    }
  }, [isLoading, isSuccess]);

  const [updateRestaurant] = useUpdateRestaurantMutation();
  const createRequest = useGetCreateRestaurantDataQuery();

  useEffect(() => {
    if (createRequest.isSuccess) {
      // dispatch set create restaurant data
      const { days, categories } = createRequest.data;
      dispatch(setCreateRestaurantData({ days, categories }));
      setDays(days);
      setCategories(categories);

      let formatedDays = days.map((element) => ({
        id: element.id,
        name: element.name,
        number: element.number,
        open_hour: "00",
        open_min: "00",
        close_hour: "23",
        close_min: "59",
        is_available: true,
      }));
      formatedDays.sort((a, b) => (a.number > b.number ? 1 : -1));
      setDays(formatedDays);
    }
  }, [createRequest.isLoading, createRequest.isSuccess]);

  const switchToggleButton = (slot) => {
    const slot_index = availability.findIndex(
      (element) => element.day === slot.day
    );
    const nextAvailability = [...availability];
    nextAvailability[slot_index].is_available = !slot.is_available;
    setAvailability(nextAvailability);
  };

  const handleCoords = (coords) => {
    setLongitude(coords?.longitude);
    setLatitude(coords?.latitude);
  };

  const handleSubmit = (data) => {
    const phones = telephones?.map((telephone) => telephone.value);
    data = {
      ...data,
      id: restaurantId,
      telephones: phones,
      longitude,
      latitude,
      _method: "PUT",
      logo: logo,
      banner: banner,
    };

    data.days = availability.map((item) => ({
      id: item.id,
      is_available: item.is_available,
      open_at: item.open_hour + ":" + item.open_min,
      close_at: item.close_hour + ":" + item.close_min,
    }));

    // update restaurant
    updateRestaurant(data)
      .unwrap()
      .then(() => {
        // getRestaurantsQuery.refetch();
        Swal.fire({
          title: "Succees",
          text: `Restaurant modifié avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        navigate("/restaurant");
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la modification du restaurant`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  const onTelephoneChange = ({ key, value }) => {
    let telephonesCopy = [...telephones];
    const index = telephonesCopy.findIndex(
      (telephone) => telephone.key === key
    );
    telephonesCopy[index].value = value;
    setTelephones(telephonesCopy);
  };

  const addTelephone = () => {
    const telephonesCopy = [...telephones];
    telephonesCopy.push({ key: nanoid(), value: "" });
    setTelephones(telephonesCopy);
  };

  const onTelephoneDelete = ({ key }) => {
    if (telephones.length <= 1) {
      setTelephones([{ key: nanoid(), value: "" }]);
      return;
    }
    let telephonesCopy = [...telephones];
    const index = telephonesCopy.findIndex(
      (telephone) => telephone.key === key
    );
    telephonesCopy.splice(index, 1);
    setTelephones(telephonesCopy);
  };

  return (
    <div>
      <Title title="Modifier le restaurant" backPath="/restaurant" />
      {/* <!-- Body --> */}
      <div className="container-fluid">
        <Formik
          enableReinitialize
          initialValues={{ name, address }}
          validationSchema={Yup.object({
            name: Yup.string().required("Obligatoire"),
            address: Yup.string().required("Obligatoire"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit({ ...values });
            setSubmitting(false);
            resetForm();
          }}
        >
          <Form className="row">
            <div className="col-md-3">
              <p className="fw-bold text-center">Informations</p>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <Input
                    label="Nom"
                    name="name"
                    type="text"
                    placeholder="Nom"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <Input
                    label="Adresse"
                    name="address"
                    type="text"
                    placeholder="Adresse"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                {telephones.map((telephone, index) => (
                  <div className="col-md-12 mt-3 d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <label>Téléphone</label>
                      <PhoneInput
                        country={"bj"}
                        enableSearch={false}
                        onlyCountries={["bj"]}
                        masks={{ bj: "..  ..  ..  .." }}
                        disableCountryCode={true}
                        disableDropdown={true}
                        value={telephone.value}
                        onChange={(value) =>
                          onTelephoneChange({
                            key: telephone.key,
                            value,
                          })
                        }
                        inputClass="w-100 p-3"
                        buttonClass="d-none"
                        containerClass=""
                        placeholder="97  00  00  00"
                      />
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          onTelephoneDelete({ key: telephone.key })
                        }
                        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                        type="button"
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </div>
                  </div>
                ))}
                <div className="col-md-12 mt-3">
                  <button
                    className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                    type="button"
                    onClick={addTelephone}
                  >
                    <i className="bi bi-plus"></i>
                  </button>
                  Ajouter numéro
                </div>
              </div>
            </div>

            <div className="col-md-9">
              <p className="fw-bold text-center">Disponibilité</p>
              <EditableAvailabilityTable
                initialDays={days}
                initialAvailability={initialAvailability}
                setAvailability={setAvailability}
                canEdit={true}
              />
            </div>

            <div className="row">
              <div className="col-md-6">
                <label className="form-label">Logo</label>

                <input
                  type="file"
                  value=""
                  onChange={(e) => {
                    setLogo(e.target.files[0]);
                  }}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Bannière</label>

                <input
                  type="file"
                  value=""
                  onChange={(e) => {
                    setBanner(e.target.files[0]);
                  }}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="mt-5 d-flex bd-highlight justify-content-start">
                <div className="mr-2">
                  <button className="btn btn-primary" type="submit">
                    Modifier
                  </button>
                </div>

                <div className="mx-2">
                  <Link to="/restaurant">
                    <button className="btn btn-white" type="button">
                      Annuler
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default UpdateRestaurant;
