import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  useAcceptRestaurantOrderMutation,
  useProcessRestaurantOrderMutation,
  useReadyRestaurantOrderMutation,
} from "../restaurantsApiSlice";
import {
  ACCEPTED,
  PENDING,
  PROCESSING,
} from "../../../components/Shared/Constants";

function UpdateOrderStatusModal({ order }) {
  const [acceptRestaurantOrder] = useAcceptRestaurantOrderMutation();
  const [processRestaurantOrder] = useProcessRestaurantOrderMutation();
  const [readyRestaurantOrder] = useReadyRestaurantOrderMutation();
  const {
    id: orderId,
    accepted_at,
    set_as_ready_at,
    set_as_processing_at,
    set_as_ready_by_id,
    status,
  } = order;

  const handleAccept = () => {
    acceptRestaurantOrder({ order_id: orderId })
      .unwrap()
      .then((response) => {
        if (response?.error?.originalStatus === 500) {
          Swal.fire({
            title: "Erreur",
            text: `Erreur de l'acceptation de la commande`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Succees",
            text: `Acceptation de la commande`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de l'acceptation de la commande`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  const handleProcessing = () => {
    processRestaurantOrder({ order_id: orderId })
      .unwrap()
      .then((response) => {
        if (response?.error?.originalStatus === 500) {
          Swal.fire({
            title: "Erreur",
            text: `Erreur pour informer que le repas est en cours de préparation`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Succees",
            text: `Repas en cours de preparation`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur pour informer que le repas est en cours de préparation`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  const handleReady = () => {
    readyRestaurantOrder({ order_id: orderId })
      .unwrap()
      .then((response) => {
        if (response?.error?.originalStatus === 500) {
          Swal.fire({
            title: "Erreur",
            text: `Erreur pour informer que le repas est prêt`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Succees",
            text: `Informer que le repas est prêt`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur pour informer que le repas est prêt`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#createMealCategoryModal${orderId}`}
        title="Metrre a jour le statut"
      >
        <i className="bi bi-arrow-down-up  fs-3"></i>
      </button>

      <div
        className="modal fade"
        id={`createMealCategoryModal${orderId}`}
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Mettre à jour le statut de la commande
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <div className="modal-body">
              <div className="container-fluid">
                <form
                  className="row"
                  encType="multipart/form-data"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>Informer que la commande est :</div>
                  <div className="mt-5 d-flex bd-highlight justify-content-start">
                    {status === PENDING && (
                      <div className="mx-2">
                        <button
                          onClick={handleAccept}
                          className="btn btn-white"
                          type="submit"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Accepter
                        </button>
                      </div>
                    )}

                    {status === ACCEPTED && (
                      <div className="mx-2">
                        <button
                          onClick={handleProcessing}
                          className="btn btn-white"
                          type="submit"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          En preparation
                        </button>
                      </div>
                    )}

                    {status === PROCESSING && (
                      <div className="mx-2">
                        <button
                          onClick={handleReady}
                          className="btn btn-white"
                          type="submit"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Préparée
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateOrderStatusModal;
