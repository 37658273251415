import React, { useEffect, useState } from "react";
import Title from "../../components/Shared/Title";
import { v4 as uuidv4 } from "uuid";
import Geolocation from "../../components/Shared/Geolocation";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import Select from "react-select";
import { selectRoles } from "../auth/authSlice";
import { selectSelectedRestaurant } from "../restaurants/restaurantSlice";
import {
  useAddMealPromotionMutation,
  useGetCreateMealPromotionDataQuery,
} from "./mealsApiSlice";
import { selectDataTableItems } from "../dataTable/dataTableSlice";

function CreateMealPromotion({ meals, setCanSelect }) {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState(null);
  const [typeOptions, setTypeOptions] = useState(null);
  const [value, setValue] = useState(null);
  const [maxBeneficiaries, setMaxBeneficiaries] = useState(null);
  const [valueType, setValueType] = useState(null);
  const [valueTypeOptions, setValueTypeOptions] = useState(null);
  const [calculationBasis, setCalculationBasis] = useState(null);
  const [calculationBasisOptions, setCalculationBasisOptions] = useState(null);
  const [cible, setCible] = useState(null);
  const [cibleOptions, setCibleOptions] = useState(null);
  const [validity, setValidity] = useState(null);
  const [validityOptions, setValidityOptions] = useState(null);
  const [mealOptions, setMealOptions] = useState(null);
  const [maxUsage, setMaxUsage] = useState(1);
  const [cummulative, setCummulative] = useState(null);
  const [applicate, setApplicate] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);

  const [addPromotion] = useAddMealPromotionMutation();
  const navigate = useNavigate();

  const selectedRestaurant = useSelector(selectSelectedRestaurant);
  const dataTableItems = useSelector(selectDataTableItems);
  const createRequest = useGetCreateMealPromotionDataQuery();

  useEffect(() => {
    if (createRequest.isSuccess) {
      const { cible, type, value_type, validity, meals } = createRequest.data;
      setCibleOptions(cible);
      setCalculationBasisOptions(cible);
      setTypeOptions(type);
      setValueTypeOptions(value_type);
      setValidityOptions(validity);
      const formattedMealOptions = meals.map((meal) => ({
        label: meal.name,
        value: meal.id,
      }));
      setMealOptions(formattedMealOptions);
    }
  }, [createRequest.isLoading, createRequest.isSuccess]);

  useEffect(() => {
    const formattedMeals = meals.map((meal) => ({
      label: meal.name,
      value: meal.id,
    }));
    setCustomerOptions(formattedMeals);
  }, [meals]);

  useEffect(() => {
    setSelectedMeals(dataTableItems.filter((item) => item.isSelected === true));
  }, [dataTableItems]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const collectedData = {
      name,
      code,
      value_type: valueType.value,
      value,
      maximum_beneficiaries: selectedMeals.length,
      // calculation_basis: calculationBasis.value,
      max_usage: maxUsage,
      applicate: "meals",
      restaurant_id: selectedRestaurant?.id,
      cummulative,
      status: "valid",
      meals: selectedMeals.map((meal) => meal.id),
    };

    console.log("PROMO DATA ", collectedData);

    setCanSelect(false);

    addPromotion(collectedData)
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Code promo créé avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la création du code promo`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  return (
    <div className="m-4">
      {/* btn-ghost-secondary */}
      <button
        className="btn btn-lg btn-ghost-secondary border"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#createMealPromoModal"
      >
        <i className="bi bi-plus"></i>
        Promo Repas
      </button>

      <div
        className="modal fade"
        id="createMealPromoModal"
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Nouvelle promotion pour repas
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <form onSubmit={handleSubmit} className="modal-body">
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="form-label">Nom</label>
                  <input
                    label="Nom"
                    name="name"
                    type="text"
                    placeholder="Nom"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Code Promo</label>
                  <input
                    label="Code"
                    name="code"
                    type="text"
                    placeholder="Code"
                    className="form-control"
                    onChange={(e) => setCode(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Valeur</label>
                  <input
                    label="Valeur"
                    name="value"
                    type="number"
                    placeholder="Valeur"
                    className="form-control"
                    onChange={(e) => setValue(e.target.value)}
                    required
                    min={1}
                  />
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Type de valeur</label>
                  <Select
                    name="valueType"
                    options={valueTypeOptions}
                    isClearable
                    isSearchable
                    onChange={setValueType}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    required
                  />
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Nbr. max usage</label>
                  <input
                    label="Nbr. max usage"
                    name="maxUsage"
                    type="number"
                    min={1}
                    placeholder="Nbr. max usage"
                    className="form-control"
                    onChange={(e) => setMaxUsage(e.target.value)}
                    required
                  />
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Cummulable (n) fois</label>
                  <input
                    label="Cummulable (n) fois"
                    name="cummulative"
                    type="number"
                    min={0}
                    placeholder="Cummulable (n) fois"
                    className="form-control"
                    onChange={(e) => setCummulative(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="mt-5 d-flex bd-highlight justify-content-start">
                <div className="mx-2">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    data-bs-dismiss="modal"
                  >
                    Créer
                  </button>
                </div>

                <div className="mx-2">
                  <button
                    className="btn btn-white"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateMealPromotion;
