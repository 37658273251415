import React, { useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import { Link } from "react-router-dom";
import {
  useGetPromotionsQuery,
  useGetRestaurantsQuery,
} from "./promotionsApiSlice";
import DeletePromotionModal from "./DeletePromotionModal";
import UpdatePromotionStatusModal from "./UpdatePromotionStatusModal";
import STATUS from "../../components/Shared/Status";

function PromotionList() {
  const [baseUrl, setBaseUrl] = useState("promo-codes");
  const [url, setUrl] = useState(baseUrl);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetPromotionsQuery({ url });

  const columns = [
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
    {
      name: "Statut",
      selector: "status",
      type: "status",
    },
  ];

  let data = [];
  let meta = {};

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((promo) => ({
      id: promo.id,
      name: promo.name,
      status: promo.status,
      address: promo.address,
    }));

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (promotion) => {
    return (
      <div className="fw-bold d-flex">
        <Link to={`/promotion/${promotion.id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
            type="button"
          >
            <i className="bi bi-eye fs-3"></i>
          </button>
        </Link>

        <Link to={`/promotion/edit/${promotion.id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
            type="button"
          >
            <i className="bi bi-pencil fs-3"></i>
          </button>
        </Link>
        <UpdatePromotionStatusModal promotionId={promotion.id} />
        <DeletePromotionModal promotion={promotion} />
      </div>
    );
  };

  return (
    <div className="container">
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        baseUrl={baseUrl}
        setUrl={setUrl}
        meta={meta}
      />
    </div>
  );
}

export default PromotionList;
