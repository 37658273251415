import React, { useState } from "react";
import { useAddTagMutation, useGetTagsQuery } from "./tagsApiSlice";
import Swal from "sweetalert2";
import Title from "../../components/Shared/Title";

function CreateZone() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [addTag] = useAddTagMutation();
  const { refetch } = useGetTagsQuery();

  const handleSubmit = (e) => {
    e.preventDefault();

    addTag({ name, description })
      .unwrap()
      .then((response) => {
        console.log("CREATE TAG", response);
        if (response?.error?.originalStatus === 500) {
          Swal.fire({
            title: "Erreur",
            text: `Erreur du serveur lors de la création du tag`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          refetch();
          Swal.fire({
            title: "Succees",
            text: `Tag créé avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la création du tag`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });

    setName("");
    setDescription("");
  };

  return (
    <div>
      <Title backPath="/restaurant" title="Nouvelle zone" />
      {/* <!-- Body --> */}
      <div className="container-fluid">
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="row"
        >
          <div>
            {/* <!-- Body --> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12 d-flex align-items-end mt-3">
                    <input
                      type="text"
                      id="createRestaurantCategoryName"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      placeholder="Nom de la zone"
                      aria-label="Nom de la zone"
                    />
                  </div>

                  <div className="col-md-12 mt-3">
                    <input
                      type="text"
                      id="createRestaurantCategoryDescription"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="form-control"
                      placeholder="Description de la zone"
                      aria-label="Description de la zone"
                    />
                  </div>
                </div>
                {/* Map */}
                <div className="col-md-6">
                  <h6>Map</h6>
                </div>
                <div className="mt-5 d-flex bd-highlight justify-content-start">
                  <div className="mx-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Créer
                    </button>
                  </div>

                  <div className="mx-2">
                    <button
                      type="button"
                      className="btn btn-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateZone;
