import React, { useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import ReadMealCategoryModal from "../../components/MealCategory/crud/ReadMealCategoryModal";
import UpdateMealCategoryModal from "./UpdateMealCategoryModal";
import { useGetMealCategoriesQuery } from "./mealCategoriesApiSlice";
import DeleteMealCategoryModal from "./DeleteMealCategoryModal";

function MealCategoriesList({ setTitle }) {
  const [baseUrl, setBaseUrl] = useState("meal-categories");
  const [url, setUrl] = useState(baseUrl);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetMealCategoriesQuery({ url });

  const columns = [
    {
      name: "",
      selector: "image",
      sortable: true,
      type: "image",
    },
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
    },
  ];

  let data = [];
  let meta = {};

  let content;
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((mealCategory) => ({
      id: mealCategory.id,
      name: mealCategory.name,
      description: mealCategory.description,
      image: mealCategory.image.imageUrl,
    }));

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
    content = "";
  } else if (isError) {
    content = <p> {JSON.stringify(error)} </p>;
  }

  const actions = (mealCategory) => {
    return (
      <div className="fw-bold d-flex">
        <ReadMealCategoryModal mealCategory={mealCategory} />
        <UpdateMealCategoryModal mealCategory={mealCategory} />
        <DeleteMealCategoryModal mealCategoryId={mealCategory.id} />
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        baseUrl={baseUrl}
        setUrl={setUrl}
        meta={meta}
      />
    </div>
  );
}

export default MealCategoriesList;
