import React from "react";
import Swal from "sweetalert2";
import { useDeleteOptionMutation } from "./optionsApiSlice";

function DeleteOptionModal({ option }) {
  const [deleteSupplement] = useDeleteOptionMutation();

  async function displayConfirmation() {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Voulez-vous vraiment supprimer ce ${option.name} ?`,
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isDeleteConfirmed = swal.isConfirmed;

    if (isDeleteConfirmed) {
      deleteSupplement(option.id)
        .unwrap()
        .then(() => {
          Swal.fire({
            title: "Succees",
            text: `${option.name} supprimée avec succès`,
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((response) => {
          Swal.fire({
            title: "Erreur",
            text: `Erreur lors de la suppression de ${option.name}`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    }
  }
  return (
    <div>
      <button
        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
        type="button"
        onClick={displayConfirmation}
      >
        <i className="bi bi-trash"></i>
      </button>
    </div>
  );
}

export default DeleteOptionModal;
