import React, { useCallback, useEffect, useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import { Link } from "react-router-dom";
import { useGetRestaurantsQuery } from "./restaurantsApiSlice";
import DeleteRestaurantModal from "./DeleteRestaurantModal";
import ValidateRestaurantModal from "./ValidateRestaurantModal";
import CreateRestaurantPromotion from "./CreateRestaurantPromotion";
import debounce from "lodash.debounce";

function RestaurantList() {
  const [baseUrl, setBaseUrl] = useState("restaurants");
  const [url, setUrl] = useState(baseUrl);
  const [canSelect, setCanSelect] = useState(false);
  const [search, setSearch] = useState("");

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetRestaurantsQuery({ url });

  const debouncedSetUrl = useCallback(
    debounce((search, baseUrl) => setUrl(baseUrl + "?search=" + search), 500),
    []
  );

  useEffect(() => {
    debouncedSetUrl(search, baseUrl);
  }, [search, baseUrl]);

  const columns = [
    {
      name: "Logo",
      selector: "logo",
      sortable: true,
      type: "image",
    },
    {
      name: "Bannière",
      selector: "banner",
      sortable: true,
      type: "image",
    },
    {
      name: "Nom",
      selector: "name",
      sortable: true,
    },
    {
      name: "Statut",
      selector: "status",
      type: "status",
    },
    {
      name: "Adresse",
      selector: "address",
    },
  ];

  let data = [];
  let meta = {};

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.data.map((restaurant) => ({
      id: restaurant.id,
      name: restaurant.name,
      status: restaurant.status,
      address: restaurant.address,
      validated_by: restaurant.validated_by,
      logo: restaurant.logo_url,
      banner: restaurant.banner_url,
    }));

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (restaurant) => {
    return (
      <div className="fw-bold d-flex">
        {!restaurant.validated_by && (
          <ValidateRestaurantModal restaurant={restaurant} />
        )}
        <Link to={`/restaurant/${restaurant.id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
            type="button"
            title="Consulter le détail"
          >
            <i className="bi bi-eye fs-3"></i>
          </button>
        </Link>

        <Link to={`/restaurant/edit/${restaurant.id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
            type="button"
            title="Modifier"
          >
            <i className="bi bi-pencil fs-3"></i>
          </button>
        </Link>
        <DeleteRestaurantModal restaurant={restaurant} />
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid d-flex justify-content-end bd-highlight">
        <div className="m-4">
          <input
            type="search"
            className="js-form-search form-control"
            placeholder="Rechercher"
            aria-label="Rechercher"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="m-4">
          <Link to="/restaurant/create">
            <button
              className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg d-none"
              type="button"
            >
              <i className="bi bi-plus fs-3"></i>
            </button>
            <button
              className="btn btn-lg btn-ghost-secondary border"
              type="button"
            >
              <i className="bi-building"></i>
              Créer Restaurant
            </button>
          </Link>
        </div>

        <div className="m-4">
          <button
            className="btn btn-lg btn-ghost-secondary border"
            type="button"
            onClick={() => setCanSelect(!canSelect)}
          >
            Sélectionner
          </button>
        </div>

        <CreateRestaurantPromotion
          restaurants={data}
          setCanSelect={setCanSelect}
        />
      </div>
      <div className="container">
        <DataTable
          columns={columns}
          data={data}
          actions={actions}
          baseUrl={baseUrl}
          setUrl={setUrl}
          meta={meta}
          canSelect={canSelect}
        />
      </div>
    </>
  );
}

export default RestaurantList;
