function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function chunk(str, n) {
  if (str == null) {
    return [];
  }
  var ret = [];
  var i;
  var len;
  var modulo = str.length % n;
  ret.push(str.substring(0, modulo));

  for (i = modulo, len = str.length; i < len; i += n) {
    ret.push(str.substring(i, n + i));
  }

  return ret;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

function localDateFormat(date) {
  let x = new Date(date);
  return isNaN(x)
    ? "Aucune date"
    : x?.toLocaleDateString(["ban", "id"]) +
        " " +
        x?.toLocaleTimeString("it-IT");
}

function localPriceFormat(value, currency = false) {
  return chunk(value?.toString(), 3).join(" ") + (currency ? " Fcfa" : "");
}

export { stringAvatar, localDateFormat, localPriceFormat };
