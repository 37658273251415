import React from "react";
import Swal from "sweetalert2";
import { useDeleteDrinkCategoryMutation } from "./drinkCategoriesApiSlice";

function DeleteDrinkCategoryModal({ drinkCategory }) {
  const [deleteDrinkCategory] = useDeleteDrinkCategoryMutation();

  async function displayConfirmation() {
    const swal = await Swal.fire({
      title: "Confirmation",
      text: `Voulez-vous vraiment supprimer  ${drinkCategory.name} ?`,
      icon: "question",
      confirmButtonText: "Oui",
      denyButtonText: "Non",
      showDenyButton: true,
    });
    const isDeleteConfirmed = swal.isConfirmed;

    if (isDeleteConfirmed) {
      deleteDrinkCategory(drinkCategory.id)
        .unwrap()
        .then((response) => {
          if (response?.error?.originalStatus === 500) {
            Swal.fire({
              title: "Erreur",
              text: `Erreur du serveur lors de la suppresison de la catégorie de boisson`,
              icon: "error",
              toast: true,
              position: "top-end",
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              title: "Succees",
              text: `Catégorie de boisson supprimée avec succès`,
              icon: "success",
              toast: true,
              position: "top-end",
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((error) => {
          console.log("DELETE DRINK CATEGORY MODAL ERROR", error);
          Swal.fire({
            title: "Erreur",
            text: `(${drinkCategory.name}): ${error.data.message}`,
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 15000,
            timerProgressBar: true,
          });
        });
    }
  }

  return (
    <div>
      <button
        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
        type="button"
        onClick={displayConfirmation}
      >
        <i className="bi bi-trash fs-3"></i>
      </button>
    </div>
  );
}

export default DeleteDrinkCategoryModal;
