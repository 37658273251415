import React from "react";
import { Link, NavLink } from "react-router-dom";
import RequireAuth from "../../features/auth/RequireAuth";
import { useSelector } from "react-redux";
import { selectRoles } from "../../features/auth/authSlice";

function VerticalNavbar() {
  const currentRoles = useSelector(selectRoles);
  const currentRolesNames = currentRoles?.map((role) => role.name);

  return (
    <aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white">
      <div className="navbar-vertical-container">
        <div className="navbar-vertical-footer-offset">
          {/*Logo*/}
          <Link className="navbar-brand" to="/" aria-label="Front">
            <img
              className=""
              // navbar-brand-logo img-fluid
              src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.png"}
              alt="Logo"
              data-hs-theme-appearance="default"
              style={{ height: "4rem", width: "6rem" }}
            />
            <img
              className="navbar-brand-logo"
              src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.png"}
              alt="Logo"
              data-hs-theme-appearance="dark"
            />
            <img
              className="navbar-brand-logo-mini"
              src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.png"}
              alt="Logo"
              data-hs-theme-appearance="default"
            />
            <img
              className="navbar-brand-logo-mini"
              src={process.env.PUBLIC_URL + "/assets/svg/logos/logo.png"}
              alt="Logo"
              data-hs-theme-appearance="dark"
            />
          </Link>

          {/* End Logo */}

          {/* Navbar Vertical Toggle  */}
          <button
            type="button"
            className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
          >
            <i
              className="bi-arrow-bar-left navbar-toggler-short-align"
              data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Réduire"
            ></i>
            <i
              className="bi-arrow-bar-right navbar-toggler-full-align"
              data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Ouvrir"
            ></i>
          </button>

          {/* End Navbar Vertical Toggle */}

          {/* Content */}
          <div className="navbar-vertical-content">
            <div
              id="navbarVerticalMenu"
              className="nav nav-pills nav-vertical card-navbar-nav"
            >
              {/* item: Tableau de bord */}
              {["admin", "restaurant-admin", "delivery-company-admin"].some(
                (role) => currentRolesNames?.includes(role)
              ) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/dashboard"
                  >
                    <i className="bi bi-house nav-icon"></i>
                    <span className="nav-link-title">Tableau de bord</span>
                  </NavLink>
                </div>
              )}

              {/* item: Commandes */}
              {["admin", "restaurant-admin", "delivery-company-admin"].some(
                (role) => currentRolesNames?.includes(role)
              ) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/order"
                  >
                    <i className="bi bi-clipboard nav-icon"></i>
                    <span className="nav-link-title">Commandes</span>
                  </NavLink>
                </div>
              )}

              {/* item: Gestion des Restaurants */}
              {["admin"].some((role) => currentRolesNames?.includes(role)) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbarVerticalMenuRestaurant"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarVerticalMenuRestaurant"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuRestaurant"
                  >
                    <i className="bi-building nav-icon"></i>
                    <span className="nav-link-title">
                      Gestion des Restaurants
                    </span>
                  </a>

                  <div
                    id="navbarVerticalMenuRestaurant"
                    className="nav-collapse collapse"
                    data-bs-parent="#navbarVerticalMenu"
                  >
                    {/* link: Restaurants */}
                    {["admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/restaurant"
                      >
                        <i className="bi bi-list-ol nav-icon"></i>
                        <span className="nav-link-title">Restaurants</span>
                      </NavLink>
                    )}

                    {/* link: Catégories Restaurants */}
                    {["admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/restaurant-category"
                      >
                        <i className="bi bi-tag nav-icon"></i>
                        <span className="nav-link-title">
                          Catégories Restaurants
                        </span>
                      </NavLink>
                    )}

                    {/* link: Gestionnaires */}
                    {["admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/restaurant-staff"
                      >
                        <i className="bi bi-people nav-icon"></i>
                        <span className="nav-link-title">Gestionnaires</span>
                      </NavLink>
                    )}
                  </div>
                </div>
              )}

              {/* item: Gestion des Repas */}
              {["admin", "restaurant-admin"].some((role) =>
                currentRolesNames?.includes(role)
              ) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbarVerticalMenuMeal"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarVerticalMenuMeal"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuMeal"
                  >
                    <i className="bi-egg-fried nav-icon"></i>
                    <span className="nav-link-title">Gestion des Repas</span>
                  </a>

                  <div
                    id="navbarVerticalMenuMeal"
                    className="nav-collapse collapse"
                    data-bs-parent="#navbarVerticalMenu"
                  >
                    {/* link: Repas */}
                    {["admin", "restaurant-admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/meal"
                      >
                        <i className="bi bi-list-ol nav-icon"></i>
                        <span className="nav-link-title">Repas</span>
                      </NavLink>
                    )}

                    {/* link: Categories repas */}
                    {["admin", "restaurant-admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/meal-category"
                      >
                        <i className="bi bi-tag nav-icon"></i>
                        <span className="nav-link-title">Categories repas</span>
                      </NavLink>
                    )}

                    {/* link: Boissons */}
                    {["admin", "restaurant-admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/drink"
                      >
                        <i className="bi bi-cup-straw nav-icon"></i>
                        <span className="nav-link-title">Boissons</span>
                      </NavLink>
                    )}

                    {/* link: Categories boissons */}
                    {["admin", "restaurant-admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/drink-category"
                      >
                        <i className="bi bi-tag nav-icon"></i>
                        <span className="nav-link-title">
                          Categories boissons
                        </span>
                      </NavLink>
                    )}

                    {/* link: Suppléments */}
                    {["admin", "restaurant-admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/supplement"
                      >
                        <i className="bi bi-plus-square nav-icon"></i>
                        <span className="nav-link-title">Suppléments</span>
                      </NavLink>
                    )}

                    {/* link: Options */}
                    {["admin", "restaurant-admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/option"
                      >
                        <i className="bi bi-option nav-icon"></i>
                        <span className="nav-link-title">Options</span>
                      </NavLink>
                    )}
                  </div>
                </div>
              )}

              {/* item: Compagnies de Livraison */}
              {["admin"].some((role) => currentRolesNames?.includes(role)) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/delivery-company"
                  >
                    <i className="bi bi-bicycle nav-icon"></i>
                    <span className="nav-link-title">
                      Compagnies de Livraison
                    </span>
                  </NavLink>
                </div>
              )}

              {/* link: tags */}
              {["admin", "restaurant-admin"].some((role) =>
                currentRolesNames?.includes(role)
              ) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/tag"
                  >
                    <i className="bi bi-tags nav-icon"></i>
                    <span className="nav-link-title">Tags</span>
                  </NavLink>
                </div>
              )}

              {/* item: Comptes */}
              {["admin", "delivery-company-admin"].some((role) =>
                currentRolesNames?.includes(role)
              ) && (
                <div className="nav-item">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbarVerticalMenuCustomer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarVerticalMenuCustomer"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuCustomer"
                  >
                    <i className="bi bi-people nav-icon"></i>
                    <span className="nav-link-title">Comptes</span>
                  </a>

                  <div
                    id="navbarVerticalMenuCustomer"
                    className="nav-collapse collapse"
                    data-bs-parent="#navbarVerticalMenu"
                  >
                    {/* link: Clients */}
                    {["admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/customer"
                      >
                        <i className="bi bi-person nav-icon"></i>
                        <span className="nav-link-title">Clients</span>
                      </NavLink>
                    )}

                    {/* link: Livreurs */}
                    {["admin", "delivery-company-admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/delivery-person"
                      >
                        <i className="bi bi-bicycle nav-icon"></i>
                        <span className="nav-link-title">Livreurs</span>
                      </NavLink>
                    )}

                    {/* link: Staff */}
                    {["admin"].some((role) =>
                      currentRolesNames?.includes(role)
                    ) && (
                      <NavLink
                        className={({ isActive }) => {
                          return "nav-link" + (isActive ? " active" : "");
                        }}
                        to="/staff"
                      >
                        <i className="bi bi-briefcase nav-icon"></i>
                        <span className="nav-link-title">Staff</span>
                      </NavLink>
                    )}
                  </div>
                </div>
              )}

              {/* item: Messagerie
              {["admin", "restaurant-admin"].some((role) =>
                currentRolesNames?.includes(role)
              ) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/chat/messenger"
                  >
                    <i className="bi bi-chat-dots nav-icon"></i>
                    <span className="nav-link-title">Messagerie</span>
                  </NavLink>
                </div>
              )} */}

              {/* item: Codes promo */}
              {["admin", "restaurant-admin"].some((role) =>
                currentRolesNames?.includes(role)
              ) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/promotion"
                  >
                    <i className="bi bi-ticket-perforated nav-icon"></i>
                    <span className="nav-link-title">Codes promo</span>
                  </NavLink>
                </div>
              )}

              {/* item: Pubs */}
              {["admin", "restaurant-admin"].some((role) =>
                currentRolesNames?.includes(role)
              ) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/advertisement"
                  >
                    <i className="bi bi-asterisk nav-icon"></i>
                    <span className="nav-link-title">Pubs</span>
                  </NavLink>
                </div>
              )}

              {/* item: Zones-Localisations */}
              {["admin", "delivery-company-admin"].some((role) =>
                currentRolesNames?.includes(role)
              ) && (
                <div className="nav-item d-none">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbarVerticalMenuGeolocation"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarVerticalMenuGeolocation"
                    aria-expanded="false"
                    aria-controls="navbarVerticalMenuGeolocation"
                  >
                    <i className="bi bi-geo-alt nav-icon"></i>
                    <span className="nav-link-title">Zones-Localisations</span>
                  </a>

                  <div
                    id="navbarVerticalMenuGeolocation"
                    className="nav-collapse collapse"
                    data-bs-parent="#navbarVerticalMenu"
                  >
                    <NavLink
                      className={({ isActive }) => {
                        return "nav-link" + (isActive ? " active" : "");
                      }}
                      to="/zone"
                    >
                      <i className="bi bi-pin-map nav-icon"></i>
                      <span className="nav-link-title">Zones</span>
                    </NavLink>
                  </div>
                </div>
              )}

              {/* item: Notifications */}
              {/* {["admin"].some((role) => currentRolesNames?.includes(role)) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/notif-templates"
                  >
                    <i className="bi bi-bell nav-icon"></i>
                    <span className="nav-link-title">Notifications</span>
                  </NavLink>
                </div>
              )} */}

              {/* item: Rubrics */}
              {["admin"].some((role) => currentRolesNames?.includes(role)) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/rubric"
                  >
                    <i className="bi bi-wallet nav-icon"></i>
                    <span className="nav-link-title">Rubriques</span>
                  </NavLink>
                </div>
              )}

              {/* item: Recharges */}
              {["admin"].some((role) => currentRolesNames?.includes(role)) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/recharge"
                  >
                    <i className="bi bi-wallet nav-icon"></i>
                    <span className="nav-link-title">Recharges</span>
                  </NavLink>
                </div>
              )}

              {/* item: Transactions */}
              {["admin"].some((role) => currentRolesNames?.includes(role)) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/transaction"
                  >
                    <i className="bi bi-arrow-left-right nav-icon"></i>
                    <span className="nav-link-title">Transactions</span>
                  </NavLink>
                </div>
              )}

              {/* item: Refund
              {["admin"].some((role) => currentRolesNames?.includes(role)) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/refund"
                  >
                    <i className="bi bi-arrow-left-right nav-icon"></i>
                    <span className="nav-link-title">Remboursements</span>
                  </NavLink>
                </div>
              )} */}

              {/* item: Configurations */}
              {["admin"].some((role) => currentRolesNames?.includes(role)) && (
                <div className="nav-item">
                  <NavLink
                    className={({ isActive }) => {
                      return "nav-link" + (isActive ? " active" : "");
                    }}
                    to="/settings"
                  >
                    <i className="bi bi-gear nav-icon"></i>
                    <span className="nav-link-title">Configurations</span>
                  </NavLink>
                </div>
              )}
            </div>
          </div>
          {/* End Content */}

          {/* Footer */}
          <div className="navbar-vertical-footer"></div>
          {/* End Footer */}
        </div>
      </div>
    </aside>
  );
}

export default VerticalNavbar;
