import Title from "../components/Shared/Title";
import PartnersList from "../features/partners/PartnersList";
import React from "react";

function Partner() {
  return (
    <>
      <Title title="Partenaires" />
      <div className="container-fluid d-flex flex-row-reverse bd-highlight"></div>
      <PartnersList />
    </>
  );
}

export default Partner;
