import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Wrapper from "./components/Shared/Wrapper";
import Restaurant from "./pages/Restaurant";
import Login from "./features/auth/Login/Login";
import DeliveryPerson from "./pages/DeliveryPerson";
import Meal from "./pages/Meal";
import Customer from "./pages/Customer";
import Order from "./pages/Order";
import Notification from "./pages/Notification";
import Location from "./pages/Location";
import Advertisement from "./pages/Advertisement";
import Promotion from "./pages/Promotion";
import Commission from "./pages/Commission";
import MealCategory from "./pages/MealCategory";
import Partner from "./pages/Partner";
import Staff from "./pages/Staff";
import DeliveryFee from "./pages/DeliveryFee";
import Setting from "./pages/Setting";
import ReadDeliveryPerson from "./components/DeliveryPerson/crud/ReadDeliveryPerson";
import EmailVerification from "./pages/EmailVerification";
import RestaurantCategory from "./pages/RestaurantCategory";
import Drink from "./pages/Drink";
import Supplement from "./pages/Supplement";
import UpdateCustomer from "./components/Customer/crud/UpdateCustomer";
import UpdateDeliveryPerson from "./components/DeliveryPerson/crud/UpdateDeliveryPerson";
import ReadStaff from "./features/staff/ReadStaff";
import RequireAuth from "./features/auth/RequireAuth";
import ReadRestaurant from "./features/restaurants/ReadRestaurant";
import UpdateRestaurant from "./features/restaurants/UpdateRestaurant";
import CreateRestaurant from "./features/restaurants/CreateRestaurant";
import CreateStaff from "./features/staff/CreateStaff";
import RestaurantStaff from "./pages/RestaurantStaff";
import Invitation from "./features/auth/Login/Invitation";
import ReadRestaurantStaff from "./features/restaurantStaff/ReadRestaurantStaff";
import Zone from "./pages/Zone";
import Tag from "./pages/Tag";
import CreateZone from "./features/zones/CreateZone";
import DrinkCategory from "./pages/DrinkCategory";
import ReadPromotion from "./features/promotions/ReadPromotion";
import CreatePromotion from "./features/promotions/CreatePromotion";
import UpdatePromotion from "./features/promotions/UpdatePromotion";
import CreateMeal from "./features/meals/CreateMeal";
import ReadMeal from "./features/meals/ReadMeal";
import UpdateMeal from "./features/meals/UpdateMeal";
import CreateDeliveryFee from "./features/deliveryFee/CreateDeliveryFee";
import ReadDeliveryFee from "./features/deliveryFee/ReadDeliveryFee";
import CreateDeliveryPerson from "./features/deliveryPerson/CreateDeliveryPerson";
import Recharge from "./pages/Recharge";
import Option from "./pages/Option";
import React from "react";
import Dashboard from "./features/auth/Login/Dashboard";
import ReadOrder from "./features/admin/ReadOrder";
import RequireRestaurantSelection from "./features/restaurants/RequireRestaurantSelection";
import ReadRecharge from "./features/recharges/ReadRecharge";
import NotifTemplates from "./pages/Config/NotifTemplates";
import ReadCustomer from "./features/customer/ReadCustomer";
import DeliveryCompany from "./pages/DeliveryCompany";
import ReadDeliveryCompany from "./features/deliveryCompany/ReadDeliveryCompany";
import CreateDeliveryCompany from "./features/deliveryCompany/CreateDeliveryCompany";
import ForgottenPassword from "./features/auth/Login/ForgottenPassword";
import ResetPassword from "./features/auth/Login/ResetPassword";
import ResetPasswordByOtp from "./features/auth/Login/ResetPasswordByOtp";
import Rubric from "./pages/Rubric";
import MetaData from "./pages/MetaData";
import CreateMetaData from "./features/metaData/CreateMetaData";
import Transaction from "./pages/Transaction";
import ChatWrapper from "./components/Shared/ChatWrapper";
import Chat from "./pages/Chat";
import UpdateDeliveryFee from "./features/deliveryFee/UpdateDeliveryFee";
import UpdateNotifTemplate from "./features/notif-templates/UpdateNotifTemplateModal";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route
          path="/delivery-company-registration"
          element={<DeliveryCompany />}
        /> */}
        <Route path="/invitation/:token" element={<Invitation />} />
        <Route path="/password/reset/:token" element={<ResetPassword />} />
        <Route
          path="/otp/password-reset/:username"
          element={<ResetPasswordByOtp />}
        />
        <Route path="/forgotten-password" element={<ForgottenPassword />} />
        <Route path="chat" element={<ChatWrapper />}>
          <Route element={<RequireAuth />}>
            <Route path="messenger/:userId?" element={<Chat />} />
          </Route>
        </Route>
        <Route path="/" element={<Wrapper />}>
          <Route element={<RequireAuth on="page" />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="tag/" element={<Tag />} />
            <Route path="zone/" element={<Zone />} />
            <Route path="zone/create" element={<CreateZone />} />
            <Route path="meal-category/" element={<MealCategory />} />
            <Route path="restaurant" element={<Restaurant />} />
            <Route path="wallet" element={<Restaurant />} />
            <Route path="rubric" element={<Rubric />} />
            <Route path="recharge" element={<Recharge />} />
            <Route path="recharge/:rechargeId" element={<ReadRecharge />} />
            <Route path="transaction" element={<Transaction />} />
            <Route
              path="/delivery-company/create"
              element={<CreateDeliveryCompany />}
            />
            <Route
              path="/delivery-company/:deliveryCompanyId"
              element={<ReadDeliveryCompany />}
            />
            <Route path="/delivery-company" element={<DeliveryCompany />} />
            <Route
              path="restaurant-category"
              element={<RestaurantCategory />}
            />

            <Route path="restaurant/create" element={<CreateRestaurant />} />
            <Route
              path="restaurant/edit/:restaurantId"
              element={<UpdateRestaurant />}
            />
            <Route
              path="restaurant/:restaurantId"
              element={<ReadRestaurant />}
            />
            <Route
              path="restaurant-staff/:restaurantStaffId"
              element={<ReadRestaurantStaff />}
            />
            <Route path="restaurant-staff" element={<RestaurantStaff />} />

            <Route path="delivery-person/" element={<DeliveryPerson />} />
            <Route
              path="delivery-person/create"
              element={<CreateDeliveryPerson />}
            />
            <Route
              path="delivery-person/:delivery_person_id"
              element={<ReadDeliveryPerson />}
            />
            <Route
              path="delivery-person/edit/:delivery_person_id"
              element={<UpdateDeliveryPerson />}
            />
            <Route path="delivery-fee/create" element={<CreateDeliveryFee />} />
            <Route
              path="delivery-fee/:deliveryFeeId"
              element={<ReadDeliveryFee />}
            />
            <Route
              path="delivery-fee/edit/:deliveryFeeId"
              element={<UpdateDeliveryFee />}
            />
            <Route path="delivery-fee/" element={<DeliveryFee />} />
            <Route path="meta-data/create" element={<CreateMetaData />} />
            <Route path="meta-data/" element={<MetaData />} />
            <Route path="meal/" element={<Meal />} />
            <Route path="meal/edit/:mealId" element={<UpdateMeal />} />
            <Route path="meal/:mealId" element={<ReadMeal />} />
            <Route path="meal/create" element={<CreateMeal />} />
            <Route path="drink/" element={<Drink />} />
            <Route path="drink-category/" element={<DrinkCategory />} />
            <Route path="supplement/" element={<Supplement />} />
            <Route path="option/" element={<Option />} />
            <Route
              path="customer/edit/:customer_id"
              element={<UpdateCustomer />}
            />
            <Route path="customer/:customerId" element={<ReadCustomer />} />
            <Route path="customer/" element={<Customer />} />
            <Route path="order/" element={<Order />} />
            <Route path="order/:orderId" element={<ReadOrder />} />
            <Route path="notification/" element={<Notification />} />
            <Route path="location/" element={<Location />} />
            <Route path="advertisement/" element={<Advertisement />} />
            <Route path="promotion/" element={<Promotion />} />
            <Route path="promotion/:promotionId" element={<ReadPromotion />} />
            <Route path="promotion/create" element={<CreatePromotion />} />
            <Route
              path="promotion/edit/:promotionId"
              element={<UpdatePromotion />}
            />
            <Route path="commission/" element={<Commission />} />
            <Route path="partner/" element={<Partner />} />
            <Route path="staff/" element={<Staff />} />
            <Route path="staff/create" element={<CreateStaff />} />
            <Route path="staff/:staffId" element={<ReadStaff />} />
            <Route path="settings/" element={<Setting />} />
            <Route path="notif-templates" element={<NotifTemplates />} />
            <Route
              path="notif-template/edit/:notifTemplateId"
              element={<UpdateNotifTemplate />}
            />
            <Route path="dashboard/" element={<Dashboard />} />
            <Route path="*" element={<p>Dashboards</p>} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
