import React from "react";
import { useParams } from "react-router-dom";
import Title from "../../Shared/Title";
import { stringAvatar } from "../../Shared/Utils";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";

function ReadDeliveryPerson() {
  const { delivery_person_id } = useParams();
  return (
    <>
      <Title
        title={`Livreur ${delivery_person_id}`}
        backPath="/delivery-person"
      >
        <div className="d-flex justify-content-center">
          <Avatar {...stringAvatar("Bob .")} />
        </div>

        <div className="d-flex justify-content-center mt-3">
          <Rating
            name="half-rating-read"
            defaultValue={2.5}
            precision={0.5}
            readOnly
            size="large"
          />
        </div>
      </Title>

      <div className="row">
        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      508
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Livraisons</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      2
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Livraisons en attente</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      498
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Livraisons effectuées</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      451
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Notes reçues</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      8
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Plaintes</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      5
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Années d'Ancienneté</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}

        {/* <!-- Card --> */}
        <div className="col-lg-3 mb-3 mb-lg-5">
          <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                      className="text-inherit avatar avatar-lg avatar-4x3"
                      style={{ minHeight: "5rem", fontSize: "5vh" }}
                      data-hs-theme-appearance="default"
                    >
                      100
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Paiements Cash</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <!-- End Card --> */}
      </div>

      <div className="content container-fluid">
        <div className="row">
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Nom</h6>
                <h3 className="card-title">Pizzaria</h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Téléphone</h6>
                <h3 className="card-title">97879568</h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Adresse</h6>
                <h3 className="card-title">Abomey</h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Statut</h6>
                <h3 className="card-title">Approuvé</h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Affiliation</h6>
                <h3 className="card-title">Partenaire 7</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReadDeliveryPerson;
