import { apiSlice } from "../../app/api/apiSlice";

const staffApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Staff"],
  endpoints: (builder) => ({
    getAllStaff: builder.query({
      query: () => "staff",
      providesTags: ["Staff"],
    }),
    getStaff: builder.query({
      query: ({ staffId }) => {
        return `staff/${staffId}`;
      },
    }),
    getCreateStaffData: builder.query({
      query: () => "staff/create",
    }),
    addStaff: builder.mutation({
      query: (staff) => ({
        url: "staff",
        method: "POST",
        body: { ...staff },
        redirect: "error",
      }),
      invalidatesTags: ["Staff"],
    }),
    updateStaff: builder.mutation({
      query: (staff) => ({
        url: `staff/${staff.id}`,
        method: "PUT",
        body: staff,
      }),
      invalidatesTags: ["Staff"],
    }),
    deleteStaff: builder.mutation({
      query: (staffId) => ({
        url: `staff/${staffId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Staff"],
    }),
  }),
});

export const {
  useGetAllStaffQuery,
  useGetStaffQuery,
  useGetCreateStaffDataQuery,
  useAddStaffMutation,
  useUpdateStaffMutation,
  useDeleteStaffMutation,
} = staffApiSlice;
