import Title from "../components/Shared/Title";
import { useState } from "react";
import MealCategoriesList from "../features/mealCategories/MealCategoriesList";
import { v4 as uuidv4 } from "uuid";
import CreateMealCategoryModal from "../features/mealCategories/CreateMealCategoryModal";
import React from "react";
import useRedirectUserIfNoRestaurantSelected from "../hooks/restaurants/useRedirectUserIfNoRestaurantSelected";

function MealCategory() {
  const [statistics, setStatistics] = useState([
    { quantity: 3, description: "Total Catégories" },
    { quantity: 2, description: "Catégories Actives" },
    { quantity: 90, description: "Catégories Vides" },
    { quantity: 5, description: "Catégories Visibles" },
  ]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  useRedirectUserIfNoRestaurantSelected();

  return (
    <>
      <Title title="Catégories de repas" />

      <div className="row d-none">
        {statistics.map((stat) => (
          <div key={uuidv4()} className="col-lg-3 mb-3 mb-lg-5">
            {/* <!-- Card --> */}
            <div className="d-grid gap-2 gap-lg-4">
              <span className="card card-hover-shadow">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <span
                        className="text-inherit avatar avatar-lg avatar-4x3 d-flex"
                        style={{ minHeight: "5rem", fontSize: "5vh" }}
                        data-hs-theme-appearance="default"
                      >
                        {stat.quantity}
                      </span>
                    </div>

                    <div className="flex-grow-1 ms-4">
                      <h3 className="text-inherit mb-1 text-center"></h3>
                      <span className="text-body">{stat.description}</span>
                    </div>

                    <div className="ms-2 text-end">
                      <i className="d-none bi-chevron-right text-body text-inherit"></i>
                    </div>
                  </div>
                </div>
              </span>
            </div>
            {/* <!-- End Card --> */}
          </div>
        ))}
      </div>

      <div className="container-fluid mt-5 mb-5">
        <p>Liste des catégories de repas</p>
      </div>

      <div className="container-fluid d-flex justify-content-end bd-highlight">
        <CreateMealCategoryModal />
      </div>

      <MealCategoriesList />
    </>
  );
}

export default MealCategory;
