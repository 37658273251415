import { apiSlice } from "../../app/api/apiSlice";

const customerApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Customers", "Promotions"],
  endpoints: (builder) => ({
    getAllCustomers: builder.query({
      query: ({ url }) => url,
      providesTags: ["Customers"],
    }),
    getCustomer: builder.query({
      query: ({ customerId }) => {
        return `clients/${customerId}`;
      },
    }),
    getCreateCustomerData: builder.query({
      query: () => "clients/create",
    }),
    addCustomer: builder.mutation({
      query: (customer) => ({
        url: "clients",
        method: "POST",
        body: { ...customer },
        redirect: "error",
      }),
      invalidatesTags: ["Customers"],
    }),
    updateCustomer: builder.mutation({
      query: (customer) => ({
        url: `clients/${customer.id}`,
        method: "PUT",
        body: customer,
      }),
      invalidatesTags: ["Customers"],
    }),
    deleteCustomer: builder.mutation({
      query: (customerId) => ({
        url: `clients/${customerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Customers"],
    }),

    // Customer promotion
    getCreateCustomerPromotionData: builder.query({
      query: () => "promo-codes/create",
    }),
    addCustomerPromotion: builder.mutation({
      query: (promotion) => ({
        url: "promo-codes",
        method: "POST",
        body: promotion,
      }),
      invalidatesTags: ["Promotions"],
    }),
  }),
});

export const {
  useGetAllCustomersQuery,
  useGetCustomerQuery,
  useGetCreateCustomerDataQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,

  // Customer promotion
  useGetCreateCustomerPromotionDataQuery,
  useAddCustomerPromotionMutation,
} = customerApiSlice;
