import React from 'react';
import {Link} from "react-router-dom";

export default function ConfigLink({title, icon, link}) {
    return (
        <div className="col-lg-3 mb-3 mb-lg-5">
            <div className="d-grid gap-2 gap-lg-4">
                <Link className="card card-hover-shadow" to={link}>
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <span
                                    className="text-inherit avatar avatar-lg avatar-4x3"
                                    style={{minHeight: "5rem", fontSize: "5vh"}}
                                    data-hs-theme-appearance="default"
                                >
                                  <i className={'bi' + icon}></i>
                                </span>
                            </div>

                            <div className="flex-grow-1 ms-4">
                                <h3 className="text-inherit mb-1 text-center"></h3>
                                <span className="text-body">
                                    {title}
                                </span>
                            </div>

                            <div className="ms-2 text-end">
                                <i className="d-none bi-chevron-right text-body text-inherit"></i>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}
