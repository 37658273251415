import React, { useEffect, useState } from "react";
import Title from "../../components/Shared/Title";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  useAddDeliveryPersonMutation,
  useGetCreateDeliveryPersonDataQuery,
} from "./deliveryPersonApiSlice";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Input from "../../components/formik/Input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const animatedComponent = makeAnimated();

function CreateDeliveryPerson() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState(null);
  const [options, setOptions] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createRequest = useGetCreateDeliveryPersonDataQuery();
  const [addDeliveryPerson] = useAddDeliveryPersonMutation();

  useEffect(() => {
    if (createRequest.isSuccess) {
      //   const { roles } = createRequest.data;
      //   let formattedOptions = roles.map((role) => ({
      //     ...role,
      //     value: role.name,
      //   }));
      //   setOptions(formattedOptions);
    }
  }, [createRequest.isLoading, createRequest.isSuccess]);

  const handleSubmit = (data) => {
    data.telephone = telephone;
    addDeliveryPerson(data)
      .unwrap()
      .then(() => {
        Swal.fire({
          title: "Succees",
          text: `Informations soumises avec succès`,
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
        navigate("/delivery-person");
      })
      .catch((response) => {
        Swal.fire({
          title: "Erreur",
          text: `Erreur lors de la soumission des informations`,
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };

  return (
    <div>
      <Title
        title="Enregistrer un nouveau livreur"
        backPath="/delivery-person"
      />
      <Formik
        enableReinitialize
        initialValues={{
          firstname: "",
          lastname: "",
          password: "",
          telephone: "",
          email: "",
        }}
        validationSchema={Yup.object({
          firstname: Yup.string().required("Obligatoire"),
          lastname: Yup.string().required("Obligatoire"),
          email: Yup.string()
            .email("Adresse email invalide")
            .required("*Obligatoire"),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit({ ...values });
          setSubmitting(false);
          resetForm();
        }}
      >
        <Form className="row">
          <div className="col-md-3 mt-3">
            <Input
              label="Nom"
              name="lastname"
              type="text"
              placeholder="Nom"
              className="form-control"
            />
          </div>

          <div className="col-md-3 mt-3">
            <Input
              label="Prénom"
              name="firstname"
              type="text"
              placeholder="Prénom"
              className="form-control"
            />
          </div>

          <div className="col-md-3 mt-3">
            <Input
              label="Email"
              name="email"
              type="email"
              placeholder=""
              className="form-control"
            />
          </div>

          <div className="col-md-3 mt-3">
            <Input
              label="Mot de passe"
              name="password"
              type="password"
              placeholder=""
              className="form-control"
            />
          </div>

          <div className="col-md-3">
            <p>Téléphone</p>
            <PhoneInput
              country={"bj"}
              enableSearch={false}
              onlyCountries={["bj"]}
              masks={{ bj: "..  ..  ..  .." }}
              disableCountryCode={true}
              disableDropdown={true}
              value={telephone}
              onChange={setTelephone}
              className="col-md-3 d-flex"
              inputProps={{
                className: "border-0 form-control mt-0",
                required: true,
              }}
              placeholder="97  00  00  00"
            />
          </div>

          <div className="row"></div>

          <div className="row">
            <div className="mt-5 d-flex bd-highlight justify-content-start">
              <div className="mr-2">
                <button className="btn btn-primary" type="submit">
                  Créer
                </button>
              </div>

              <div className="mx-2">
                <Link to="/restaurant">
                  <button className="btn btn-white" type="button">
                    Annuler
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default CreateDeliveryPerson;
