import React from "react";
import DataTable from "../../../features/dataTable/DataTableBase";
import { Link } from "react-router-dom";

function OrderListing({ setTitle }) {
  const actions = (id) => {
    return (
      <div className="fw-bold d-flex">
        <Link to={`/order/${id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle"
            type="button"
          >
            <i className="bi bi-eye"></i>
          </button>
        </Link>
      </div>
    );
  };

  const columns = [
    {
      name: "Client",
      selector: "customer",
      sortable: true,
    },
    {
      name: "Livreur",
      selector: "delivery_person",
      sortable: true,
    },
    {
      name: "Statut",
      selector: "status",
    },
    {
      name: "Pourboire",
      selector: "gratification",
    },
    {
      name: "Date",
      selector: "date",
    },
  ];

  const data = [
    {
      id: 1,
      customer: "Madara",
      delivery_person: "Livreur 1",
      status: "Livré",
      gratification: 2500,
      date: "30/06/23",
    },
    {
      id: 2,
      customer: "Naruto",
      delivery_person: "Livreur 5",
      status: "En Attente",
      date: "28/06/23",
    },
    {
      id: 3,
      customer: "Itachi",
      delivery_person: "Livreur 1",
      status: "Annulée",
      gratification: 3000,
      date: "13/0523",
    },
    {
      id: 4,
      customer: "Gaara",
      delivery_person: "Livreur 1",
      status: "Approuvé",
      date: "20/02/23",
    },
  ];
  return (
    <div className="container-fluid">
      <DataTable columns={columns} data={data} actions={actions} />
    </div>
  );
}

export default OrderListing;
