import React, { useState } from "react";
import DataTable from "../dataTable/DataTableBase";
import { Link } from "react-router-dom";
import { useGetRubricsQuery } from "./rubricsApiSlice";
// import { useGetMealsQuery } from "./mealsApiSlice";
// import DeleteMealModal from "./DeleteMealModal";
import UpdateRubricModal from "./UpdateRubricModal";
// import CreateMealPromotion from "./CreateMealPromotion";

function RubricList() {
  const [baseUrl, setBaseUrl] = useState("meals");
  const [url, setUrl] = useState(baseUrl);

  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetRubricsQuery();

  const columns = [
    {
      name: "Titre",
      selector: "title",
      sortable: true,
    },
    { name: "Description", selector: "description", sortable: true },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      type: "status",
    },
  ];

  let data = [];
  let meta = {};

  let content = "";
  if (isLoading) {
    content = <p>Loading ...</p>;
  } else if (isSuccess) {
    data = response.map((rubric) => ({
      ...rubric,
    }));

    meta = {
      currentPage: response.current_page,
      from: response.from,
      lastPage: response.last_page,
      perPage: response.per_page,
      total: response.total,
    };
  } else if (error) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  const actions = (rubric) => {
    return (
      <div className="fw-bold d-flex">
        {!rubric.validated_by && <UpdateRubricModal rubric={rubric} />}
        {/* <Link to={`/meal/${meal.id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
            type="button"
          >
            <i className="bi bi-eye fs-3"></i>
          </button>
        </Link> */}

        {/* <Link to={`/meal/edit/${meal.id}`}>
          <button
            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
            type="button"
          >
            <i className="bi bi-pencil fs-3"></i>
          </button>
        </Link> */}
        {/* <DeleteMealModal meal={meal} /> */}
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid d-flex justify-content-end bd-highlight">
        {/* <CreateMealPromotion meals={data} /> */}
      </div>
      <div className="container">
        <DataTable
          columns={columns}
          data={data}
          actions={actions}
          baseUrl={baseUrl}
          setUrl={setUrl}
          meta={meta}
        />
      </div>
    </>
  );
}

export default RubricList;
