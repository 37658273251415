import React from "react";

function ReadTagModal({ tag }) {
  return (
    <div>
      <button
        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#readTagModal${tag.id}`}
      >
        <i className="bi bi-eye"></i>
      </button>

      {/* <!-- Update Plan Modal --> */}
      <div
        className="modal fade"
        id={`readTagModal${tag.id}`}
        tabIndex="-1"
        aria-labelledby="accountUpdatePlanModalLabel"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xs"
          role="document"
        >
          <div className="modal-content">
            {/* <!-- Header --> */}
            <div className="modal-header">
              <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                Détails du tag
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <div className="modal-body">
              <div className="content container-fluid">
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h6 className="card-subtitle mb-3"></h6>

                        <h3 className="card-title d-flex justify-content-center">
                          {tag.name}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-5">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h6 className="card-subtitle mb-3">Description</h6>
                        {tag.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center justify-content-sm-end gap-3">
                <button
                  type="button"
                  className="btn btn-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Fermer
                </button>
              </div>
            </div>
            {/* <!-- End Body --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Update Plan Modal --> */}
    </div>
  );
}

export default ReadTagModal;
