import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

import { persistor, store } from "./app/sotre";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { selectCurrentToken } from "./features/auth/authSlice";
import useGetCurrentToken from "./hooks/auth/useGetCurrentToken";

// const token = useGetCurrentToken();

// window.Echo = new Echo({
//   authEndpoint: `http://127.0.0.1:8000/api/broadcasting/auth`,
//   broadcaster: "pusher",
//   Pusher,
//   key: "LARAVELCHATFDASD",
//   wsHost: "localhost",
//   wsPort: 6001,
//   wssport: 6001,
//   transports: ["websocket"],
//   enabledTransports: ["ws", "wss"],
//   forceTLS: false,
//   disableStats: true,
//   cluster: "mt1",
//   auth: {
//     headers: {
//       Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5YTg0NjkwYi0wNGIzLTQ5NzktOWQ3Yy01MGU0NTc0OWEyNGUiLCJqdGkiOiIzMjcyOGE4NGVkMDY0NTE0NmQzNzU0ZjZlZTE4Njg0ZDI0M2RiZjNjOTg2ZDY3YjMyOTYxYTI2YTQ2NWUzN2Y5YmMwYzFiZTI0OTU4OTA4MiIsImlhdCI6MTcwMTg1NTI5MS40NzAxMDYsIm5iZiI6MTcwMTg1NTI5MS40NzAxMDksImV4cCI6MTcwMTk0MTY5MS40NTA2MTgsInN1YiI6IjlhOGU4ZTg3LTk2YzUtNDQ0NS1iMWM4LWEzZTcxZjg5YmJjNSIsInNjb3BlcyI6W119.HGgtPDpWLarsTrLG-b_M19dvY2V5u9uwOV8k-yMtJ9e17I_UY6XbU6LPqFYqdJLS3JTMFoFEPiqC_-OcclhLnEMeHRgXalwBBf1hP3GTszM-2ulBnEDvsfHP_vOvPa_UpnAK3gEVA4659uOianQa-792mqxkTlZJYB5HAdL3kQJCsqXtd4C08CYo6ynEJSurn62lEcFvH-rNQrgvflDFgZg7sOYv8hgTuZhg0j6oXeW-yNJHrAJ7PEn6h4Wpp4kJPx_-8FFefi3i1ag5FSaKO_sblUL7GvJYjFGBW0OwUXfBTLf-CWLunmoWx5Mh6dIPnDTfYCdYG_7V22MwmBAtoRSFTJOrPypwi7sljvA1RpbL1wjw8zpNclbo08r-VBbM8_areDxsnCDXRyuWmPso8N3-h1W-a6Acuuh4vTYTSF45byCr7t9j0W2Kdf-yJb7fQ6E6DqyZEtaCn2w4U24RTA00WOfKDwIbX1_b6Hs2A1wsI-rDxqTpLfaK8Fh_Whq6FM1zZ1cbT_wMsmOZicy7QeRLq5PnAXTx_oySqL0PWEiYnLzIIElVWc2RogPCVtCz3D4i7IAUV4wnRWoKZPZYl0GXFNh7oGwh9nA_Auz-NSNN1mPvsWtVW7FmPrXYEnmhHYemTLWOqNoKCVYoECzGzJcQAY9g7VnsB-63NKThpZM`,
//       Accept: "application/json",
//     },
//   },
// });

// window.Echo.private(
//   "messenger.9a8e8e87-96c5-4445-b1c8-a3e71f89bbc5.9a84826c-4200-4765-9578-0c7d58927fe2"
// ).listen("MessageSent", (eventData) => {
//   console.log("EVENT FROM CHANNEL", eventData);
// });

// window.Echo.join(`group_chat.1`)
//   .here((users) => {
//     console.log("JOIN GROUP CHAT", users);
//   })
//   .joining((user) => {
//     console.log(user.name);
//   })
//   .leaving((user) => {
//     console.log(user.name);
//   })
//   .listen("GroupChatMessage", (eventData) => {
//     console.log("EVENT FROM CHANNEL", eventData);
//   })
//   .error((error) => {
//     console.error(error);
//   });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
