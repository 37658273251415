import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { stringAvatar } from "../../components/Shared/Utils";
import Title from "../../components/Shared/Title";
import { nanoid } from "@reduxjs/toolkit";
import { useGetRechargeQuery } from "./rechargesApiSlice";

function ReadRecharge() {
  const [order, setOrder] = useState({});
  const { rechargeId } = useParams();
  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetRechargeQuery({ rechargeId });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isSuccess) {
    }
  }, [isLoading, isSuccess]);

  return (
    <>
      <Title title={`Recharge`} backPath="/recharge">
        <p className="text-center">Référence : </p>
      </Title>

      <div className="content container-fluid">
        <div className="row">
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Emetteur</h6>
                <h3></h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Destinataire</h6>
                <h3></h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Montant</h6>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Status</h6>
                <h3 className="card-title"> </h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Date</h6>
                <h3 className="card-title"></h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Charges</h6>
                <h3 className="card-title"></h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Transaction</h6>
                <h3 className="card-title"></h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Promotion</h6>
                <h3 className="card-title">{order?.promo_code_discount} F</h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Mode de paiement</h6>
                <h3 className="card-title"></h3>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-3 mb-lg-5">
          {/* <!-- Card --> */}
          {/* <!-- Table --> */}
          <div className="table-responsive datatable-custom">
            <table
              id="datatable"
              className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              data-hs-datatables-options='{
                   "columnDefs": [{
                      "targets": [0, 1, 4],
                      "orderable": false
                    }],
                   "order": [],
                   "info": {
                     "totalQty": "#datatableWithPaginationInfoTotalQty"
                   },
                   "search": "#datatableSearch",
                   "entries": "#datatableEntries",
                   "pageLength": 8,
                   "isResponsive": false,
                   "isShowPaging": false,
                   "pagination": "datatablePagination"
                 }'
            >
              <thead className="thead-light">
                <tr>
                  <th>Repas</th>
                  <th>Quantité</th>
                  <th>Prix</th>
                </tr>
              </thead>

              <tbody>
                {order?.items?.map((item) => (
                  <tr key={nanoid()}>
                    <td>{item.meal_id}</td>
                    <td>{item.quantity}</td>
                    <td>{item.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <!-- End Table --> */}

          {/* <!-- Footer --> */}
          <div className="card-footer"></div>
          {/* <!-- End Footer --> */}
          {/* <!-- End Card --> */}
        </div>
      </div>
    </>
  );
}

export default ReadRecharge;
