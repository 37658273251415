import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/Shared/Title";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";
import {
  localDateFormat,
  localPriceFormat,
  stringAvatar,
} from "../../components/Shared/Utils";
import { nanoid } from "@reduxjs/toolkit";
import { useGetPromotionQuery } from "./promotionsApiSlice";
import STATUS from "../../components/Shared/Status";
import {
  APPLICATE,
  CALCULATION_BASIS,
  VALUE_TYPE,
} from "../../components/Shared/Constants";

function ReadPromotion() {
  const { promotionId } = useParams();
  const [promotion, setPromotion] = useState({ name: "Promotion" });
  const {
    data: response,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetPromotionQuery({ promotionId });

  useEffect(() => {
    if (isSuccess) {
      setPromotion(response);
    }
  }, [isLoading, isSuccess]);

  return (
    <>
      <Title title={promotion.name} backPath="/promotion" />

      <div className="content container-fluid">
        <div className="row">
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Nom</h6>
                <h3 className="card-title">{promotion.name}</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Statut</h6>
                <h3 className="card-title">{STATUS[promotion.status]}</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Code</h6>
                <h3 className="card-title">{promotion.code}</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Type de valeur</h6>
                <h3 className="card-title">
                  {VALUE_TYPE[promotion.value_type]}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Valeur</h6>
                <h3 className="card-title">
                  {localPriceFormat(promotion.value, true)}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Maximum de bénéficiaires</h6>
                <h3 className="card-title">
                  {promotion.maximum_beneficiaries}
                </h3>
              </div>
            </div>
          </div>

          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Base de calcule</h6>
                <h3 className="card-title">
                  {CALCULATION_BASIS[promotion.calculation_basis]}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Applicable à</h6>
                <h3 className="card-title">{APPLICATE[promotion.applicate]}</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Cummulable</h6>
                <h3 className="card-title">
                  {promotion.cummulative ? "Oui" : "Non"}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Nbre max usage</h6>
                <h3 className="card-title">{promotion.max_usage}</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="card-subtitle mb-3">Nbre max bénéficiaires</h6>
                <h3 className="card-title">
                  {promotion.maximum_beneficiaries}
                </h3>
              </div>
            </div>
          </div>
          {promotion.created_at && (
            <div className="col-lg-3 mb-5">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Date de création</h6>
                  <h3 className="card-title">
                    {localDateFormat(promotion.created_at)}
                  </h3>
                </div>
              </div>
            </div>
          )}
          {promotion.cible && (
            <div className="col-lg-3 mb-5">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">---Cible</h6>
                  <h3 className="card-title">Livraison/Repas</h3>
                </div>
              </div>
            </div>
          )}
          {promotion.expire_at && (
            <div className="col-lg-3 mb-5">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Date d'expiration</h6>
                  <h3 className="card-title">02/12/23</h3>
                </div>
              </div>
            </div>
          )}
          {promotion.source && (
            <div className="col-lg-3 mb-5">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Source</h6>
                  <h3 className="card-title">Restaurant</h3>
                </div>
              </div>
            </div>
          )}
          {promotion.origin && (
            <div className="col-lg-3 mb-5">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Origin</h6>
                  <h3 className="card-title">Le Sorrento </h3>
                </div>
              </div>
            </div>
          )}
          {promotion.beneficiary_id && (
            <div className="col-lg-3 mb-5">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="card-subtitle mb-3">Bénéficiaire</h6>
                  <h3 className="card-title">Jean Pierre</h3>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="table-responsive datatable-custom">
            <table
              id="datatable"
              className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
            >
              <thead className="thead-light">
                <tr>
                  <th>Repas</th>
                  <th>Prix</th>
                </tr>
              </thead>

              <tbody>
                {promotion?.meals?.map((item) => (
                  <tr key={nanoid()}>
                    <td>{item.name}</td>
                    <td>{localPriceFormat(item.price, true)}</td>
                  </tr>
                ))}
                {promotion?.meals?.length <= 0 && (
                  <tr key={nanoid()}>
                    <td className="text-center" colSpan="2">
                      Aucun
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="card-footer"></div>
          </div>
        </div>
        <div className="row">
          <div className="table-responsive datatable-custom">
            <table
              id="datatable"
              className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
            >
              <thead className="thead-light">
                <tr>
                  <th>Restaurants</th>
                  <th>Logo</th>
                  <th>Bannière</th>
                </tr>
              </thead>

              <tbody>
                {promotion?.restaurants?.map((item) => (
                  <tr key={nanoid()}>
                    <td>{item.name}</td>
                    <td>
                      <Avatar alt=" " src={item.logo_url} />
                    </td>
                    <td>
                      <Avatar alt=" " src={item.banner_url} />
                    </td>
                  </tr>
                ))}

                {promotion?.restaurants?.length <= 0 && (
                  <tr key={nanoid()}>
                    <td className="text-center" colSpan="3">
                      Aucun
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="card-footer"></div>
          </div>
        </div>
        <div className="row">
          <div className="table-responsive datatable-custom">
            <table
              id="datatable"
              className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
            >
              <thead className="thead-light">
                <tr>
                  <th>Client</th>
                  <th>Email</th>
                  <th>Téléphone</th>
                </tr>
              </thead>

              <tbody>
                {promotion?.clients?.map((item) => (
                  <tr key={nanoid()}>
                    <td>{item.identity.fullName}</td>
                    <td>{item.identity.email}</td>
                    <td>{item.identity.telephone}</td>
                  </tr>
                ))}

                {promotion?.clients?.length <= 0 && (
                  <tr key={nanoid()}>
                    <td className="text-center" colSpan="3">
                      Aucun
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="card-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReadPromotion;
